import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Close,
  Icon,
  Scrollbars,
  UtilityInlineGrid,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'rhinostyle';
import PdfViewerContainer from '../containers/PdfViewerContainer';
import { FORM_DELETE, FORM_EDIT, FORM_VIEW } from '../constants/UserPermissionsConstants';
import { updatedAtDateFormatter } from '../helpers/DateHelpers';
import { getAttachmentUrl } from '../helpers/SavedContentHelpers';
import { isWriteBackEnabled } from '../helpers/OrganizationHelpers';
import FormActionButton from './FormActionButton';

const renderLastUpdated = (formTemplate) => (
  formTemplate?.lastModifiedBy ? ` by ${formTemplate?.lastModifiedBy?.firstName} ${formTemplate?.lastModifiedBy?.lastName}` : ''
);

const renderAutoSendToEHRValue = (formTemplate) => (
  formTemplate?.autoSendToEHR ? (<div className="u-text-secondary"> Active </div>) : (<div className="u-text-accent"> Inactive </div>)
);

const DeleteFormModal = (props) => {
  const {
    isOpen,
    handleClose,
    formInProgress,
    handleDeleteForm,
    activeFormTemplate,
  } = props;

  return (
    <Modal open={isOpen}>
      <ModalHeader
        onClose={handleClose}
        title="Delete Form?"
        titleSub="By deleting this form, the following will happen:"
      />
      <ModalBody>
        <ol className="u-list--numbers">
          <li>Removes the form from all forms list.</li>
          <li>All outstanding form links associated to this form will expire.</li>
          <li>Will be removed from any template it&apos;s currently attached to. <br /> <strong>Please Note:</strong> These templates should be updated.</li>
        </ol>
      </ModalBody>
      <ModalFooter>
        <UtilityInlineGrid align="between">
          <Button onClick={handleClose}>
            Cancel
          </Button>
          <Button loading={formInProgress} onClick={() => handleDeleteForm(activeFormTemplate.formId)} type="danger" data-cypress="confirmDelete">
            Delete Form
          </Button>
        </UtilityInlineGrid>
      </ModalFooter>
    </Modal>
  );
};

DeleteFormModal.propTypes = {
  isOpen: PropTypes.bool,
  formInProgress: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleDeleteForm: PropTypes.func.isRequired,
  activeFormTemplate: PropTypes.object,
};

const LibraryFormPanel = (props) => {
  const {
    activeFormTemplate,
    handleTogglePanel,
    showDeleteFormModal,
    loggedInUserPermissions,
    isFormTemplateDeleting,
    isFormTemplateUpdating,
    handleToggleModal,
    handleDeleteForm,
    handlePublishForm,
    handleFormConfiguration,
    isPdfViewerOpen,
    togglePdfViewer,
    handleCloneForm,
    currentOrganization,
  } = props;
  return (
    <div className="summary-panel__wrapper form-details__summary-panel">
      <div className="summary-panel">
        <div className="app-page__header">
          <div className="app-page__header__title" data-cypress="summaryTitle">Summary</div>
          <div className="app-page__header__action">
            <Close
              className="app-page__header__action__close"
              onClick={() => handleTogglePanel(activeFormTemplate.formId)}
            />
          </div>
        </div>

        <Scrollbars className="summary-panel__body">
          <div className="summary-panel__content">
            <div className="summary-panel__intro-wrapper">
              <div className="summary-panel__intro__title">
                <Icon icon="signature" className="signature-icon" /> {activeFormTemplate.title}
              </div>
              <div className="summary-panel__intro__actions">
                {loggedInUserPermissions.includes(FORM_EDIT) && (
                  <UtilityInlineGrid align="middle">
                    {!activeFormTemplate.isPublished && (
                      <Button
                        loading={isFormTemplateUpdating}
                        onClick={() => handlePublishForm(activeFormTemplate.formId)}
                        size="small"
                        type="accent"
                        data-cypress="publishForm"
                      >Publish Form
                      </Button>
                    )}
                    <Button
                      size="small"
                      type="primary"
                      outlined
                      onClick={handleFormConfiguration}
                      data-cypress="editForm"
                    >
                      Edit Form
                    </Button>
                    <Button
                      size="small"
                      type="primary"
                      outlined
                      onClick={() => handleCloneForm(activeFormTemplate.formId)}
                      data-cypress="cloneForm"
                    >
                      Clone Form
                    </Button>
                  </UtilityInlineGrid>
                )}
                {!loggedInUserPermissions.includes(FORM_EDIT) && loggedInUserPermissions.includes(FORM_VIEW) && (
                  <UtilityInlineGrid align="middle">
                    <Button
                      size="small"
                      type="primary"
                      outlined
                      onClick={togglePdfViewer}
                    >
                      View Form
                    </Button>
                  </UtilityInlineGrid>
                )}
              </div>
            </div>
            <div className="summary-panel__section">
              <div className="summary-panel__section__label">Updated</div>
              <div className="summary-panel__section__content">
                <div>{`${updatedAtDateFormatter(activeFormTemplate.updatedAt)}${renderLastUpdated(activeFormTemplate)}`}</div>
              </div>
            </div>
            { isWriteBackEnabled(currentOrganization) && (
            <div className="summary-panel__section">
              <div className="summary-panel__section__label">SEND TO EHR/PMS</div>
              <div className="summary-panel__section__content">
                <div>{renderAutoSendToEHRValue(activeFormTemplate)}</div>
              </div>
            </div>
            )}
            <div className="summary-panel__section summary-panel__section--full">
              <div className="summary-panel__section__label">Actions</div>
              <div className="u-flex-direction-row u-flex u-m-t-small">
                {loggedInUserPermissions.includes(FORM_VIEW) && (
                <FormActionButton
                  label="view"
                  dataCypress="viewForm"
                  icon="pdfFile"
                  toggleButton={togglePdfViewer}
                />
                )}
                {loggedInUserPermissions.includes(FORM_DELETE) && (
                <FormActionButton
                  label="delete"
                  dataCypress="deleteForm"
                  icon="trash"
                  toggleButton={handleToggleModal}
                />
                )}
              </div>
              <DeleteFormModal
                isOpen={showDeleteFormModal}
                formInProgress={isFormTemplateDeleting}
                activeFormTemplate={activeFormTemplate}
                handleClose={handleToggleModal}
                handleDeleteForm={handleDeleteForm}
              />
            </div>
          </div>
        </Scrollbars>
        <PdfViewerContainer
          open={isPdfViewerOpen}
          attachmentUrl={getAttachmentUrl(activeFormTemplate.formS3Url, activeFormTemplate.createdBy)}
          fileName={activeFormTemplate.formS3Url}
          handlePdfViewerClose={togglePdfViewer}
        />
      </div>
    </div>
  );
};

LibraryFormPanel.propTypes = {
  activeFormTemplate: PropTypes.object.isRequired,
  showDeleteFormModal: PropTypes.bool.isRequired,
  isFormTemplateDeleting: PropTypes.bool.isRequired,
  loggedInUserPermissions: PropTypes.array.isRequired,
  handleTogglePanel: PropTypes.func.isRequired,
  handleToggleModal: PropTypes.func.isRequired,
  handleDeleteForm: PropTypes.func.isRequired,
  handlePublishForm: PropTypes.func.isRequired,
  isFormTemplateUpdating: PropTypes.bool.isRequired,
  handleFormConfiguration: PropTypes.func.isRequired,
  isPdfViewerOpen: PropTypes.bool.isRequired,
  togglePdfViewer: PropTypes.func.isRequired,
  handleCloneForm: PropTypes.func.isRequired,
  currentOrganization: PropTypes.object,
};

export default LibraryFormPanel;
