import { useSelector } from 'react-redux';
import {
  useGetPrescriptionsQuery,
} from '../services/prescriptionService';
import { getRhinoblastMaxPageSize } from '../selectors/organizationSelectors';

const usePrescriptions = ({
  sortBy,
  sortOrder,
  pageNo,
  payorIds,
  prescriptionSearch,
  startDate,
  endDate,
  consentTypeIds,
  patientId,
}) => {
  const pageSize = useSelector(getRhinoblastMaxPageSize);
  const organizationId = useSelector((state) => state.auth.currentOrg);
  const results = useGetPrescriptionsQuery({
    sortBy,
    sortOrder,
    pageNo,
    organizationId,
    payorIds,
    prescriptionSearch,
    startDate,
    endDate,
    consentTypeIds,
    patientId,
    pageSize,
  });
  return results;
};

export default usePrescriptions;
