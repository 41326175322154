import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import cx from 'classnames';
import {
  Alert,
  Button,
  Dropdown,
  DropdownMenuItem,
  DropdownMenuDivider,
  DropdownMenuItemWild,
  Icon,
  MessageBox,
  Modal,
  ModalFooter,
  ModalHeader,
  Select,
  UtilityInlineGrid,
  UtilitySystem,
} from 'rhinostyle';
import EmojiPickerDropdown from './EmojiPickerDropdown';
import { Types } from '../constants';
import {
  DataHelpers,
  LocationHelpers,
  UserHelpers,
  StringHelpers,
  PhoneHelpers,
} from '../helpers';
import {
  THREAD_MESSAGE_CREATE,
} from '../constants/UserPermissionsConstants';
import {
  getOtherThreadFromChannelOptions,
  getSecureThreadFromChannelOptions,
  getSecureThreadNotificationFromChannelOptions,
  getSecureThreadNotificationToChannelOptions,
  getSelectedToChannelId,
  getThreadFromChannelOptions,
  getThreadToChannelOptions,
  getIsMessageSendBoxEnabled,
  getDoesLoggedInUserHaveFromChannel,
} from '../selectors/inboxSelectors';
import MessageComposeActions from './MessageComposeActions';

import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import CharacterCount from './CharacterCount';
import MentionComposeArea from './MentionComposeArea';
import * as ThreadReducer from '../reducers/threadReducer';
import * as InboxReducer from '../reducers/inboxReducer';
import {
  getLoggedInUser,
  getLoggedInUserOrganization,
  getStorageKey } from '../selectors/userSelectors';
import ThreadTypingWrapper from './ThreadTypingWrapper';
import MessageAttachments from './MessageAttachments';
import {
  useActiveUser,
  useStorageService,
  useThreadOptions,
  useActiveConvoTab,
  useLastEvent,
  useInboxCommunicationContext,
  useContactAppointments,
} from '../hooks';
import { DRAFT_STORAGE_KEYS } from '../constants/AppConstants';

const InboxThreadCompose = (props) => {
  const {
    activeFromChannelId,
    activeSecureFromChannelId,
    activeSecureNotificationFromChannelId,
    activeSecureNotificationToChannelId,
    activeToChannelId,
    channels,
    currentUser,
    otherThreadFromChannelOptions,
    phones,
    secureThreadFromChannelOptions,
    secureThreadNotificationFromChannelOptions,
    secureThreadNotificationToChannelOptions,
    supportedLanguageIds,
    supportedLanguages,
    threadFromChannelOptions,
    fileUploadPostProcessing,
    userOrganization,
    sendingMessage,
    selectedToChannelId,
    messageForms,
    sharelinkFiles,
    templateToBeAdded,
    activeUpload,
    mostRecentConversationLanguageId,
    doesLoggedInUserHaveFromChannel,
    isMessageSendBoxEnabled,
    attachments,
    activeConvoTab,
    threadToChannelOptions,
    providers,
  } = props;

  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();

  const { userId } = params;
  const activeUser = useActiveUser();
  const { mostRecentEvent } = useLastEvent();

  const convoFooterRef = useRef();
  const messageBoxRef = useRef();

  const [messageText, setMessageText] = useState('');
  const [draftMentions, setDraftMentions] = useState([]);
  const [focusComposeArea, setFocusComposeArea] = useState(false);
  const [isNonTextableModalShowing, setIsNonTextableModalShowing] = useState(false);
  const [sendingMessageLoading, setSendingMessageLoading] = useState(false);
  const [convoTab, setConvoTab] = useState(useActiveConvoTab());
  const [messageTranslation, setMessageTranslation] = useState({
    isMessageTranslated: false,
    translatedText: '',
    selectedLanguageForTranslation: null,
    languageId: null,
  });
  const [showMentionPanel, setShowMentionPanel] = useState(false);
  const [emojiToAdd, setEmojiToAdd] = useState(null);

  // Sync values with local storage
  useStorageService(draftMentions, [], DRAFT_STORAGE_KEYS.mentions, setDraftMentions);
  useStorageService(messageText, '', DRAFT_STORAGE_KEYS.default, setMessageText);
  useStorageService(attachments, [], DRAFT_STORAGE_KEYS.attachments, handleSetFiles);
  useStorageService(sharelinkFiles, [], DRAFT_STORAGE_KEYS.sharelinkFiles, handleSetFiles);
  useStorageService(messageForms, [], DRAFT_STORAGE_KEYS.messageForms, handleSetFiles);
  useInboxCommunicationContext();

  const options = LocationHelpers.getInboxOptions(params, location);
  const isSecure = activeConvoTab === 'secure';
  const isNote = activeConvoTab === 'note';
  const isMessage = activeConvoTab === 'patient';
  const { isMessageTranslated, selectedLanguageForTranslation } = messageTranslation;
  const threadOptions = useThreadOptions();
  const isGroupInbox = location.pathname.includes('group');

  function handleSetFiles(value, fileType) {
    dispatch(ThreadReducer.setFiles({ fileType, value }));
  }

  const { appointments = [] } = useContactAppointments(true);

  function setInitialState() {
    setMessageTranslation({
      isMessageTranslated: false,
      translatedText: '',
      selectedLanguageForTranslation: null,
      languageId: null,
    });
    if (sendingMessageLoading) {
      setMessageText('');
      setDraftMentions([]);
      setSendingMessageLoading(false);
    }
  }

  function handleUpdateMentions(variable) {
    setDraftMentions((current) => (current.some((mention) => mention.id === variable.id && variable.type === mention.type) ? current : [...current, variable]));
  }

  // setup thread action options
  const recommendedLanguageForTranslation = mostRecentConversationLanguageId && supportedLanguages?.[mostRecentConversationLanguageId];
  const isMessageTranslationEnabledForOrg = userOrganization.isMessageTranslationEnabled && userOrganization.isMessageTranslationFeatureEnabled;
  const globeLanguage = selectedLanguageForTranslation || recommendedLanguageForTranslation;
  const isMessageTextEmpty = messageText.trim().length === 0;
  const threadToChannels = threadToChannelOptions.reduce((a, b) => a.concat(b.options), []);
  const toChannel = threadToChannels.find((ch) => ch.id === activeToChannelId);
  const toChannelName = toChannel ? toChannel.name : '';
  const toChannelRouteName = toChannelName ? toChannel.route : '';
  const fromChannelName = activeFromChannelId && channels[activeFromChannelId].name;
  const fromChannelRouteName = fromChannelName ? channels[activeFromChannelId].route?.name : false;
  const hasDefaultChannel = !!currentUser?.defaultChannelId;
  const isDefaultChannelSelected = hasDefaultChannel && activeFromChannelId === currentUser?.defaultChannelId;
  const textableThreadToChannelOptions =
    threadToChannelOptions
      .reduce((a, b) => a.concat(b.options), [])
      .filter((channel) => {
        if (channel.id.includes('sms')) {
          return !phones[channel.id.slice(4)].hasOptedOut;
        } else {
          return true;
        }
      });
  const doesActiveUserHaveTextableChannel = textableThreadToChannelOptions.length > 0;
  const doesActiveUserHaveFacebook = activeUser?.facebooks?.length > 0;
  const doesActiveUserHaveInstagram = activeUser?.instagrams?.length > 0;
  const doesActiveUserHaveRhinogram = activeUser?.rhinograms?.length > 0;
  const doesActiveUserHavePhone = !!activeUser?.phones?.filter((phone) => PhoneHelpers.isPhoneNumberValid(phones[phone]?.value)).length;
  const isSecureSendEnabled = currentUser.isCcr || activeUpload || (!messageText && !attachments?.length && !messageForms?.length) || sendingMessage;
  const isHipaaConsentNotGranted = [Types.TYPE_HIPAA_CONSENT_PENDING, Types.TYPE_HIPAA_CONSENT_DECLINED].includes(activeUser?.hipaaStatus?.typeId);

  // Refers to sending member's available 'from' channels.
  const doesLoggedInUserHaveSecureFromChannel = !!secureThreadFromChannelOptions.length;
  const userHasOtherChannelType = otherThreadFromChannelOptions?.[0]?.options?.length > 0;

  useEffect(() => {
    dispatch(ThreadReducer.setActiveConvoTab({ activeConvoTab: convoTab, userId }));
  }, [convoTab, userId]);

  const doesActiveUserOwnAnyPhone = doesActiveUserHavePhone && activeUser?.phones?.some((p) => Number(phones[p]?.ownerId) === Number(activeUser.id));

  const canUserReceiveMessages = doesActiveUserOwnAnyPhone || doesActiveUserHaveInstagram || doesActiveUserHaveFacebook || doesActiveUserHaveRhinogram;

  useEffect(() => {
    if (sendingMessageLoading) {
      if (isMessage) {
        const context = { userId: activeUser.id };
        dispatch(InboxReducer.handleSendMessage({ context, messageTranslation, eventType: 'messages', options, messageText, threadOptions }));
      } else if (isSecure) {
        const context = { ...options, isAssigned: mostRecentEvent?.assigned };
        dispatch(InboxReducer.handleSendMessage({ context, eventType: 'secureMessages', options, messageText, threadOptions }));
      } else {
        handleAddNote();
      }
    }
  }, [sendingMessageLoading]);

  useEffect(() => {
    if (!sendingMessage && sendingMessageLoading) {
      setInitialState();
    }
  }, [sendingMessage]);

  useEffect(() => {
    if (templateToBeAdded) {
      const appointment = appointments?.[0];
      const providerName = appointment?.externalProviderId ? providers?.[appointment.externalProviderId]?.alias : '';
      const formattedMessage = StringHelpers.replaceInboxVariablesByValue(templateToBeAdded, {
        activeUser,
        currentUser,
        userOrganization,
        appointment,
        providerName,
      });
      if (activeConvoTab !== 'note') {
        setMessageText((current) => current + formattedMessage);
      }
      dispatch(ThreadReducer.setTemplateToBeAdded(''));
    }
  }, [templateToBeAdded]);

  function handleMessageTextChange(id, value) {
    setMessageText(value);
    if (isNote) {
      if ((messageText.lastIndexOf('@') > value.lastIndexOf('@')) && !value.includes('{groupId') && !value.includes('{userId')) {
        setShowMentionPanel(false);
      }
    }
  }

  const handleMessageTextClick = () => {
    setFocusComposeArea(true);
  };

  function handleSendMessage(forceSend = false) {
    if (isMessage && toChannel?.isPhone && !toChannel?.isTextable && !forceSend) {
      setIsNonTextableModalShowing(true);
    } else {
      setIsNonTextableModalShowing(false);
      setSendingMessageLoading(true);
    }
  }

  function handleAddNote() {
    const eventType = 'notes';
    const optimisticId = DataHelpers.generateUUID(); // used for optimistic updates
    const assigned = mostRecentEvent?.assigned || false;
    const following = mostRecentEvent?.following || false;
    const context = { ...options, isAssigned: assigned };
    const { mentionPayload, message } = StringHelpers.processMentionsInMessage(messageText, draftMentions);
    const payload = {
      userId: activeUser.id,
      text: message,
      attachments,
      optimisticId,
      ...mentionPayload,
    };

    const tempEventPayload = {
      ...payload,
      assigned,
      following,
      ...mentionPayload,
      eventMentions: draftMentions,
    };
    if (sharelinkFiles.length > 0) {
      sharelinkFiles.forEach((file) => {
        payload.text += `${payload.text.length === 0 ? '' : '\n'}${file.sharelink}`;
        tempEventPayload.text += `${tempEventPayload.text.length === 0 ? '' : '\n'}${file.sharelink}`;
      });
    }
    setInitialState();

    dispatch(InboxReducer.createTempInboxEvent(tempEventPayload, eventType));
    dispatch(InboxReducer.createInboxEvent(payload, eventType, context, threadOptions));
  }

  const handleEmojiClick = (emojiObject) => setEmojiToAdd(emojiObject);

  const clearEmoji = () => setEmojiToAdd(null);

  const handleFromChannelSelect = (channelId) => {
    dispatch(InboxReducer.setActiveFromChannel(channelId));
  };

  const handleSecureFromChannelSelect = (name, channelId) => {
    dispatch(InboxReducer.setActiveSecureFromChannel(channelId));
  };

  const handleSecureNotificationToChannelSelect = (name, channelId) => {
    if ((channelId.includes('facebook') && !activeSecureNotificationToChannelId.includes('facebook'))
    || (channelId.includes('instagram') && !activeSecureNotificationToChannelId.includes('instagram'))
      || (channelId.includes('sms') && !activeSecureNotificationToChannelId.includes('sms'))) {
      const otherChannelId = otherThreadFromChannelOptions[0]?.options[0]?.id;
      if (otherChannelId) {
        dispatch(InboxReducer.setActiveSecureNotificationFromChannel(otherChannelId));
      }
    }
    dispatch(InboxReducer.setActiveSecureNotificationToChannel(channelId));
  };

  const handleSecureNotificationFromChannelSelect = (name, channelId) => {
    dispatch(InboxReducer.setActiveSecureNotificationFromChannel(channelId));
  };

  const handleToChannelSelect = (channelId) => {
    // Facebook and SMS have different size limits for files, so if the type of channel switches, clear any attachments
    if ((channelId.includes('facebook') && !selectedToChannelId.includes('facebook'))
    || (channelId.includes('instagram') && !selectedToChannelId.includes('instagram'))
      || (channelId.includes('sms') && !selectedToChannelId.includes('sms'))) {
      dispatch(ThreadReducer.resetMessageAttachments());
      const otherChannelId = otherThreadFromChannelOptions[0]?.options[0]?.id;
      if (otherChannelId) {
        dispatch(InboxReducer.setActiveFromChannel(otherChannelId));
      }
    }
    dispatch(InboxReducer.setActiveToChannel(channelId));
  };

  const fetchMessageBoxPlaceholder = () => {
    if (isSecure) {
      return 'RhinoSecure message';
    } if (activeToChannelId && activeToChannelId.includes('facebook')) {
      return 'Facebook message';
    } if (activeToChannelId && activeToChannelId.includes('instagram')) {
      return 'Instagram message';
    } if (activeToChannelId && activeToChannelId.includes('rhinogram')) {
      return 'Rhinogram message';
    }
    return 'Message';
  };

  const messageBoxPlaceholder = fetchMessageBoxPlaceholder();

  const renderStandardInfo = () => {
    if (!activeToChannelId) return null;
    return (
      <div className="convo__footer__actions">
        <div className="convo__message__add-actions">
          <EmojiPickerDropdown
            inputName="message"
            inputValue={messageText}
            inputRef={messageBoxRef}
            handleInputChange={handleMessageTextChange}
            dropdownPosition="top"
            wrapperClassName="emoji-picker__dropdown__wrapper--convo-thread u-m-l-small u-m-r-small"
          />
          {!isMessageTranslated && (<MessageComposeActions isMessageTranslated />)}
          <CharacterCount length={messageText.length} additionalClass="character-count__compose-actions" />
        </div>
        <div className="convo__channels">
          <Dropdown
            className="u-text-small u-text-muted"
            wide
            position="top"
            reset
            disableScroll
            hideCaret
            title="Change channel"
            dataCypress="channel-select-dropdown"
            label={(
              <span className="convo__channels__label">
                <Icon className="convo__channels__label__icon" icon="to-from" />
                <span className="convo__channels__label__text">
                  <strong>To: {toChannelName}</strong>
                  {toChannelRouteName && ` (${toChannelRouteName})`}&nbsp;

                  {doesLoggedInUserHaveFromChannel && hasDefaultChannel && isDefaultChannelSelected && !isGroupInbox && <strong>From: <span className="u-text-secondary">{fromChannelName}</span></strong>}
                  {doesLoggedInUserHaveFromChannel && hasDefaultChannel && !isDefaultChannelSelected && !isGroupInbox && <strong>From: <span className="u-text-accent">{fromChannelName}</span></strong>}
                  {doesLoggedInUserHaveFromChannel && !hasDefaultChannel && !isGroupInbox && <strong>From: {fromChannelName}</strong>}
                  {doesLoggedInUserHaveFromChannel && isGroupInbox && <strong>From: {fromChannelName}</strong>}

                  {doesLoggedInUserHaveFromChannel && fromChannelRouteName && ` (Route: ${fromChannelRouteName})`}
                </span>
              </span>
            )}
          >
            <DropdownMenuItemWild className="convo__channels__select">
              <span><strong>To</strong></span>
              <Dropdown
                className="convo__channels__select"
                activeKey={activeToChannelId}
                name="to-channel"
                block
                type="input"
                onSelect={handleToChannelSelect}
                position="top"
              >
                {textableThreadToChannelOptions.map((phone) =>
                  (
                    <DropdownMenuItem
                      key={phone.id}
                      id={phone.id}
                      label={(
                        <div>
                          {phone.value} &nbsp;
                          <span className="u-text-accent">
                            {phone.isPhone && !phone.isTextable ? 'Non-textable' : ''}
                          </span>
                        </div>
                    )}
                    />
                  ))}
              </Dropdown>
              <span><strong>From</strong></span>
              <Dropdown
                activeKey={activeFromChannelId}
                name="from-channel"
                block
                type="input"
                className="convo__channels__select"
                onSelect={handleFromChannelSelect}
                position="top"
                dataCypress="from-channel"
              >
                {threadFromChannelOptions[0].options.map((channel) =>
                  (
                    <DropdownMenuItem
                      key={channel.id}
                      id={channel.id}
                      label={channel.value}
                    />
                  ))}
              </Dropdown>
            </DropdownMenuItemWild>
          </Dropdown>
          <CharacterCount length={messageText.length} additionalClass="character-count__convo-channels" />
        </div>
      </div>
    );
  };

  const renderSecureInfo = () => {
    const secureChannelName = activeSecureFromChannelId && channels[activeSecureFromChannelId].name;
    const secureChannelRouteName = secureChannelName && channels[activeSecureFromChannelId].route.name;

    return (
      <div className="convo__footer__actions">
        <div className="convo__message__add-actions">
          <EmojiPickerDropdown
            inputName="message"
            inputValue={messageText}
            inputRef={messageBoxRef}
            handleInputChange={handleMessageTextChange}
            dropdownPosition="top"
            wrapperClassName="emoji-picker__dropdown__wrapper--convo-thread u-m-l-small u-m-r-small"
          />
          {!isMessageTranslated && (<MessageComposeActions isMessageTranslated />)}
          <CharacterCount length={messageText.length} additionalClass="character-count__compose-actions" />
        </div>
        <div className="convo__channels">
          <Dropdown
            className="u-text-small u-text-muted"
            wide
            position="top"
            reset
            disableScroll
            hideCaret
            label={(
              <span className="convo__channels__label">
                <Icon className="convo__channels__label__icon" icon="to-from" />
                <span className="convo__channels__label__text"><Icon icon="lock" />
                  <strong>{secureChannelName}</strong>{secureChannelRouteName && ` (Route: ${secureChannelRouteName})`}
                </span>
              </span>
            )}
          >
            <DropdownMenuItemWild>
              <Select
                label="RhinoSecure Channel"
                position="top"
                name="secure-channel"
                options={secureThreadFromChannelOptions}
                onSelect={handleSecureFromChannelSelect}
                selected={activeSecureFromChannelId}
              />
              <Select
                label="Secure Notification To"
                name="to-channel"
                position="top"
                options={secureThreadNotificationToChannelOptions}
                selected={activeSecureNotificationToChannelId}
                onSelect={handleSecureNotificationToChannelSelect}
              />
              <Select
                label="Secure Notification From"
                name="from-channel"
                position="top"
                options={secureThreadNotificationFromChannelOptions}
                selected={activeSecureNotificationFromChannelId}
                onSelect={handleSecureNotificationFromChannelSelect}
              />
            </DropdownMenuItemWild>
          </Dropdown>
          <CharacterCount length={messageText.length} additionalClass="character-count__convo-channels" />
        </div>
      </div>
    );
  };

  const renderNoteInfo = () => (
    <div className="convo__footer__actions">
      <div className="convo__message__add-actions--note">
        <EmojiPickerDropdown
          wrapperClassName="emoji-picker__dropdown__wrapper--convo-thread u-m-l-small u-m-r-small"
          dropdownPosition="top"
          onEmojiSelect={handleEmojiClick}
        />
        <MessageComposeActions />
      </div>
    </div>
  );

  const handleMessageTranslate = (targetLanguageCode, targetLanguageId = null) => {
    if (messageText.length === 0) return;

    if (targetLanguageCode === 'en') {
      setMessageTranslation((current) => ({
        ...current,
        isMessageTranslated: false,
        translatedText: '',
        languageId: targetLanguageId,
      }));
      return;
    }

    dispatch(InboxReducer.getTranslatedText({
      text: messageText,
      languageCode: 'en',
      targetLanguage: targetLanguageCode,
    })).then((data) => {
      // setTimeout(() => {
      //   this.scrollTo();
      // }, 200);
      setMessageTranslation((current) => ({
        ...current,
        isMessageTranslated: true,
        translatedText: data.translatedText,
        selectedLanguageForTranslation: supportedLanguages[targetLanguageId],
        languageId: targetLanguageId,
      }));
    });
  };

  // CCRs are not allowed to send messages
  // Remove this area completely for them
  if (!activeUser) return null;
  if (currentUser.isCcr) return false;
  const isFileUploadPostProcessing = attachments.some((attachment) => fileUploadPostProcessing.includes(attachment.key));
  const disableMessageSend = activeUpload || isFileUploadPostProcessing ||
    (!messageText && !attachments.length && !messageForms.length && !sharelinkFiles.length) || sendingMessage;
  const disableSend = () => {
    if (isNote) {
      return sendingMessage || activeUpload || (!messageText && !attachments.length);
    } if (isSecure) return isSecureSendEnabled;
    return disableMessageSend;
  };
  const convoFooterClasses = cx('convo__footer', {
    'is-note': isNote,
  });

  const globeIconClass = cx('translator-icon', {
    'translator-icon--active': isMessageTranslated,
    'translator-icon--inactive': !isMessageTranslated && messageText.trim().length > 0,
  });

  const messageTextareaClass = cx('convo__message__textarea', {
    'convo__message__textarea--disable': isMessageTranslated,
    'u-text-rtl u-m-r': !!(isMessageTranslated && selectedLanguageForTranslation?.isRightToLeft),
  });

  const convoFooterNavClasses = (tab) =>
    cx('convo__footer__nav__item', {
      [UtilitySystem.config.classes.active]: activeConvoTab === tab,
    });

  const translatedLanguageHandlersView = () => (
    <>
      <Button
        reset
        className="translator-button"
        onClick={() => handleMessageTranslate('en')}
      >
        <Icon icon="globe" className={`translator-icon ${globeIconClass}`} />
      </Button>
      <Button
        reset
        className={convoFooterNavClasses('note')}
        onClick={() => handleMessageTranslate('en')}
      >
        {'< English'}
      </Button>
    </>
  );

  const languageSelectorView = () => {
    let label;

    if (selectedLanguageForTranslation) {
      label = selectedLanguageForTranslation.name;
    } else {
      label = recommendedLanguageForTranslation ? recommendedLanguageForTranslation.name : 'Translate to';
    }

    return (
      <>
        {selectedLanguageForTranslation && !recommendedLanguageForTranslation && (
          <Button
            reset
            disabled={isMessageTextEmpty}
            className="translator-button"
            onClick={() => handleMessageTranslate(globeLanguage.languageCode, globeLanguage.id)}
          >
            <Icon icon="globe" className={`translator-icon ${globeIconClass}`} />
          </Button>
        )}
        <Dropdown
          position="top-right"
          disabled={isMessageTextEmpty}
          wide
          label={label}
          type="link"
          className="u-text-body language-selector-dropdown"
          dataCypress="translatedTo"
        >
          {supportedLanguageIds.map((supportedLanguageId) => (
            <div key={supportedLanguageId}>
              <DropdownMenuItem
                label={supportedLanguages[supportedLanguageId].name}
                onClick={() => handleMessageTranslate(supportedLanguages[supportedLanguageId].languageCode, supportedLanguageId)}
              />
            </div>
          ))}
          {recommendedLanguageForTranslation && (
            <div key={recommendedLanguageForTranslation.id}>
              <DropdownMenuDivider />
              <DropdownMenuItem
                label={`${recommendedLanguageForTranslation.name} (recommended)`}
                onClick={() => handleMessageTranslate(recommendedLanguageForTranslation.languageCode, recommendedLanguageForTranslation.id)}
              />
            </div>
          )}
        </Dropdown>
      </>
    );
  };

  function handleFocusMessageInput() {
    setTimeout(() => {
      if (convoFooterRef?.current?.lastElementChild) {
        convoFooterRef.current.lastElementChild.scrollIntoView({ block: 'center' });
      }
    }, 200);
  }

  function handleMessageInputHeightChange() {
    if (focusComposeArea) {
      dispatch(ThreadReducer.setMessageInputHeightChange(true));
    }
  }

  function renderMessageInput() {
    if (isNote) {
      return (
        <MentionComposeArea
          currentUser={currentUser}
          onInput={handleMessageTextChange}
          sendingMessage={sendingMessageLoading}
          showMentionPanel={showMentionPanel}
          toggleMentionPanel={setShowMentionPanel}
          placeholder="Add internal note"
          isNoteMention
          templateToBeAdded={templateToBeAdded}
          handleUpdateMentions={handleUpdateMentions}
          draftMentions={draftMentions}
          emoji={emojiToAdd}
          clearEmoji={clearEmoji}
        />
      );
    }
    return (
      <MessageBox
        className={`convo__message__textarea ${messageTextareaClass}`}
        disabled={isMessageTranslated} // eslint-disable-line react/prop-types
        minRows={1}
        maxHeight="12rem"
        naked
        placeholder={`${messageBoxPlaceholder} ${activeUser ? UserHelpers.formatName(activeUser, true) : null}`}
        onClick={handleMessageTextClick}
        onInput={handleMessageTextChange}
        initialValue={messageTranslation.translatedText || messageText}
        focus={focusComposeArea}
        name="message"
        handleFocus={handleFocusMessageInput}
        onHeightChange={handleMessageInputHeightChange}
        messageBoxRef={messageBoxRef}
        emojiSupport
      />
    );
  }

  const renderTranslationWrapper = () => isMessage && isMessageSendBoxEnabled && isMessageTranslationEnabledForOrg && (
  <div>
    <>
      {recommendedLanguageForTranslation ? (
        <>
          {!isMessageTranslated ? (
            <>
              <Button
                reset
                disabled={isMessageTextEmpty}
                className={`translator-button ${isMessageTextEmpty ? 'u-m-r' : ''}`}
                onClick={() => handleMessageTranslate(globeLanguage.languageCode, globeLanguage.id)}
              >
                <Icon icon="globe" className={`translator-icon ${globeIconClass}`} />
              </Button>
              {messageText.trim().length !== 0 && languageSelectorView()}
            </>
          ) : translatedLanguageHandlersView()}
        </>
      ) : (
        <>
          {isMessageTranslated ? translatedLanguageHandlersView() : languageSelectorView()}
        </>
      )}
    </>
  </div>
  );

  function renderMessageInputContainer() {
    if (isSecure) {
      if (!doesLoggedInUserHaveSecureFromChannel) {
        return (
          <Alert title="No Notification Channels" className="convo__footer__alert">
            You need to set a RhinoSecure channel to send notifications from to communicate via Rhinogram.
          </Alert>
        );
      }
      if (!doesLoggedInUserHaveFromChannel) {
        return (
          <>
            <Alert title={userHasOtherChannelType ? 'Select Channel' : 'No Channels'} className="convo__footer__alert">
              {userHasOtherChannelType ?
                'Please select an available channel to communicate with this contact.' : '  You need access to a RhinoSecure channel to communicate via Rhinogram.'}
            </Alert>
            {userHasOtherChannelType && renderSecureInfo()}
          </>
        );
      }
      if (!userHasAnyOfPermissions([THREAD_MESSAGE_CREATE])) {
        return (
          <Alert title="Messaging Restricted" className="convo__footer__alert">
            Contact your organization administrator to get the necessary permissions to send messages.
          </Alert>
        );
      }
    } if (isMessage) {
      if (!userHasAnyOfPermissions([THREAD_MESSAGE_CREATE])) {
        return (
          <Alert title="Messaging Restricted" className="convo__footer__alert">
            Contact your organization administrator to get the necessary permissions to send messages.
          </Alert>
        );
      } else if (!doesActiveUserHavePhone && !doesActiveUserHaveFacebook && !doesActiveUserHaveRhinogram && !doesActiveUserHaveInstagram) {
        return (
          <Alert title="No Phone Number Found" className="convo__footer__alert">
            This contact does not have a phone number. Please edit their profile and add a phone number in order to communicate via Rhinogram.
          </Alert>
        );
      } else if ((doesActiveUserHaveInstagram || doesActiveUserHavePhone || doesActiveUserHaveFacebook || doesActiveUserHaveRhinogram) && !canUserReceiveMessages) {
        return (
          <Alert title="Missing Phone Ownership" className="convo__footer__alert">
            This contact has a phone number associated, but they do not own it. The contact must own the phone number before messages can be sent.
          </Alert>
        );
      } else if (userOrganization?.disableMessagingHIPAAConsent && isHipaaConsentNotGranted) {
        return (
          <Alert title="Messaging Restricted" className="convo__footer__alert">
            Disabled until HIPAA consent is granted.
          </Alert>
        );
      } else if (!doesActiveUserHaveTextableChannel && doesActiveUserHavePhone) {
        return (
          <Alert title="Opted-Out Number" className="convo__footer__alert">
            {`This contact has opted-out of receiving text messages to this number ${toChannelName}. It is not possible to text this number.`}
            &nbsp;
            <Button
              reset
              type="link"
              size="small"
              url="https://rhinogram.freshdesk.com/support/solutions"
              target="_blank"
              rel="noopen noreferrer"
              title="Learn More"
            >
              Learn More
            </Button>
          </Alert>
        );
      } else if (!doesLoggedInUserHaveFromChannel) {
        return (
          <>
            <Alert title={userHasOtherChannelType ? 'Select Channel' : 'No Channels'} className="convo__footer__alert">
              {userHasOtherChannelType ?
                'Please select an available channel to communicate with this contact.' : 'You need access to a channel to communicate via Rhinogram.'}
            </Alert>
            {userHasOtherChannelType && renderStandardInfo()}
          </>
        );
      }
    } return (
      <>
        <div className={`convo__message__container ${isMessageTranslated ? 'convo__message__container--disable' : ''}`}>
          {(isMessageTranslated && selectedLanguageForTranslation) && (
            <div className="translated-text-language-label">
              <span className="u-text-small u-text-muted">Translated to {selectedLanguageForTranslation.name}</span>
            </div>
          )}
          <div className="convo__message">
            {renderMessageInput()}
          </div>
          <Button
            reset
            className="convo__message__send"
            onClick={() => handleSendMessage(false)}
            loading={sendingMessage}
            disabled={disableSend()}
            title={isNote ? 'Add note' : 'Send message'}
          >
            <Icon icon="send" />
          </Button>
          <Modal
            open={isNonTextableModalShowing}
            size="large"
          >
            <ModalHeader
              onClose={() => setIsNonTextableModalShowing(false)}
              title="Send to Non-textable Number"
              titleSub="Are you sure you want to send a message to a non-textable number? If the message is delivered, it will be automatically marked as textable."
            />
            <ModalFooter>
              <UtilityInlineGrid align="between">
                <Button
                  type="link-muted"
                  className="u-p-l-0"
                  onClick={() => setIsNonTextableModalShowing(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="accent"
                  onClick={() => handleSendMessage(true)}
                  className="button button--secondary"
                >
                  Yes, Send
                </Button>
              </UtilityInlineGrid>
            </ModalFooter>
          </Modal>
        </div>
        <MessageAttachments messageForms={messageForms} />
        {isMessage && (renderStandardInfo())}
        {isSecure && (renderSecureInfo())}
        {isNote && (renderNoteInfo())}
      </>
    );
  }

  return (
    <div className={convoFooterClasses} ref={convoFooterRef}>
      <>
        <ThreadTypingWrapper messageText={messageText} threadType="inbox" />
        <div className="convo__footer__nav">
          <div className="convo__footer__nav__left">
            <Button
              reset
              className={convoFooterNavClasses('patient')}
              onClick={() => setConvoTab('patient')}
              data-cypress="message-tab"
            >
              Message
            </Button>
            {activeUser.typeId !== Types.TYPE_ORGANIZATION && (
            <Button
              reset
              className={convoFooterNavClasses('secure')}
              onClick={() => setConvoTab('secure')}
              data-cypress="rhino-secure-tab"
            >
              RhinoSecure
            </Button>
            )}
            <Button
              reset
              className={convoFooterNavClasses('note')}
              onClick={() => setConvoTab('note')}
              data-cypress="note"
            >Note
            </Button>
          </div>
          {renderTranslationWrapper()}
        </div>
        {renderMessageInputContainer()}
      </>
    </div>
  );
};

InboxThreadCompose.propTypes = {
  activeFromChannelId: PropTypes.number,
  activeSecureFromChannelId: PropTypes.number,
  activeSecureNotificationFromChannelId: PropTypes.number,
  activeSecureNotificationToChannelId: PropTypes.string,
  activeToChannelId: PropTypes.string,
  activeUpload: PropTypes.bool,
  channels: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  fileUploadPostProcessing: PropTypes.array.isRequired,
  otherThreadFromChannelOptions: PropTypes.array,
  phones: PropTypes.object.isRequired,
  secureThreadFromChannelOptions: PropTypes.array.isRequired,
  secureThreadNotificationFromChannelOptions: PropTypes.array.isRequired,
  secureThreadNotificationToChannelOptions: PropTypes.array.isRequired,
  selectedToChannelId: PropTypes.string,
  sendingMessage: PropTypes.bool,
  supportedLanguageIds: PropTypes.array,
  supportedLanguages: PropTypes.object,
  threadFromChannelOptions: PropTypes.array.isRequired,
  messageForms: PropTypes.array,
  attachments: PropTypes.array,
  sharelinkFiles: PropTypes.array,
  userOrganization: PropTypes.object,
  templateToBeAdded: PropTypes.string,
  mostRecentConversationLanguageId: PropTypes.number,
  doesLoggedInUserHaveFromChannel: PropTypes.bool,
  isMessageSendBoxEnabled: PropTypes.bool,
  activeConvoTab: PropTypes.string,
  threadToChannelOptions: PropTypes.array,
  providers: PropTypes.object,
};

const mapStateToProps = (state) => {
  const {
    channel,
    group,
    inbox,
    language,
    phone,
    thread,
    ui,
    user,
  } = state;
  return {
    activeConvoTab: state.thread.activeConvoTab,
    activeFromChannelId: inbox.activeFromChannelId,
    activeSecureFromChannelId: inbox.activeSecureFromChannelId,
    activeSecureNotificationFromChannelId: inbox.activeSecureNotificationFromChannelId,
    activeSecureNotificationToChannelId: inbox.activeSecureNotificationToChannelId,
    activeToChannelId: inbox.activeToChannelId,
    activeUpload: thread.activeUpload,
    attachments: state.thread.attachments,
    channels: channel.channels,
    currentUser: getLoggedInUser(state),
    doesLoggedInUserHaveFromChannel: getDoesLoggedInUserHaveFromChannel(state),
    fileUploadPostProcessing: ui.fileUploadPostProcessing,
    groups: group.groups,
    isMessageSendBoxEnabled: getIsMessageSendBoxEnabled(state),
    messageForms: state.thread.messageForms,
    mostRecentConversationLanguageId: inbox.mostRecentConversationLanguageId,
    organizationMessageTemplateModalOpen: thread.organizationMessageTemplateModalOpen,
    otherThreadFromChannelOptions: getOtherThreadFromChannelOptions(state),
    phones: phone.phones,
    secureThreadFromChannelOptions: getSecureThreadFromChannelOptions(state),
    secureThreadNotificationFromChannelOptions: getSecureThreadNotificationFromChannelOptions(state),
    secureThreadNotificationToChannelOptions: getSecureThreadNotificationToChannelOptions(state),
    selectedToChannelId: getSelectedToChannelId(state),
    sendingMessage: thread.sendingMessage,
    sharelinkFiles: state.thread.sharelinkFiles,
    showMentionPanel: thread.showMentionPanel,
    storageKey: getStorageKey(state),
    supportedLanguageIds: language.languageIds,
    supportedLanguages: language.languages,
    templateToBeAdded: thread.templateToBeAdded,
    threadFromChannelOptions: getThreadFromChannelOptions(state),
    threadLoading: inbox.threadLoading,
    threadToChannelOptions: getThreadToChannelOptions(state),
    userOrganization: getLoggedInUserOrganization(state),
    users: user.users,
    providers: state.provider.providers,
  };
};

export default connect(mapStateToProps)(InboxThreadCompose);
