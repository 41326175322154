import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import cx from 'classnames';
import {
  Button,
  MessageBox,
  Close,
  Icon,
  Scrollbars,
  UtilitySystem,
  FormLabel,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  UtilityInlineGrid,
  LoaderCircle,
  UtilityListItem,
  UtilityList,
  Dropdown,
  DropdownMenuItem,
} from 'rhinostyle';
import { UPLOAD_FILE_TYPES, MAX_STANDARD_FILE_SIZE_TEXT } from '../constants/AppConstants';
import UserSearchContainer from '../containers/UserSearchContainer';
import GroupSearch from './GroupSearch';
import { StringHelpers, AttachmentHelpers } from '../helpers';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import { ASSIGNMENT_GROUP_CREATE, ASSIGNMENT_MEMBER_CREATE } from '../constants/UserPermissionsConstants';
import { categories } from '../constants/RoutingCategories';
import { useEventById, useInboxParams, useLastEvent } from '../hooks';
import { createAssignmentMember, createAssignmentGroup } from '../actions/AssignmentActions';
import { toggleProfile } from '../reducers/threadReducer';
import { getLoggedInUserOrganization, getActiveUser, userHasLimitedProviderRole } from '../selectors/userSelectors';
import { setFileUploadPostProcessingInProgress } from '../reducers/uiReducer';
import { useGetRoutesQuery } from '../services/routingService';

const ConfirmPresenceModal = (props) => {
  const {
    handleClose,
    isOpen,
    handleAssignClick,
    formInProgress,
  } = props;
  return (
    <Modal open={isOpen}>
      <ModalHeader
        onClose={handleClose}
        title="Member Offline"
      />
      <>
        <ModalBody>
          <div className="u-text-center u-text-medium">This member is offline. Are you sure you want to assign this message?</div>
        </ModalBody>
        <ModalFooter>
          <UtilityInlineGrid align="between">
            <Button className="u-text-medium" onClick={handleClose} outlined type="primary">
              {'No, Don\'t Assign'}
            </Button>
            <Button className="u-text-medium" loading={formInProgress} onClick={handleAssignClick} type="primary">
              Yes, Assign
            </Button>
          </UtilityInlineGrid>
        </ModalFooter>
      </>
    </Modal>
  );
};

ConfirmPresenceModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleAssignClick: PropTypes.func.isRequired,
  formInProgress: PropTypes.bool.isRequired,
};

const ContactAssignPanel = (props) => {
  const {
    assignFocus,
    channelDefaultRoute,
    isLoading,
    fileUploadPostProcessing,
    isDirect,
    isAssignedToMe,
    currentUser,
    handleAssign,
    users,
    handleCloseModal,
    userOrganization,
    isModalOpen,
    currentAssignment,
    handleAssignmentComplete,
    openAssignments,
    isLimitedProvider,
  } = props;

  const { userId, groupId } = useInboxParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const threadEvent = useEventById(location?.state?.routeEventId);
  const { mostRecentEvent } = useLastEvent();
  const groupAssignDisabled = hasDuplicateGroupAssignment();
  const [isFollowing, setIsFollowing] = useState(false);
  const [formInProgress, setFormInProgress] = useState(false);
  const [note, setNote] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(threadEvent?.suggestedGroupId || -1);
  const [selectedMemberId, setSelectedMemberId] = useState(threadEvent?.suggestedMemberId || -1);
  const [isNoteVisible, setIsNoteVisible] = useState(false);
  const [activeUpload, setActiveUpload] = useState(false);
  const [fileProgress, setFileProgress] = useState('0');
  const [viewGroups, setViewGroups] = useState(!!userHasAnyOfPermissions([ASSIGNMENT_GROUP_CREATE]) && !groupAssignDisabled);
  const [showConfirmPresenceModal, setShowConfirmPresenceModal] = useState(false);
  const [isFollowClicked, setIsFollowClick] = useState(false);
  const excludedMemberIds = getExcludedMemberIds();
  const isAssignPanel = !location.state?.routeEventId;
  const categoryRoutes = useGetRoutesQuery({ organizationId: userOrganization.id, skip: isAssignPanel });

  function getExcludedMemberIds() {
    if (currentAssignment?.toUserId) {
      return [currentAssignment.toUserId];
    }
    if (isDirect || isAssignedToMe) {
      return [currentUser];
    }
    return [];
  }

  function hasDuplicateGroupAssignment() {
    if (isAssignedToMe && isLimitedProvider) {
      const activeAssignments = openAssignments.filter((assignment) => assignment.assignedUserId === currentUser);
      const activeAssignmentChannelIds = activeAssignments.map((assignment) => assignment.channelId);
      const assignmentExistsToGroup = openAssignments.some((assignment) => assignment.assignedGroupId && activeAssignmentChannelIds.includes(assignment.channelId));
      return assignmentExistsToGroup;
    } return false;
  }

  function resetState() {
    setIsFollowing(false);
    setFormInProgress(false);
    setNote('');
    setAttachments([]);
    setSelectedGroupId(-1);
    setSelectedMemberId(-1);
    setIsNoteVisible(false);
    setActiveUpload(false);
    setFileProgress(false);
  }

  function handleToggleClose() {
    if (handleCloseModal) {
      handleCloseModal();
    } else {
      toggleProfile();
    }
  }

  useEffect(() => {
    if (mostRecentEvent && !isFollowClicked) {
      setIsFollowing(!!mostRecentEvent?.following);
    }
  }, [mostRecentEvent, isFollowClicked]);

  useEffect(() => {
    if (!isModalOpen) {
      resetState();
    }
  }, [isModalOpen]);

  const excludedGroupIds = getExcludedGroupIds();
  function getExcludedGroupIds() {
    if (currentAssignment?.toUserGroupId) {
      return [currentAssignment.toUserGroupId];
    }
    if (isDirect || isAssignedToMe) {
      return [groupId];
    }
    return [];
  }
  const [selectedCategory, setSelectedCategory] = useState(threadEvent?.suggestedTextClassificationType || null);

  function handleSelectCategory(categoryId) {
    setSelectedCategory(categoryId);
    const matchingRoute = categoryRoutes?.data?.textclassificationroutes?.find((route) => route.textClassificationTypes.includes(categoryId));
    if (matchingRoute?.toGroupId) {
      setViewGroups(true);
      handleSelectGroup(matchingRoute.toGroupId);
    } else if (matchingRoute?.toMemberId) {
      setViewGroups(false);
      handleSelectMember(matchingRoute.toMemberId);
    }
  }

  function handleSelectMember(id) {
    if (selectedGroupId) {
      setSelectedGroupId(-1);
    }
    setSelectedMemberId(id);
  }

  function handleSelectGroup(id) {
    if (selectedMemberId) {
      setSelectedMemberId(-1);
    }
    setSelectedGroupId(id);
  }

  let uploaderInput;

  const handleFollowClick = () => {
    setIsFollowing((current) => !current);
    setIsFollowClick(true);
  };

  const handleToggleView = () => {
    setViewGroups((current) => !current);
  };

  const handleToggleNote = () => {
    setIsNoteVisible((current) => !current);
    setNote((current) => !current);
  };

  useEffect(() => {
    if (!isNoteVisible) {
      setNote('');
    }
  }, [isNoteVisible]);

  const toggleConfirmPresenceModal = () => {
    setShowConfirmPresenceModal((current) => !current);
  };

  const handleTextChange = (id, text) => {
    setNote(text);
  };

  const checkMemberPresence = (memberId) => !!(users?.[memberId]?.online);

  function shapeRequest() {
    if (currentAssignment) {
      return {
        assignmentId: currentAssignment.assignmentId,
        patientUserId: currentAssignment.patientUserId,
        groupId: currentAssignment.toUserGroupId,
        memberId: currentAssignment.toUserId,
        following: isFollowing,
        ...(note.length || attachments.length) && {
          note,
          attachments,
        },
        ...(isAssignedToMe || isDirect) && {
          assigned: true,
        },
        ...selectedGroupId > 0 && {
          toUserGroupId: selectedGroupId,
        },
        ...selectedMemberId > 0 && {
          toMemberId: selectedMemberId,
        },
      };
    }
    return {
      patientUserId: userId,
      groupId,
      following: isFollowing,
      ...(note.length || attachments.length) && {
        note,
        attachments,
      },
      ...(isAssignedToMe || isDirect) && {
        assigned: true,
      },
      ...selectedGroupId > 0 && {
        toUserGroupId: selectedGroupId,
      },
      ...selectedMemberId > 0 && {
        toMemberId: selectedMemberId,
      },
      ...!isAssignPanel && {
        messageEventId: threadEvent.messageEventId || null,
        suggestedMemberId: threadEvent.suggestedMemberId || null,
        suggestedGroupId: threadEvent.suggestedGroupId || null,
        suggestedTextClassificationType: threadEvent.suggestedTextClassificationType,
        assignedTextClassificationType: selectedCategory,
        assignmentMethod: 'Suggested Text Classifier',
      },

    };
  }
  function navigateToListView() {
    if (handleAssignmentComplete) {
      handleAssignmentComplete();
    } else {
      const newLocation = location.pathname.substring(0, location.pathname.indexOf('/user'));

      history.push(newLocation);
    }
  }

  const handleAssignClick = async (checkForMemberPresence) => {
    if (handleAssign) {
      handleAssign(shapeRequest(), isFollowClicked);
    } else {
      const payload = shapeRequest();
      setFormInProgress(true);
      if (payload.toMemberId) {
        const renderConfirmPresenceModal = checkForMemberPresence === true && !checkMemberPresence(payload.toMemberId);
        if (renderConfirmPresenceModal) {
          toggleConfirmPresenceModal();
          setFormInProgress(false);
        } else {
          await dispatch(createAssignmentMember(payload, true));
          resetState();
          if (payload.toMemberId !== payload.suggestedMemberId) {
            navigateToListView();
          } else {
            history.push({ state: {} });
          }
        }
      } else if (payload.toUserGroupId) {
        await dispatch(createAssignmentGroup(payload, true));
        resetState();
        if (payload.toUserGroupId !== payload.suggestedGroupId) {
          navigateToListView();
        } else {
          history.push({ state: {} });
        }
      }
    }
  };

  const isFileUploadPostProcessing = attachments.some((attachment) => fileUploadPostProcessing.includes(attachment.key));

  useEffect(() => {
    if (threadEvent?.suggestedTextClassificationType) {
      setSelectedCategory(threadEvent?.suggestedTextClassificationType);
    }
  }, [threadEvent?.suggestedTextClassificationType]);

  const handleAddFile = (event, standard) => {
    setActiveUpload(true);

    const handleProgressDisplay = (progressText) => {
      setFileProgress(progressText);
    };

    const uploadOpts = {
      file: event.target.files[0],
      currentAttachments: [...attachments],
      secureMode: true,
      orgId: userOrganization.id,
      standard,
      progressCallback: handleProgressDisplay,
    };

    AttachmentHelpers.handleAddFile(uploadOpts, handleUploadComplete);
  };
  const handleRemoveAttachment = (index) => {
    setAttachments((current) => current.filter((att, i) => i !== index));
  };

  function handleUploadComplete(newState) {
    const newAttachment = newState.attachments
      .filter((key) => !Object.keys(attachments).includes(key))
      .pop();

    if (newAttachment?.isPostProcessingNeeded) {
      dispatch(setFileUploadPostProcessingInProgress(newAttachment));
    }
    setActiveUpload(false);
    setAttachments(newState.attachments);
  }

  const handleAddFileClick = () => {
    uploaderInput.click();
  };

  const tabTriggerMemberClasses = cx('tabs__trigger', {
    [UtilitySystem.config.classes.active]: !viewGroups,
  });

  const tabTriggerGroupsClasses = cx('tabs__trigger', {
    [UtilitySystem.config.classes.active]: viewGroups,
  });

  const assignFooterClasses = (method) =>
    cx('u-text-small assign-panel__footer__nav__item', {
      [UtilitySystem.config.classes.active]:
        (method === 'note' && note) || (method === 'follow' && isFollowing),
    });

  const renderViewMembers = () => {
    const defaultRouteMemberId =
      (channelDefaultRoute && channelDefaultRoute.type === 'member') ? channelDefaultRoute.userId : -1;

    return (
      <UserSearchContainer
        type="preloadedMembers"
        interfaceMode="radio"
        selectedUserId={selectedMemberId}
        handleSearchSelect={handleSelectMember}
        disabledUserIds={excludedMemberIds}
        defaultRouteMemberId={defaultRouteMemberId}
        focus={assignFocus}
        isAssignmentSearch
        isHidden={viewGroups}
      />
    );
  };

  const renderLoader = () => (
    <div className="convo__message__compose__attachments__loader">
      <LoaderCircle size="xsmall" className="u-text-primary" />&nbsp;&nbsp;&nbsp;<span className="u-text-primary u-text-small">{fileProgress}</span>
    </div>
  );

  const renderAttachmentLink = (attachment, key) => (
    <UtilityListItem key={key} className="u-text-small">
      {fileUploadPostProcessing.includes(attachment.key) ? (
        <Button
          reset
          className="u-text-primary"
        >
          <Icon icon="attachment" />&nbsp; Processing file...
        </Button>
      ) : (
        <Button
          reset
          className="u-text-primary"
          onClick={() => handleRemoveAttachment(key, attachment)}
        >
          <Icon icon="attachment" />&nbsp; {StringHelpers.formatFilename(attachment.name)}&nbsp;&nbsp;
          <span className="u-text-muted"><Icon size="small" icon="close" /></span>
        </Button>
      )}
    </UtilityListItem>
  );

  const renderMessageAttachments = (files) => (
    <div style={{ padding: '1rem' }}>
      {files.length > 0 && (
        <UtilityList style={{ margin: 0 }}>
          {files.map(renderAttachmentLink)}
        </UtilityList>
      )}
      {activeUpload ? renderLoader() : null}
    </div>
  );

  const renderViewGroups = () => {
    const defaultRouteGroupId =
      (channelDefaultRoute && channelDefaultRoute.type === 'group') ? channelDefaultRoute.groupId : -1;

    return (
      <GroupSearch
        handleSearchSelect={handleSelectGroup}
        interfaceMode="radio"
        showTooltip={false}
        selectedGroupId={selectedGroupId}
        excludedGroupIds={excludedGroupIds}
        defaultRouteGroupId={defaultRouteGroupId}
        focus={assignFocus}
        excludeChatGroups
        prepopulate
        isHidden={!viewGroups}
      />
    );
  };

  const renderMessageComposeActions = () => {
    const iconClass = 'convo__message__icon-button';
    return (
      <div className="u-flex u-flex-align-items-center">
        <Button
          title={`Add File (must be ${MAX_STANDARD_FILE_SIZE_TEXT} or less)`}
          className={iconClass}
          onClick={handleAddFileClick}
          reset
          data-cypress="Add File"
        >
          <Icon icon="attachment" />
        </Button>
      </div>
    );
  };

  const title = isAssignPanel ? 'Assign' : 'Change Category/Assignment';
  const bodyClasses = cx('summary-panel__body', {
    'summary-panel__body--category': !isAssignPanel,
  });

  const categoryClasses = cx('summary-panel__dropdown form__control', {
    'summary-panel__dropdown--accent': selectedCategory && selectedCategory === threadEvent?.suggestedTextClassificationType,
  });

  return (
    <>
      <div className="summary-panel__wrapper app-panels--hide-icons-desktop">
        <div className="summary-panel">
          <div className="app-page__header">
            <div className="app-page__header__title">
              {title}
              {!isAssignPanel && (
                <div className="app-page__header__titlesub">Patient Initiated Message Assignment</div>
              )}
            </div>
            <div className="app-page__header__action">
              <Close
                className="app-page__header__action__close"
                onClick={handleToggleClose}
              />
            </div>
          </div>
          <Scrollbars className={bodyClasses}>
            <div className="summary-panel__content">
              {!isAssignPanel && (
                <>
                  <label>Category</label>
                  <Dropdown
                    title="Category"
                    label="Select A Category"
                    type="outline"
                    position="right"
                    wide
                    activeKey={selectedCategory}
                    className={categoryClasses}
                    dataCypress="categorySelectDropdown"
                  >
                    {categories.map((category) => (
                      <DropdownMenuItem
                        key={category.id}
                        id={category.id}
                        label={category.id}
                        dataCypress={`dropdownItem-${category.name}`}
                        onClick={() => {
                          handleSelectCategory(category.id);
                        }}
                      />
                    ))}
                  </Dropdown>
                </>
              )}
              <>
                <div className="tabs__header">
                  {userHasAnyOfPermissions([ASSIGNMENT_MEMBER_CREATE]) && (
                    <Button data-cypress="assign-members" id="assign__members" reset className={tabTriggerMemberClasses} onClick={handleToggleView}>
                      Members
                    </Button>
                  )}
                  {userHasAnyOfPermissions([ASSIGNMENT_MEMBER_CREATE]) && userHasAnyOfPermissions([ASSIGNMENT_GROUP_CREATE]) && (
                    <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                  )}
                  {userHasAnyOfPermissions([ASSIGNMENT_GROUP_CREATE]) && (
                    <Button id="assign__groups" reset className={tabTriggerGroupsClasses} onClick={handleToggleView} disabled={groupAssignDisabled}>
                      Groups
                    </Button>
                  )}
                </div>
                {userHasAnyOfPermissions([ASSIGNMENT_GROUP_CREATE]) && renderViewGroups()}
                {userHasAnyOfPermissions([ASSIGNMENT_MEMBER_CREATE]) && renderViewMembers()}
                {isNoteVisible && (
                  <div>
                    <FormLabel className="u-m-t-large" id="label">Note</FormLabel>
                    <div className="assignment__note__panel">
                      <div className="convo__message__container">
                        <div className="convo__message">
                          <MessageBox
                            className="convo__message__textarea"
                            minRows={1}
                            maxHeight="12rem"
                            name="note"
                            onInput={handleTextChange}
                            placeholder="Add internal note"
                            naked
                          />
                        </div>
                      </div>
                      {(activeUpload || attachments.length > 0) && renderMessageAttachments(attachments)}
                    </div>
                    <div className="convo__footer__actions--contact-assign-panel">
                      {renderMessageComposeActions()}
                    </div>
                    <input
                      className="u-sr-only"
                      type="file"
                      ref={(c) => (uploaderInput = c)}
                      onClick={() => { uploaderInput.value = null; }}
                      accept={UPLOAD_FILE_TYPES}
                      onChange={(e) => handleAddFile(e, true)}
                    />
                    <div className="u-m-t">
                      <span className="u-text-small u-text-info u-text-muted u-text-italic"> This note will be attached when assigned.</span>
                    </div>
                  </div>
                )}
                <div className="assign-panel__footer">
                  <div className="assign-panel__footer__left">
                    <Button
                      onClick={handleToggleNote}
                      reset
                      className={assignFooterClasses('note')}
                    >
                      <Icon size="large" icon="note" />&nbsp; Add Note
                    </Button>
                    {!isLimitedProvider && (
                      <Button
                        onClick={handleFollowClick}
                        reset
                        className={assignFooterClasses('follow')}
                      >
                        <Icon size="large" icon="star" />&nbsp; Follow
                      </Button>
                    )}

                  </div>
                  <div className="assign-panel__footer__right">
                    <Button
                      data-cypress="assign-final-button"
                      id="assign__final__button"
                      size="small"
                      type="primary"
                      disabled={(selectedGroupId < 0 && selectedMemberId < 0) || isFileUploadPostProcessing}
                      onClick={() => handleAssignClick(true)}
                      loading={formInProgress || isLoading}
                    >
                      Assign
                    </Button>
                  </div>
                </div>
              </>
            </div>
          </Scrollbars>
        </div>
      </div>
      <ConfirmPresenceModal
        handleClose={toggleConfirmPresenceModal}
        formInProgress={formInProgress}
        isOpen={showConfirmPresenceModal}
        handleAssignClick={handleAssignClick}
      />
    </>
  );
};

ContactAssignPanel.propTypes = {
  assignFocus: PropTypes.bool.isRequired,
  channelDefaultRoute: PropTypes.object,
  isLoading: PropTypes.bool,
  fileUploadPostProcessing: PropTypes.array.isRequired,
  isDirect: PropTypes.bool,
  isAssignedToMe: PropTypes.bool,
  currentUser: PropTypes.number,
  handleAssign: PropTypes.func,
  users: PropTypes.object,
  handleCloseModal: PropTypes.func,
  userOrganization: PropTypes.object,
  isModalOpen: PropTypes.bool,
  currentAssignment: PropTypes.object,
  handleAssignmentComplete: PropTypes.func,
  isLimitedProvider: PropTypes.bool,
  openAssignments: PropTypes.array,
};

const mapStateToProps = (state, props) => {
  const { user, inbox, channel, ui, thread } = state;
  const defaultChannelId = props.bulkActiveFromChannelId || inbox.activeFromChannelId;
  return {
    activeFromChannelId: inbox.activeFromChannelId,
    activeUser: getActiveUser(state),
    channelDefaultRoute: channel.channels[defaultChannelId]?.route,
    groupId: inbox.groupId,
    isAssignedToMe: inbox.assigned,
    isDirect: inbox.direct,
    currentUser: state.auth.currentUser,
    users: user.users,
    userOrganization: getLoggedInUserOrganization(state),
    fileUploadPostProcessing: ui.fileUploadPostProcessing,
    activePanel: thread.activePanel,
    openAssignments: inbox.openAssignments,
    isLimitedProvider: userHasLimitedProviderRole(state),
  };
};

export default connect(mapStateToProps)(ContactAssignPanel);
