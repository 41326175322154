import axios from 'axios';
import moment from 'moment-timezone';
import { createSlice } from '@reduxjs/toolkit';

import { exists, arrayToObj } from '../helpers/DataHelpers';
import { UserHelpers, SearchHelpers } from '../helpers';

import { ALL, DEFAULT_ANALYTICS_PAGE_SIZE } from '../constants/AppConstants';

import { setError } from './uiReducer';

// SLICE
const analyticsSlice = createSlice({
  name: 'ANALYTICS',
  initialState: {
    closedConversations: {},
    openConversations: {},
    medicaidConversations: {},
    pageLoading: true,
    isClosedActivitiesLoading: true,
    isOpenActivitiesLoading: true,
    isMedicaidActivitiesLoading: true,
    isCampaignsActivitiesLoading: true,
    loading: false,
    selectedContactIds: [],
    selectedContacts: {},
    selectedMemberIds: [],
    selectedMembers: {},
    getContacts: {},
    contactsIds: [],
    members: {},
    membersIds: [],
  },
  reducers: {
    receiveData: (state, action) => ({
      ...state,
      messages: [
        ...action.payload.messages,
      ],
      contacts: {
        ...action.payload.contacts,
      },
      responseTime: {
        ...action.payload.responseTime,
      },
      peakTime: {
        ...action.payload.peakTime,
      },
      pageLoading: false,
    }),
    receiveClosedConversations: (state, action) => ({
      ...state,
      closedConversations: {
        ...action.payload,
      },
      isClosedActivitiesLoading: false,
    }),
    receiveOpenConversations: (state, action) => ({
      ...state,
      openConversations: {
        ...action.payload,
      },
      isOpenActivitiesLoading: false,
    }),
    receiveMedicaidConversations: (state, action) => ({
      ...state,
      medicaidConversations: {
        ...action.payload.data,
      },
      isMedicaidActivitiesLoading: false,
    }),
    receiveCampaignsConversations: (state, action) => ({
      ...state,
      campaignsConversations: {
        ...action.payload,
      },
      isCampaignsActivitiesLoading: false,
    }),
    receiveCampaignsFailedContacts: (state, action) => ({
      ...state,
      campaignsFailedContacts: {
        ...action.payload,
      },
      isCampaignsFailedContactsLoading: false,
    }),
    receiveContacts: (state, action) => ({
      ...state,
      getContacts: action.payload.data,
      contactsIds: action.payload.ids,
    }),
    receiveMembers: (state, action) => ({
      ...state,
      members: action.payload.data,
      membersIds: action.payload.ids,
    }),
    updateSelectedContacts: (state, action) => ({
      ...state,
      selectedContactIds: action.payload.selectedIds,
      selectedContacts: action.payload.selectedItems,
    }),
    updateSelectedMembers: (state, action) => ({
      ...state,
      selectedMemberIds: action.payload.selectedIds,
      selectedMembers: action.payload.selectedItems,
    }),
    clearAllSelectedContacts: (state) => ({
      ...state,
      selectedContactIds: [],
      selectedContacts: {},
    }),
    clearAllSelectedMembers: (state) => ({
      ...state,
      selectedMemberIds: [],
      selectedMembers: {},
    }),
    clearAllSelectedFilters: (state) => ({
      ...state,
      selectedContactIds: [],
      selectedMemberIds: [],
      selectedMembers: {},
      selectedContacts: {},
    }),
    requestData: (state) => ({
      ...state,
      pageLoading: true,
    }),
    requestClosedConversations: (state) => ({
      ...state,
      isClosedActivitiesLoading: true,
    }),
    requestOpenConversations: (state) => ({
      ...state,
      isOpenActivitiesLoading: true,
    }),
    requestMedicaidConversations: (state) => ({
      ...state,
      isMedicaidActivitiesLoading: true,
    }),
    requestCampaignsConversations: (state) => ({
      ...state,
      isCampaignsActivitiesLoading: true,
    }),
    requestCampaignsFailedContacts: (state) => ({
      ...state,
      isCampaignsFailedContactsLoading: true,
    }),
  },
});

export default analyticsSlice.reducer;

// ACTIONS
export const {
  receiveData,
  receiveClosedConversations,
  receiveOpenConversations,
  receiveMedicaidConversations,
  receiveCampaignsConversations,
  receiveCampaignsFailedContacts,
  receiveContacts,
  receiveMembers,
  updateSelectedContacts,
  updateSelectedMembers,
  clearAllSelectedContacts,
  clearAllSelectedMembers,
  clearAllSelectedFilters,
  requestData,
  requestClosedConversations,
  requestOpenConversations,
  requestMedicaidConversations,
  requestCampaignsConversations,
  requestCampaignsFailedContacts,
} = analyticsSlice.actions;

// THUNKS -- ASYNC ACTION CREATORS
export function fetchAnalyticsData(fromDate, toDate) {
  return (dispatch) => {
    dispatch(requestData());
    return axios.all([
      fetchMessageData(fromDate, toDate),
      fetchContactData(fromDate, toDate),
      fetchResponseTime(fromDate, toDate),
      fetchPeakTime(fromDate, toDate),
    ])
      .then(axios.spread((messages, contacts, responseTime, peakTime) => {
        dispatch(receiveData(
          getAnalyticsPayload(
            messages,
            contacts,
            responseTime,
            peakTime,
          ),
        ));
      }))
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));
      });
  };
}

export function fetchMedicaidConversationsView(filters) {
  return (dispatch) => {
    dispatch(requestMedicaidConversations());
    return axios.all([
      fetchMedicaidConversationsData(filters),
      fetchFilteredData('contacts'),
      fetchFilteredData('members'),
    ])
      .then(axios.spread((conversations, contacts, members) => {
        const { data: contactsData, arrayOfIds: contactsIds } = receiveFilterData(contacts);
        const { data: membersData, arrayOfIds: membersIds } = receiveFilterData(members);
        dispatch(receiveContacts(getFiltersDataPayload(contactsData, contactsIds)));
        dispatch(receiveMembers(getFiltersDataPayload(membersData, membersIds)));
        dispatch(receiveMedicaidConversations(conversations));
      }))
      .catch((err) => {
        dispatch(setError(err.response || err));
      });
  };
}

export function fetchFilterData(search, type, includeInactiveAndDeleted = true) {
  const scope = type === 'contacts' ? 'nonMembers' : type;
  const url = search ? SearchHelpers.getSearchUrl({ search, scope, includeInactiveAndDeleted, type: 'name' }) : `/users/allMembersOrContacts?userType=${type}`;

  return (dispatch) =>
    axios.get(url, {
      ...!axios?.defaults?.headers?.common?.Authorization && {
        headers: { Authorization: 'legacy' }, // both values needed for ApiGateway
      } })
      .then((response) => {
        const { data, arrayOfIds } = receiveFilterData(response, true);
        if (scope === 'nonMembers') {
          dispatch(receiveContacts(getFiltersDataPayload(data, arrayOfIds)));
        } else if (scope === 'members') {
          dispatch(receiveMembers(getFiltersDataPayload(data, arrayOfIds)));
        }
      })
      .catch((err) => dispatch(handleError(err)));
}

export function getAnalyticsPayload(messages, contacts, responseTime, peakTime) {
  return {
    messages: messages.data,
    contacts: contacts.data,
    responseTime: responseTime.data,
    peakTime: peakTime.data,
  };
}

export function fetchMessageData(fromDate, toDate) {
  return axios.get(`/analytics/messages?from=${fromDate}&to=${toDate}`)
    .catch((err) => console.error(err.response || err));
}

export function fetchContactData(fromDate, toDate) {
  return axios.get(`/analytics/contacts?from=${fromDate}&to=${toDate}`)
    .catch((err) => console.error(err.response || err));
}

export function fetchPeakTime(fromDate, toDate) {
  return axios.get(`/analytics/peaktime?from=${fromDate}&to=${toDate}`)
    .catch((err) => console.error(err.response || err));
}

export function fetchResponseTime(fromDate, toDate) {
  return axios.get(`/analytics/responseTime?from=${fromDate}&to=${toDate}`)
    .catch((err) => console.error(err.response || err));
}

export function fetchClosedConversations(fromDate, toDate, pageNo = 0, pageSize = DEFAULT_ANALYTICS_PAGE_SIZE) {
  return (dispatch) => {
    dispatch(requestClosedConversations());
    return axios.get(`/analytics/closedConversations?from=${fromDate}&to=${toDate}&pageNo=${pageNo}&pageSize=${pageSize}`)
      .then((activities) => {
        dispatch(receiveClosedConversations(activities.data));
      })
      .catch((err) => {
        dispatch(setError(err.response || err));
      });
  };
}

export function fetchOpenConversations(pageNo = 0, pageSize = DEFAULT_ANALYTICS_PAGE_SIZE, filterBy = ALL) {
  return (dispatch) => {
    dispatch(requestOpenConversations());
    return axios.get(`/analytics/openConversations?pageNo=${pageNo}&pageSize=${pageSize}&filterBy=${filterBy}`)
      .then((activities) => {
        dispatch(receiveOpenConversations(activities.data));
      })
      .catch((err) => {
        dispatch(setError(err.response || err));
      });
  };
}

export function fetchMedicaidConversationsData(filters) {
  const url = filterData(filters);
  return axios.get(url);
}

function getFiltersDataPayload(data, ids = []) {
  return {
    data,
    ids,
  };
}

export function fetchMedicaidConversations(filters) {
  filterData(filters);
  const url = filterData(filters);
  return (dispatch) => {
    dispatch(requestMedicaidConversations());
    return axios.get(url)
      .then((conversations) => {
        dispatch(receiveMedicaidConversations(conversations));
      })
      .catch((err) => {
        dispatch(setError(err.response || err));
      });
  };
}

export function fetchCampaignsFailedContacts(id) {
  return (dispatch) => {
    dispatch(requestCampaignsFailedContacts());
    return axios.get(`/analytics/bulkMessages/${id}?status=failed`)
      .then((activities) => {
        dispatch(receiveCampaignsFailedContacts(activities.data));
      })
      .catch((err) => {
        dispatch(setError(err.response || err));
      });
  };
}

function filterData(filters) {
  const {
    medicaidStartDate,
    medicaidEndDate,
    pageNo = 0,
    pageSize = DEFAULT_ANALYTICS_PAGE_SIZE,
    sortOrder,
    sortBy,
    memberIds,
    contactIds,
  } = filters;
  const baseUrl = '/analytics/medicaidConversations';
  let url = `${baseUrl}?from=${medicaidStartDate}&to=${medicaidEndDate}&pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=${sortOrder}&sortBy=${sortBy}`;
  if (memberIds.length > 0) {
    url = `${url}&members=${memberIds}`;
  }
  if (contactIds.length > 0) {
    url = `${url}&contacts=${contactIds}`;
  }
  return url;
}

// Integration Analytics

export function getLinerAnalytics(orgId, dateStr) {
  const utcDate = moment(dateStr).utc().format('YYYY-MM-DD');
  return axios.get(`${process.env.REACT_APP_LINER_BASE_URL}/analytics/${orgId}/${utcDate}`);
}

export function getFeederAnalytics(orgId, dateStr) {
  const utcDate = moment(dateStr).utc().format('YYYY-MM-DD');
  return axios.get(`${process.env.REACT_APP_FEEDER_BASE_URL}/analytics/${orgId}/${utcDate}`);
}

export function getApiAnalytics(orgId, dateStr) {
  const utcDate = moment(dateStr).utc().format('YYYY-MM-DD');
  return axios.get(`/integrations/rhinoapiData?orgId=${orgId}&date=${utcDate}`);
}

// Helpers
function handleError(err) {
  return (dispatch) => {
    const error = err.response || err;
    dispatch(setError(error));
  };
}

export function fetchFilteredData(type) {
  return axios.get(`/users/allMembersOrContacts?userType=${type}`);
}

function receiveFilterData(response, isSearch) {
  const userResponse = isSearch ? response.data?.users : response.data;
  const filteredData = userResponse ? arrayToObj(userResponse, 'id') : {};
  let arrayOfIds = [];

  if (exists(response) && exists(userResponse)) {
    arrayOfIds = userResponse.map((item) => item.id);
  }

  Object.keys(filteredData).map((item) => {
    if (filteredData[item].firstName && filteredData[item].lastName) {
      filteredData[item].name = UserHelpers.formatAvatarName(filteredData[item].firstName, filteredData[item].lastName);
      filteredData[item].title = `${filteredData[item].firstName} ${filteredData[item].lastName}`;
    } else {
      filteredData[item].title = (filteredData[item].firstName || filteredData[item].lastName) ? `${filteredData[item].firstName || filteredData[item].lastName}` : '';
    }

    return item;
  });

  return {
    data: filteredData,
    arrayOfIds,
  };
}

export function setSelectedContacts(selectedData) {
  return (dispatch) => {
    dispatch(updateSelectedContacts(selectedData));
  };
}

export function setSelectedMembers(selectedData) {
  return (dispatch) => {
    dispatch(updateSelectedMembers(selectedData));
  };
}

export function handleClearSelectedContacts() {
  return (dispatch) => {
    dispatch(clearAllSelectedContacts());
  };
}

export function handleClearSelectedMembers() {
  return (dispatch) => {
    dispatch(clearAllSelectedMembers());
  };
}

export function handleClearAllFilters() {
  return (dispatch) => {
    dispatch(clearAllSelectedFilters());
  };
}
