import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import { fetchBillingAndSubscriptionPlan } from '../reducers/billingReducer';
import MemberProfilePanel from '../components/MemberProfilePanel';
import { UserHelpers, AddonHelpers } from '../helpers';
import * as UserReducer from '../reducers/userReducer';
import { AppConstants } from '../constants';
import { getLoggedInUser } from '../selectors/userSelectors';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import CustomError from '../helpers/CustomErrorHelper';

class MemberProfilePanelContainer extends Component {
  state = this.initialState;

  get initialState() {
    return {
      modalFormInProgress: false,
      passwordFormInProgress: false,
      showDeactivateMemberModal: false,
      showActivateMemberModal: false,
      isTempPasswordConfirmVisible: false,
      temporaryPassword: null,
      copyPasswordText: 'Copy',
      isWarning: false,
      fee: 0,
      isLoaderShow: false,
      isActivate: true,
      isInviteSendInProgress: false,
    };
  }

  componentDidMount() {
    this.props.fetchUser(this.props.activeUser.id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.subscriptionLoading !== this.props.subscriptionLoading && this.props.subscriptionLoading) {
      this.setState({ isLoaderShow: false }); // eslint-disable-line react/no-did-update-set-state
    }

    const activeUserChanged = prevProps.activeUser.id !== this.props.activeUser.id;

    if (this.props.activeUser.id && prevProps.activeUser.id && activeUserChanged) {
      this.setState(this.initialState); // eslint-disable-line react/no-did-update-set-state
      this.props.fetchUser(this.props.activeUser.id);
    }

    if (this.state.isActivate && !isEqual(this.props, prevProps)) {
      this.setState(AddonHelpers.getAddonFee(0, this.props, AppConstants.MEMBER_PRODUCT)); // eslint-disable-line react/no-did-update-set-state
    } else if (!isEqual(this.props, prevProps)) {
      this.setState(AddonHelpers.getAddonFee(1, this.props, AppConstants.MEMBER_PRODUCT)); // eslint-disable-line react/no-did-update-set-state
    }

    if (this.props.billingError) {
      throw new CustomError({ type: 'Billing API', message: AppConstants.BILLING_ERROR_MESSAGE });
    }
  }

  handleCreateTemporaryPassword = () => {
    const temporaryPassword = UserHelpers.generateTempPassword();

    this.setState({ passwordFormInProgress: true });

    this.props.updateUser(this.props.activeUser.id, { password: temporaryPassword })
      .then(() => {
        this.setState({ temporaryPassword, passwordFormInProgress: false });
      });
  }

  handleUpdateActiveStatus = (active = true) => {
    const { loginEmail, typeId, id } = this.props.activeUser;
    this.setState({ modalFormInProgress: true });
    this.props.updateUser(id, { active, loginEmail, typeId })
      .then(() => {
        this.setState({ showDeactivateMemberModal: false, showActivateMemberModal: false, modalFormInProgress: false });
        if (this.props.handleUpdateMemberActiveStatus) {
          this.props.handleUpdateMemberActiveStatus(id);
        } else {
          this.props.handleTogglePanel();
        }
      });
  }

  handleActivateDeactivate = (name) => {
    if (name === 'showDeactivateMemberModal') {
      this.setState({ [name]: !this.state[name], isLoaderShow: true, isActivate: false });
    } else {
      this.setState({ [name]: !this.state[name], isLoaderShow: true, isActivate: true });
    }
    this.props.fetchBillingAndSubscriptionPlan();
  }

  handleToggle = (name) => {
    if (name === 'showDeactivateMemberModal') {
      const fee = AddonHelpers.getAddonFee(1, this.props, AppConstants.MEMBER_PRODUCT);
      this.setState(fee);
    }
    if (name === 'showActivateMemberModal') {
      const fee = AddonHelpers.getAddonFee(0, this.props, AppConstants.MEMBER_PRODUCT);
      this.setState(fee);
    }
    this.setState({ [name]: !this.state[name] });
  }

  handleModalReverseComplete = () => {
    this.setState({
      modalFormInProgress: false,
    });
  }

  handleCopyClick = () => {
    this.setState({
      copyPasswordText: 'Copied!',
    }, () => {
      setTimeout(() => {
        this.setState({
          copyPasswordText: 'Copy',
        });
      }, 2500);
    });
  }

  handleSendInvite = (userId) => {
    this.setState({ isInviteSendInProgress: true });
    this.props.sendInviteToUser(userId, { isInviteSent: true })
      .then(() => this.setState({ isInviteSendInProgress: false }));
  };

  render() {
    const props = {
      activeUser: this.props.activeUser,
      copyPasswordText: this.state.copyPasswordText,
      currentUser: this.props.currentUser,
      channels: this.props.channels,
      fetchUser: this.props.fetchUser,
      groups: this.props.groups,
      handleCopyClick: this.handleCopyClick,
      handleCreateTemporaryPassword: this.handleCreateTemporaryPassword,
      handleModalReverseComplete: this.handleModalReverseComplete,
      handleToggle: this.handleToggle,
      handleTogglePanel: this.props.handleTogglePanel,
      handleUpdateActiveStatus: this.handleUpdateActiveStatus,
      isTempPasswordConfirmVisible: this.state.isTempPasswordConfirmVisible,
      modalFormInProgress: this.state.modalFormInProgress,
      passwordFormInProgress: this.state.passwordFormInProgress,
      showDeactivateMemberModal: this.state.showDeactivateMemberModal,
      showActivateMemberModal: this.state.showActivateMemberModal,
      isGoToChatButtonVisible: !this.props.location.pathname.includes('chat') && this.props.currentUser.id !== this.props.activeUser.id,
      tags: this.props.tags,
      temporaryPassword: this.state.temporaryPassword,
      timeZones: this.props.timeZones,
      isWarning: this.state.isWarning,
      perMemberFee: this.state.fee,
      handleActivateDeactivate: this.handleActivateDeactivate,
      isLoaderShow: this.state.isLoaderShow,
      isCcr: this.props.currentUser && this.props.currentUser.isCcr,
      handleSendInvite: this.handleSendInvite,
      isInviteSendInProgress: this.state.isInviteSendInProgress,
      isRhinoformEnabled: this.props.isRhinoformEnabled,
      isEditIntegratedContactEnabled: this.props.activeOrg?.isEditIntegratedContactEnabled,
      integrationPartnerTypeId: this.props.activeOrg?.integrationPartnerTypeId,
    };

    return <MemberProfilePanel {...props} />;
  }
}

MemberProfilePanelContainer.propTypes = {
  activeUser: PropTypes.object.isRequired,
  channels: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  fetchUser: PropTypes.func,
  groups: PropTypes.object.isRequired,
  handleTogglePanel: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  tags: PropTypes.object.isRequired,
  timeZones: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
  productCount: PropTypes.number.isRequired,
  billing: PropTypes.object,
  subscription: PropTypes.object,
  handleActivateDeactivate: PropTypes.func,
  fetchBillingAndSubscriptionPlan: PropTypes.func.isRequired,
  subscriptionLoading: PropTypes.bool.isRequired,
  billingError: PropTypes.bool,
  sendInviteToUser: PropTypes.func,
  isRhinoformEnabled: PropTypes.bool,
  isEditIntegratedContactEnabled: PropTypes.bool,
  integrationPartnerTypeId: PropTypes.number,
  activeOrg: PropTypes.object,
  handleUpdateMemberActiveStatus: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { user, tag, group, channel, timeZone, billing, form } = state;

  return {
    currentUser: getLoggedInUser(state),
    channels: channel.channels,
    groups: group.groups,
    tags: tag.tags,
    timeZones: timeZone.timeZones,
    usersLoading: user.loading,
    productCount: user.totalActiveMemberCount,
    billing: billing.billing,
    subscription: billing.subscription,
    subscriptionLoading: billing.subscriptionLoading,
    billingError: billing.billingError,
    isRhinoformEnabled: !!form.org?.isRhinoformEnabled,
    activeOrg: getCurrentOrg(state),
  };
};

const actions = {
  fetchUser: UserReducer.fetchUser,
  updateUser: UserReducer.updateUser,
  sendInviteToUser: UserReducer.sendInviteToUser,
  fetchBillingAndSubscriptionPlan,
};

export default connect(mapStateToProps, actions)(MemberProfilePanelContainer);
