import axios from 'axios';
import { normalize } from 'normalizr';
import { createSlice } from '@reduxjs/toolkit';
import * as UIReducer from './uiReducer';
import { event, savedContentItem } from '../actions/NormalizrSchema';
import * as DataHelpers from '../helpers/DataHelpers';
import NotificationService from '../services/NotificationService';
import { TYPE_EVENT_STATUS_DELIVERED } from '../constants/Types';
import { pushReceiveThread, getInboxThreadPayload } from './inboxReducer';
import threadService from '../services/threadService';

// SLICE //
const savedContentSlice = createSlice({
  name: 'SAVED_CONTENT',
  initialState: {
    loading: false,
    savedContentItems: {},
    savedContentSavedEvents: {},
    savedContentItemIds: [],
    isSelectionPreviewModeEnabled: false,
    isSelectionModeEnabled: false,
    selectedEventIds: [],
    contentSelectionTitle: '',
    contentSelectionNote: '',
    savedContentErrors: {},
    activeSavedContentItemId: null,
    autoSendToEHR: false,
  },
  reducers: {
    receiveSavedContentItems: receiveSavedContentItemsMutation,
    receiveCreatedSavedContentItem: receiveSavedContentItemsMutation,
    savedContentRequestData: (state) => ({
      ...state,
      loading: true,
    }),
    setIsSelectionPreviewModeEnabled: (state) => ({
      ...state,
      isSelectionPreviewModeEnabled: !state.isSelectionPreviewModeEnabled,
    }),
    setSelectedEventIds: (state, action) => ({
      ...state,
      selectedEventIds: DataHelpers.updateArrayById(state.selectedEventIds, action.payload),
    }),
    setContentSelectionTitle: (state, action) => ({
      ...state,
      contentSelectionTitle: action.payload,
    }),
    setAutoSendToEHR: (state, action) => ({
      ...state,
      autoSendToEHR: action.payload,
    }),
    setActiveSavedContentItemId: (state, action) => ({
      ...state,
      activeSavedContentItemId: action.payload,
    }),
    setIsSelectionModeEnabled: (state, action) => ({
      ...state,
      isSelectionModeEnabled: action.payload,
      selectedEventIds: [],
      contentSelectionTitle: '',
      contentSelectionNote: '',
      isSelectionPreviewModeEnabled: false,
      autoSendToEHR: false,
    }),
    receiveEventsForSavedContent: (state, action) => ({
      ...state,
      savedContentItems: DataHelpers.mergeShallow(state.savedContentItems, action.payload.savedContentItems),
      savedContentItemIds: [...new Set([...state.savedContentItemIds], [...action.payload.savedContentItemIds])],
      savedContentSavedEvents: {
        ...state.savedContentSavedEvents,
        [action.payload.savedContentItemIds[0]]: [...new Set([
          ...(state.savedContentSavedEvents[action.payload.savedContentItemIds[0]] || []),
          ...action.payload.savedContentSavedEvents[action.payload.savedContentItemIds[0]],
        ])],
      },
    }),
    removeSavedContentItem: (state, action) => {
      const savedContentItems = DataHelpers.cloneDeep(state.savedContentItems);
      const savedContentSavedEvents = DataHelpers.cloneDeep(state.savedContentSavedEvents);

      delete savedContentItems[action.payload.id];
      delete savedContentSavedEvents[action.payload.id];

      return {
        ...state,
        savedContentItems: { ...savedContentItems },
        savedContentItemIds: [...state.savedContentItemIds.filter((id) => id !== action.payload.id)],
        savedContentSavedEvents: { ...savedContentSavedEvents },
        // loading: false,
      };
    },
    updateSavedContentSent: (state, action) => {
      const savedContentItems = DataHelpers.cloneDeep(state.savedContentItems);
      const { payload: { event: { details: { savedContentItemId } } } } = action;
      const foundEventIndex = savedContentItems[savedContentItemId].savedContentEvents.findIndex((a) => a.id === action.payload.event.id);
      if (foundEventIndex !== -1) savedContentItems[savedContentItemId].savedContentEvents[foundEventIndex].statusTypeId = action.payload.event.statusTypeId;
      return {
        ...state,
        savedContentItems: { ...savedContentItems },
      };
    },
  },
});

// REDUCER
export default savedContentSlice.reducer;

// ACTIONS
export const {
  updateSavedContentSent,
  receiveSavedContentItems,
  savedContentRequestData,
  receiveEventsForSavedContent,
  receiveCreatedSavedContentItem,
  removeSavedContentItem,
  setIsSelectionPreviewModeEnabled,
  removeSelectionModeEnabled,
  setSelectedEventIds,
  setIsSelectionModeEnabled,
  setActiveSavedContentItemId,
} = savedContentSlice.actions;

// THUNKS -- ASYNC ACTIONS //
export function fetchSavedContentItemsByContact(contactId, type) {
  return async (dispatch) => {
    dispatch(savedContentRequestData());

    try {
      const { data: savedContentItems } = await getSavedContentItemsByContact(contactId, type);
      const normalizedSavedContentItems = normalize(savedContentItems, [savedContentItem]);
      dispatch(receiveSavedContentItems(shapeSavedContentItemsPayload(normalizedSavedContentItems)));
    } catch (err) {
      console.error(err.response || err);
      dispatch(UIReducer.setError(err.response || err));
    }
  };
}

export function fetchEventsForSavedContentItem(savedContentItemId, pageNumber = 0, pageSize = 20) {
  return async (dispatch) => {
    dispatch(savedContentRequestData());

    try {
      const { data } = await getEventsForSavedContent(savedContentItemId, pageNumber, pageSize);
      const normalizedSavedContentItem = normalize(data, savedContentItem);
      dispatch(setActiveSavedContentItemId(savedContentItemId));
      dispatch(receiveEventsForSavedContent(shapeSavedContentItemWithEvents(normalizedSavedContentItem)));

      // Increment page number ONLY if there are still potentially more results to come.
      return data.events.length === 0 || data.events.length < pageSize ? pageNumber : pageNumber + 1;
    } catch (err) {
      console.error(err.response || err);
      dispatch(UIReducer.setError(err.response || err));
      throw err;
    }
  };
}

export function createSavedContent(savedContentPayload) {
  return async (dispatch) => {
    let createdSavedContentId;

    try {
      const response = await postSavedContent(savedContentPayload);
      const normalizedEvent = normalize(response.data.event, event);
      const normalizedSavedContentItem = normalize(response.data.savedContentItem, savedContentItem);

      dispatch(receiveCreatedSavedContentItem(shapeCreatedSavedContentPayload(normalizedEvent, normalizedSavedContentItem)));
      NotificationService('createSavedContent', response);

      createdSavedContentId = normalizedSavedContentItem.result;
      dispatch(setActiveSavedContentItemId(createdSavedContentId));
      dispatch(setIsSelectionModeEnabled(false));
    } catch (err) {
      console.error(err.response || err);
      dispatch(UIReducer.setError(err.response || err));
      NotificationService('createSavedContent', err.response);
    }

    return createdSavedContentId; // Sending id of saved event back to function caller.
  };
}

export function destroySavedContentItem(savedContentItemId, isCopiedContent = false) {
  return async (dispatch) => {
    dispatch(savedContentRequestData());

    try {
      const response = await deleteSavedContent(savedContentItemId);
      NotificationService('deleteSavedContent', response);
      dispatch(threadService.util.invalidateTags(['Thread']));
      dispatch(removeSavedContentItem({ id: savedContentItemId, isCopiedContent }));
    } catch (err) {
      console.error(err.response || err);
      dispatch(UIReducer.setError(err.response || err));
      NotificationService('deleteSavedContent', err.response);
    }
  };
}

export function sendSavedContentItem(savedContentItemId) {
  return async (dispatch) => {
    dispatch(savedContentRequestData());

    try {
      const response = await sendSavedContent(savedContentItemId);
      const normalizedEvent = normalize(response.data.event, event);
      const normalizedSavedContentItem = normalize(response.data.savedContentItem, savedContentItem);

      dispatch(receiveCreatedSavedContentItem(shapeCreatedSavedContentPayload(normalizedEvent, normalizedSavedContentItem)));
      NotificationService('sendSavedContent', response);
    } catch (err) {
      console.error(err.response || err);
      dispatch(UIReducer.setError(err.response || err));
      NotificationService('sendSavedContent', err.response);
    }
  };
}

export function createCopySavedContent(payload) {
  return (dispatch) => {
    const url = '/users/copySavedContent';
    return axios.post(url, payload)
      .then((response) => {
        const normalized = normalize([response.data.event.event], [event]);
        if (normalized.result) {
          dispatch(pushReceiveThread(getInboxThreadPayload(normalized)));
        }
        NotificationService('createCopySavedContent', response);
      })
      .catch((err) => {
        NotificationService('createCopySavedContent', err.response);
        dispatch(UIReducer.setError(err.response || err));
      });
  };
}

export function receiveUpdateSavedContentSentEvent(message) {
  return (dispatch) => {
    dispatch(updateSavedContentSent(message));
    if (message.event.statusTypeId === TYPE_EVENT_STATUS_DELIVERED) {
      NotificationService('sendSavedContent', { status: 200 }, 'Saved Content Successfully Sent!');
    } else {
      NotificationService('sendSavedContent');
    }
  };
}

export function updateContentSelectionMode(isSelectionModeEnabled) {
  return (dispatch) => {
    const shouldLeaveSelectionMode = isSelectionModeEnabled ? dispatch(UIReducer.confirmFormChanges()) : true;
    if (shouldLeaveSelectionMode) {
      dispatch(setIsSelectionModeEnabled(false));
    }
  };
}

// AXIOS HELPERS //
export function getSavedContentItemsByContact(contactId, type) {
  return axios.get(`/savedContent?userId=${contactId}${type ? `&type=${type}` : ''}`);
}

export function getEventsForSavedContent(savedContentItemId, pageNumber = 0, pageSize = 20) {
  return axios.get(`/savedContent/events/${savedContentItemId}?page=${pageNumber}&pageSize=${pageSize}`);
}

export function postSavedContent(savedContentPayload) {
  return axios.post('/savedContent', savedContentPayload);
}

export function deleteSavedContent(savedContentItemId) {
  return axios.delete(`/savedContent/${savedContentItemId}`);
}

export function sendSavedContent(savedContentItemId) {
  return axios.get(`/savedContent/${savedContentItemId}/send`);
}

// REDUCER MUTATION LOGIC //
function receiveSavedContentItemsMutation(state, action) {
  return {
    ...state,
    savedContentItems: {
      ...state.savedContentItems,
      ...action.payload.savedContentItems,
    },
    savedContentItemIds: [...new Set([...state.savedContentItemIds, ...action.payload.savedContentItemIds])],
    loading: false,
  };
}

// SHAPERS FOR NORMALIZED DATA //
export function shapeSavedContentItemsPayload(normalizedSavedContentItems) {
  const {
    result,
    entities: {
      savedContentItems,
      creators,
      contacts,
    },
  } = normalizedSavedContentItems;

  return {
    savedContentItems: {
      ...savedContentItems,
    },
    savedContentItemIds: result,
    users: {
      ...creators,
      ...contacts,
    },
    userIds: [...new Set([...DataHelpers.getObjectKeys(creators), ...DataHelpers.getObjectKeys(contacts)])],
  };
}

export function shapeSavedContentItemWithEvents(normalizedSavedContentItem) {
  const {
    result,
    entities: {
      channels,
      connectedParties,
      contacts,
      creators,
      emails,
      events,
      facebooks,
      instagrams,
      organizations,
      phones,
      savedContentItems,
      senders,
      users,
    },
  } = normalizedSavedContentItem;

  return {
    channels: { ...channels },
    channelIds: DataHelpers.getObjectKeys(channels),
    events: { ...events },
    eventIds: DataHelpers.getObjectKeys(events),
    phones: { ...phones },
    phoneIds: DataHelpers.getObjectKeys(phones),
    emails: { ...emails },
    emailIds: DataHelpers.getObjectKeys(emails),
    connectedParties: { ...connectedParties },
    connectedPartyIds: DataHelpers.getObjectKeys(connectedParties),
    facebooks: { ...facebooks },
    facebookIds: DataHelpers.getObjectKeys(facebooks),
    instagrams: { ...instagrams },
    instagramIds: DataHelpers.getObjectKeys(instagrams),
    organizations: { ...organizations },
    organizationIds: DataHelpers.getObjectKeys(organizations),
    users: { ...senders, ...users, ...creators, ...contacts },
    userIds: [...new Set([
      ...DataHelpers.getObjectKeys(users),
      ...DataHelpers.getObjectKeys(senders),
      ...DataHelpers.getObjectKeys(creators),
      ...DataHelpers.getObjectKeys(contacts),
    ])],
    savedContentItems: {
      ...savedContentItems,
    },
    savedContentItemIds: DataHelpers.getObjectKeys(savedContentItems),
    savedContentSavedEvents: { [result]: savedContentItems[result].events },
  };
}

export function shapeCreatedSavedContentPayload(normalizedEvent, normalizedSavedContentItem) {
  const {
    result: eventResult,
    entities: {
      channels,
      connectedParties,
      emails,
      events,
      facebooks,
      instagrams,
      organizations,
      phones,
      senders,
      users,
    },
  } = normalizedEvent;

  const {
    result: savedContentItemResult,
    entities: {
      savedContentItems,
      creators,
      contacts,
    },
  } = normalizedSavedContentItem;

  return {
    channels: { ...channels },
    channelIds: DataHelpers.getObjectKeys(channels),
    events: { ...events },
    eventIds: [eventResult],
    phones: { ...phones },
    phoneIds: DataHelpers.getObjectKeys(phones),
    emails: { ...emails },
    emailIds: DataHelpers.getObjectKeys(emails),
    connectedParties: { ...connectedParties },
    connectedPartyIds: DataHelpers.getObjectKeys(connectedParties),
    facebooks: { ...facebooks },
    facebookIds: DataHelpers.getObjectKeys(facebooks),
    instagrams: { ...instagrams },
    instagramIds: DataHelpers.getObjectKeys(instagrams),
    organizations: { ...organizations },
    organizationIds: DataHelpers.getObjectKeys(organizations),
    users: { ...senders, ...users, ...contacts, ...creators },
    userIds: [...new Set([
      ...DataHelpers.getObjectKeys(users),
      ...DataHelpers.getObjectKeys(senders),
      ...DataHelpers.getObjectKeys(contacts),
      ...DataHelpers.getObjectKeys(creators),
    ])],
    savedContentItems: { ...savedContentItems },
    savedContentItemIds: [savedContentItemResult],
  };
}
