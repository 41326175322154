import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  Cover,
  CoverBody,
  CoverHeader,
  NavTabs,
  NavTabsItem,
  TabsContent,
  TabContentPane,
  RadioGroup,
  Radio,
  Input,
  CoverFooter,
  UtilityInlineGrid,
  LoaderCircle,
  Icon,
  CheckboxGroup,
  Checkbox,
} from 'rhinostyle';
import onlineForm from '../assets/images/online-form.svg';
import formOverlay from '../assets/images/forms-overlay.svg';
import { isWriteBackEnabled } from '../helpers/OrganizationHelpers';

const RhinoformView = (props) => {
  const {
    closeCover,
    activeKey,
    updateActiveKey,
    handleFormChanges,
    handleTypeChange,
    handleSubmissionTypeChange,
    handleAddFile,
    formType,
    submissionType,
    handleformConfiguration,
    rhinoformUrl,
    handleChange,
    formName,
    errors,
    pageContainerRef,
    scratchRhinoform,
    handleUpdateForm,
    handleResetForm,
    handleCreateForm,
    iframeLoaded,
    iFrameLoading,
    pdfFileName,
    handlePdfForm,
    isFormLoading,
    updatingForm,
    formUploadPostProcessing,
    processingText,
    formTitle,
    isFormStateUpdated,
    isEditMode,
    openModal,
    isSaveFormLoading,
    isSaveDraftFormLoading,
    fileInputKey,
    fileProgressText,
    activeUpload,
    formSubmissionType,
    autoSendToEHR,
    formAutoSendToEHR,
    currentOrganization,
  } = props;

  const groupTypeOptions = [
    {
      label: 'Start from scratch',
      description: 'Create a new form with a blank canvas',
      value: 1,
      dataCypress: 'startFromScratch',
    },
    {
      label: 'Import PDF form',
      description: 'Create an online form from an existing PDF',
      value: 2,
    },
  ];
  const submissionDisplayOptions = [
    {
      label: 'Overlay on Original PDF',
      description: 'Display form answers on Original PDF',
      value: 1,
      dataCypress: 'originalPdf',
      image: formOverlay,
      showWidgetWarning: true,
      widgetWarningMessage: 'Do not use widgets for overlay display option',
    },
    {
      label: 'On Online Form',
      description: 'Display form answers on Online Form',
      value: 2,
      image: onlineForm,
      showWidgetWarning: false,
      widgetWarningMessage: 'Do not use widgets for overlay display option',
    },
  ];
  const iframeLoader = updatingForm ? isFormLoading : iFrameLoading;
  const isButtonEnabled = !!(rhinoformUrl && !isFormLoading && formName && activeKey === 2);
  const isUpdateEnable = formTitle === formName && submissionType === formSubmissionType && formAutoSendToEHR === autoSendToEHR;

  return (
    <>
      <Cover open={openModal} className="rhinoform">
        <div className="rhinoform__cover">
          <CoverHeader onClose={closeCover} icon="arrow-left" title={isEditMode ? 'Edit Form' : 'Create Form'} />
          <div className={`rhinoform__tabs ${(rhinoformUrl && !isFormLoading) ? 'enable-link' : ''}`}>
            <NavTabs activeKey={activeKey} onSelect={updateActiveKey}>
              <NavTabsItem id={1} dataFeatureTag="settings">Settings</NavTabsItem>
              <NavTabsItem id={2} dataFeatureTag="build">Build</NavTabsItem>
            </NavTabs>
          </div>
          <CoverBody className="pdf-attachment__cover__body-container">
            <TabsContent activeKey={activeKey}>
              <TabContentPane id={1}>
                <div className="app-page__container" ref={pageContainerRef}>
                  <div className="app-page__container__inner">
                    <form onChange={handleFormChanges} encType="multipart/form-data" className="u-position-relative">
                      <div className="box">
                        <div className="box__title-wrapper">
                          <div className="box__title">SELECT FORM OPTION<span className="form__asterisk">*</span></div>
                        </div>
                        <RadioGroup blockGroup name="groupType" onChange={handleTypeChange} selectedValue={formType}>
                          {groupTypeOptions.map((item) => (
                            <Radio
                              key={item.value}
                              value={item.value}
                              dataCypress={item.dataCypress}
                              disabled={(isFormStateUpdated && scratchRhinoform && item.value === 1)
                                || (isFormStateUpdated && !scratchRhinoform && item.value === 2)}
                              className={((isFormStateUpdated && !scratchRhinoform && item.value === 1) ||
                                (isFormStateUpdated && scratchRhinoform && item.value === 2)) ? 'hide-form' : ''}
                              label={(
                                <>
                                  <span className="form__block-group__label">{item.label}</span>
                                  <span className="form__block-group__desc">{item.description}</span>
                                </>
                              )}
                            />
                          ))}
                        </RadioGroup>
                        <div className="box__title-wrapper">
                          <div className="box__title">Form Details</div>
                        </div>
                        <div className="row">
                          <div className="column-9@small">
                            <Input
                              explanationMessage="Form name must be unique"
                              label="Name"
                              name="formName"
                              initialValue={formName}
                              onChange={handleChange}
                              onClick={handlePdfForm}
                              required
                              validationMessage={errors.formName}
                              autoComplete="off"
                              dataFeatureTag="formName"
                            />
                          </div>
                        </div>
                        {formType === 2 && (
                          <div className="row">
                            <div className="column-12@small">
                              <div className="form__block-group__label u-m-b-small u-m-t">
                                Form Submission Display
                              </div>
                              <RadioGroup
                                blockGroup
                                name="submissionDisplayType"
                                onChange={handleSubmissionTypeChange}
                                selectedValue={submissionType === null ? 2 : submissionType}
                              >
                                {submissionDisplayOptions.map((item) => (
                                  <Radio
                                    key={item.value}
                                    value={item.value}
                                    dataCypress={item.dataCypress}
                                    className="rhinoform__pdf"
                                    label={(
                                      <div className="rhinoform__pdf__container">
                                        <div className="rhinoform__pdf__image">
                                          <img src={item.image} alt={item.dataCypress} />
                                        </div>
                                        <div className="rhinoform__pdf__label">
                                          <span className="form__block-group__label">{item.label}</span>
                                          <span className="form__block-group__desc">{item.description}</span>
                                          {(submissionType === 1 && item.showWidgetWarning) && (
                                          <div>
                                            <span><Icon icon="alert-triangle" bump="up" className="inbox__item__body__failed-icon" /></span>
                                            <span> <strong className="form__block-group__warning_desc">Attention </strong></span>
                                            <span className="form__block-group__warning_desc">{item.widgetWarningMessage}</span>
                                          </div>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  />
                                ))}
                              </RadioGroup>
                            </div>
                          </div>
                        )}
                        <br />
                        {isWriteBackEnabled(currentOrganization) && (
                        <div className="row">
                          <div className="column-9@small">
                            <CheckboxGroup label="Send to EHR/PMS">
                              <Checkbox
                                className="u-text-small"
                                name="autoSendToEHR"
                                onChange={handleChange}
                                isChecked={autoSendToEHR}
                                label="Automatically Send Completed Forms to Contact's EHR/PMS"
                              />
                            </CheckboxGroup>
                          </div>
                        </div>
                        )}
                        {formType && (
                          <div className="rhinoform__button">
                            {((isFormStateUpdated && scratchRhinoform) || (pdfFileName && isFormStateUpdated)) ? (
                              <div className="column-6@small">
                                {pdfFileName && (
                                  <div className="uploaded-pdf">
                                    <Icon icon="signature" />
                                    <span>{pdfFileName}</span>
                                  </div>
                                )}
                                <UtilityInlineGrid>
                                  <Button
                                    reset
                                    className="button button--primary button--medium u-m-t-large"
                                    loading={isFormLoading}
                                    disabled={((isUpdateEnable) && activeKey === 1)}
                                    onClick={() => handleUpdateForm(formType)}
                                    data-cypress={formType === 1 ? 'updateForm' : null}
                                  >{formType === 1 ? 'Update Form' : 'Update PDF Form'}
                                  </Button>
                                  {!isEditMode ? (
                                    <Button
                                      className="u-m-t-large u-m-l-large"
                                      type="outline"
                                      onClick={() => handleResetForm(formType)}
                                    >
                                      Reset Form
                                    </Button>
                                  ) : null}
                                </UtilityInlineGrid>
                              </div>
                            ) : (
                              <div className="rhinoform__create-button">
                                <div className=" u-m-t-large u-flex u-flex-align-items-center">
                                  <Button
                                    reset
                                    className="button button--primary button--medium u-m-r"
                                    disabled={!formName}
                                    loading={isFormLoading || activeUpload}
                                    onClick={() => handleformConfiguration(formType)}
                                    data-cypress={formType === 1 ? 'beginCreatingForm' : null}
                                  >{formType === 1 ? 'Begin Creating Form' : 'Upload PDF Form'}
                                  </Button>
                                  <div className="">
                                    <span className="u-text-small u-text-primary">{(activeUpload ? fileProgressText : '')}</span>
                                    {formUploadPostProcessing.includes(fileInputKey) &&
                                      <span className="u-text-small u-text-primary">{processingText}</span>}
                                  </div>
                                </div>
                                {formType === 2 && formName && (
                                  <>
                                    <input
                                      name="pdfName"
                                      type="file"
                                      accept="application/pdf, application/vnd.ms-excel"
                                      key={fileInputKey}
                                      onChange={handleAddFile}
                                    />
                                    <div className="pdf-form">
                                      <div className="form__explanation-message column-8@small">Max of 50 Pages in a single PDF allowed</div>
                                    </div>
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </TabContentPane>
              <TabContentPane id={2}>
                <div className="rhinoform__iframe-cover">
                  {iframeLoader && (
                    <div className="app-page-loader-outer">
                      <div className="app-page-loader">
                        <LoaderCircle size="large" className="u-text-primary" />
                      </div>
                    </div>
                  )}
                  {rhinoformUrl && <iframe src={rhinoformUrl} onLoad={() => iframeLoaded()} title="Rhinoform" />}
                </div>
              </TabContentPane>
            </TabsContent>
          </CoverBody>
          <CoverFooter>
            <UtilityInlineGrid align="right">
              <Button
                className="rhinoform__save-as"
                type={!isButtonEnabled ? 'outline' : 'secondary'}
                disabled={!isButtonEnabled}
                onClick={() => handleCreateForm(false, formName)}
                outlined={isButtonEnabled}
                loading={isSaveDraftFormLoading}
                data-cypress="saveAsDraft"
              >
                Save As Draft
              </Button>
              <Button
                type={!isButtonEnabled ? 'default' : 'secondary'}
                disabled={!isButtonEnabled}
                onClick={() => handleCreateForm(true, formName)}
                loading={isSaveFormLoading}
                data-cypress="save"
              >
                Save
              </Button>
            </UtilityInlineGrid>
          </CoverFooter>
        </div>
      </Cover>
    </>
  );
};

RhinoformView.propTypes = {
  closeCover: PropTypes.func,
  activeKey: PropTypes.number,
  updateActiveKey: PropTypes.func,
  handleFormChanges: PropTypes.func,
  handleTypeChange: PropTypes.func,
  handleSubmissionTypeChange: PropTypes.func,
  formType: PropTypes.number,
  submissionType: PropTypes.number,
  handleformConfiguration: PropTypes.func,
  rhinoformUrl: PropTypes.string,
  handleChange: PropTypes.func,
  formName: PropTypes.string,
  errors: PropTypes.object.isRequired,
  pageContainerRef: PropTypes.func.isRequired,
  scratchRhinoform: PropTypes.bool,
  handleUpdateForm: PropTypes.func,
  handleResetForm: PropTypes.func,
  handleCreateForm: PropTypes.func,
  iframeLoaded: PropTypes.func,
  iFrameLoading: PropTypes.bool,
  pdfFileName: PropTypes.string,
  handlePdfForm: PropTypes.func,
  isFormLoading: PropTypes.bool,
  updatingForm: PropTypes.bool,
  formUploadPostProcessing: PropTypes.array,
  processingText: PropTypes.string,
  formTitle: PropTypes.string,
  formSubmissionType: PropTypes.number,
  isFormStateUpdated: PropTypes.bool,
  isEditMode: PropTypes.bool,
  openModal: PropTypes.bool,
  isSaveFormLoading: PropTypes.bool,
  isSaveDraftFormLoading: PropTypes.bool,
  fileInputKey: PropTypes.string,
  handleAddFile: PropTypes.func,
  fileProgressText: PropTypes.string,
  activeUpload: PropTypes.bool,
  autoSendToEHR: PropTypes.bool,
  formAutoSendToEHR: PropTypes.bool,
  currentOrganization: PropTypes.object,
};

export default RhinoformView;
