import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { UtilitySystem } from 'rhinostyle';
import { DataHelpers, UserHelpers } from '../helpers';
import { getLoggedInUser } from '../selectors/userSelectors';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import MultiOrgContainer from '../containers/MultiOrgContainer';

import {
  APPOINTMENT_TYPE_VIEW,
  AUTOMATED_MESSAGE_VIEW,
  BILLING_VIEW,
  CHANNEL_VIEW,
  FORM_VIEW,
  GROUP_VIEW,
  MEMBER_VIEW,
  OFFICE_VIEW,
  ORGANIZATION_PREFERENCES_VIEW,
  ORGANIZATION_PROFILE_VIEW,
  OUT_OF_OFFICE_VIEW,
  ROLE_VIEW,
  TAG_VIEW,
  TEMPLATE_MESSAGE_VIEW,
  PRESCRIPTION_NOTIFICATION_VIEW,
} from '../constants/UserPermissionsConstants';

const SettingsMenu = (props) => {
  const {
    currentUser,
    currentOrganization,
    billing,
    handleNavigationClick,
    organizations,
    settingsRef,
    history,
  } = props;

  const isAutomatedMessagesLinkVisible = !!props.currentOrganization?.supportsAppointmentReminders;
  const isAppointmentDataEnabled = !!props.currentOrganization?.integration && !props.currentOrganization.arePrescriptionCampaignsEnabled;
  const arePrescriptionCampaignsEnabled = !!currentOrganization?.integrationPartnerTypeId && !!currentOrganization?.arePrescriptionCampaignsEnabled;
  const { userHasAnyOfPermissions } = UserHelpers;
  const billingSetUpForMember = billing;
  const billingSetUpForCcr = currentOrganization && currentOrganization.billing;

  const SUB_NAV_CATEGORIES = {
    appointments: 'APPT/CAMPAIGNS',
    prescriptions: 'PRESCRIPTIONS',
    userConfigurations: 'USER CONFIGURATIONS',
    organization: 'ORGANIZATION',
  };

  const menuOptions = [
    // APPOINTMENTS //
    {
      title: 'Appointment Data',
      id: 'nav-settings-appointment-data-setup',
      to: '/settings/organization/appointment-data-setup',
      dataCypress: 'appointmentDataOption',
      category: SUB_NAV_CATEGORIES.appointments,
      isVisible: userHasAnyOfPermissions([APPOINTMENT_TYPE_VIEW]) && isAppointmentDataEnabled,
    },
    {
      title: 'Appointment Reminders',
      id: 'nav-settings-appointment-reminders',
      to: '/settings/organization/appointment-reminders',
      dataCypress: 'appointmentReminderOption',
      category: SUB_NAV_CATEGORIES.appointments,
      isVisible: userHasAnyOfPermissions([AUTOMATED_MESSAGE_VIEW]) && isAutomatedMessagesLinkVisible && !arePrescriptionCampaignsEnabled,
    },
    {
      title: 'Campaigns',
      id: 'nav-settings-hipaa-campaigns',
      to: '/settings/organization/hipaa-campaigns',
      dataCypress: 'hipaaCampaignOption',
      category: SUB_NAV_CATEGORIES.appointments,
      isVisible: userHasAnyOfPermissions([AUTOMATED_MESSAGE_VIEW]) && isAutomatedMessagesLinkVisible && currentOrganization.areHipaaRequestCampaignsEnabled,
    },
    // PRESCRIPTIONS //
    {
      title: 'Prescription Notifications',
      id: 'nav-settings-prescription-notifications',
      to: '/settings/organization/prescription-notifications',
      dataCypress: 'prescriptionCampaignOption',
      category: SUB_NAV_CATEGORIES.prescriptions,
      isVisible: userHasAnyOfPermissions([PRESCRIPTION_NOTIFICATION_VIEW]) && arePrescriptionCampaignsEnabled,
    },
    {
      title: 'Integration Analytics',
      id: 'nav-settings-integration-analytics',
      to: '/settings/organization/integration-analytics',
      dataCypress: 'integrationAnalyticsOption',
      category: SUB_NAV_CATEGORIES.appointments,
      isVisible: UserHelpers.isCCR(currentUser),
    },
    // USER CONFIGURATIONS //
    {
      title: 'Channels',
      id: 'nav-settings-channels',
      to: '/settings/organization/channels',
      dataCypress: 'channelsOption',
      category: SUB_NAV_CATEGORIES.userConfigurations,
      isVisible: userHasAnyOfPermissions([CHANNEL_VIEW]),
    },
    {
      title: 'Groups',
      id: 'nav-settings-groups',
      to: '/settings/organization/groups',
      dataCypress: 'groupsOption',
      category: SUB_NAV_CATEGORIES.userConfigurations,
      isVisible: userHasAnyOfPermissions([GROUP_VIEW]),
    },
    {
      title: 'Members',
      id: 'nav-settings-members',
      to: '/settings/organization/members',
      dataCypress: 'membersOption',
      category: SUB_NAV_CATEGORIES.userConfigurations,
      isVisible: userHasAnyOfPermissions([MEMBER_VIEW]),
    },
    {
      title: 'Roles',
      id: 'nav-settings-roles',
      to: '/settings/organization/roles',
      dataCypress: 'rolesOption',
      category: SUB_NAV_CATEGORIES.userConfigurations,
      isVisible: userHasAnyOfPermissions([ROLE_VIEW]),
    },
    {
      title: 'Tags',
      id: 'nav-settings-tags',
      to: '/settings/organization/tags',
      dataCypress: 'tagsOption',
      category: SUB_NAV_CATEGORIES.userConfigurations,
      isVisible: userHasAnyOfPermissions([TAG_VIEW]),
    },
    // ORGANIZATION //
    {
      title: 'Billing',
      id: 'nav-settings-billing',
      to: '/settings/organization/billing',
      dataCypress: 'billingOption',
      category: SUB_NAV_CATEGORIES.organization,
      isVisible: userHasAnyOfPermissions([BILLING_VIEW]) && billingSetUpForMember !== null && billingSetUpForCcr !== null && DataHelpers.hasData(billing),
    },
    {
      title: 'Library',
      id: 'nav-settings-library',
      to: `/settings/organization/library/${userHasAnyOfPermissions([TEMPLATE_MESSAGE_VIEW]) ? 'templates' : 'forms'}`,
      dataCypress: 'libraryOption',
      category: SUB_NAV_CATEGORIES.organization,
      isVisible: userHasAnyOfPermissions([FORM_VIEW, TEMPLATE_MESSAGE_VIEW]),
    },
    {
      title: 'Preferences',
      id: 'nav-settings-preferences',
      to: '/settings/organization/preferences',
      dataCypress: 'organizationPreferencesOption',
      category: SUB_NAV_CATEGORIES.organization,
      isVisible: userHasAnyOfPermissions([ORGANIZATION_PREFERENCES_VIEW]),
    },
    {
      title: 'Profile',
      id: 'nav-settings-profile',
      to: '/settings/organization/profile',
      dataCypress: 'organizationProfileOption',
      category: SUB_NAV_CATEGORIES.organization,
      isVisible: userHasAnyOfPermissions([ORGANIZATION_PROFILE_VIEW]),
    },
    {
      title: 'Offices',
      id: 'nav-settings-offices',
      to: '/settings/organization/offices',
      dataCypress: 'officesOption',
      category: SUB_NAV_CATEGORIES.organization,
      isVisible: userHasAnyOfPermissions([OFFICE_VIEW]),
    },
    {
      title: 'Out of Office',
      id: 'nav-settings-out-of-office',
      to: '/settings/organization/out-of-office',
      dataCypress: 'outOfOfficeOption',
      category: SUB_NAV_CATEGORIES.organization,
      isVisible: userHasAnyOfPermissions([OUT_OF_OFFICE_VIEW]),
    },
  ];

  const renderMenuListItemsByCategory = (subNavCategory) => (
    <>
      <li className="app-navigation__nav__header">
        <span className="app-navigation__sub-nav__text">{subNavCategory}</span>
      </li>
      {menuOptions
        .filter((option) => option.category === subNavCategory && option.isVisible)
        .map((option) => (
          <li key={option.id} className="app-navigation__nav__sub">
            <NavLink
              id={option.id}
              to={option.to}
              data-cypress={option.dataCypress}
              onClick={handleNavigationClick}
              className="app-navigation__nav__button"
              activeClassName={UtilitySystem.config.classes.active}
            >
              <span>{option.title}</span>
            </NavLink>
          </li>
        ))}
    </>
  );

  return (
    <nav id="app-navigation" className="app-navigation">
      <div className="app-navigation__container">
        <div className="app-navigation__top">
          <ul className="app-navigation__nav" ref={settingsRef}>
            <li className="app-navigation__nav__header">
              <span className="app-navigation__nav__button__text">SETTINGS</span>
            </li>
            <div className="app-navigation__list-item__divider" />
            {renderMenuListItemsByCategory(SUB_NAV_CATEGORIES.appointments)}
            {arePrescriptionCampaignsEnabled && renderMenuListItemsByCategory(SUB_NAV_CATEGORIES.prescriptions)}
            {renderMenuListItemsByCategory(SUB_NAV_CATEGORIES.userConfigurations)}
            {renderMenuListItemsByCategory(SUB_NAV_CATEGORIES.organization)}
          </ul>
        </div>
        <div className="app-navigation__bottom">
          {currentUser?.organization && (
            <div className="app-navigation__org">
              {organizations[currentUser.organization].name}
              <span>Organization{UserHelpers.isCCR(currentUser) && ` (#${organizations[currentUser.organization].id})`}</span>
            </div>
          )}
          <MultiOrgContainer history={history} />
        </div>
      </div>
    </nav>
  );
};

SettingsMenu.propTypes = {
  currentUser: PropTypes.object,
  currentOrganization: PropTypes.object,
  handleNavigationClick: PropTypes.func,
  billing: PropTypes.object,
  organizations: PropTypes.object,
  settingsRef: PropTypes.object,
  history: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { billing, pay, form } = state;
  return {
    currentUser: getLoggedInUser(state),
    currentOrganization: getCurrentOrg(state),
    billing: billing.billing,
    isRhinopayEnabled: pay.merchant.isRhinopayEnabled,
    isRhinoformEnabled: !!form.org?.isRhinoformEnabled,
  };
};

export default connect(mapStateToProps, {})(SettingsMenu);
