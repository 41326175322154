import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchFormTemplates, updateFormTemplates, updateFormsList, getFormTemplateById } from '../reducers/formReducer';
import { fetchMessageTemplates, updateFavoriteStatus, updateTemplatesList } from '../reducers/messageTemplateReducer';
import { compareObjectByKey, cloneDeep, getDefaultSortKey } from '../helpers/DataHelpers';
import OrganizationLibrary from '../components/OrganizationLibrary';
import EmptyMessage from '../components/EmptyMessage';
import {
  FORMS_LIST_HEADERS_CONFIG,
  TEMPLATES_LIST_HEADERS_CONFIG,
  NAVIGATION_TABS,
} from '../constants/LibraryConstants';
import { getLoggedInUser, getLoggedInUserPermissionNames } from '../selectors/userSelectors';

const filterOptions = [
  {
    id: 'all',
    label: 'All',
  },
  {
    id: 'favorite',
    label: 'Favorite',
  },
];

class OrganizationLibraryContainer extends Component {
  state = {
    selectedKey: 1,
    searchText: '',
    focusSearchInput: false,
    selectedFilter: 'all',
    sortKey: getDefaultSortKey(TEMPLATES_LIST_HEADERS_CONFIG),
    panelOpen: !!this.props.match.params.id,
    formHeadersConfig: cloneDeep(FORMS_LIST_HEADERS_CONFIG),
    templateHeadersConfig: cloneDeep(TEMPLATES_LIST_HEADERS_CONFIG),
  }

  static getDerivedStateFromProps(props) {
    const newState = {};
    if (props.pageLoading) {
      newState.pageLoading = false;
    }
    return newState;
  }

  componentDidMount() {
    const { location, isRhinoformEnabled } = this.props;
    if (location.pathname.includes('forms') && isRhinoformEnabled) {
      this.props.fetchFormTemplates(this.state.selectedFilter);
      this.setDefaultSortOnForms();
      this.setState({ selectedKey: NAVIGATION_TABS.forms, sortKey: getDefaultSortKey(FORMS_LIST_HEADERS_CONFIG) });
    } else if (location.pathname.includes('templates')) {
      this.props.fetchMessageTemplates();
      this.setDefaultSortOnTemplates();
      this.setState({ selectedKey: NAVIGATION_TABS.templates, sortKey: getDefaultSortKey(TEMPLATES_LIST_HEADERS_CONFIG) });
    }
  }

  componentDidUpdate(prevProps) {
    const queryParamsChanged = prevProps.match.params.id !== this.props.match.params.id;

    if (queryParamsChanged) {
      this.setState({ panelOpen: !!this.props.match.params.id }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  setDefaultSortOnForms = () => {
    const headers = this.state.formHeadersConfig;
    Object.keys(headers).forEach((key) => {
      headers[key].default = FORMS_LIST_HEADERS_CONFIG[key].default;
      headers[key].direction = FORMS_LIST_HEADERS_CONFIG[key].direction;
    });
    this.setState({ formHeadersConfig: headers });
  }

  setDefaultSortOnTemplates = () => {
    const headers = this.state.templateHeadersConfig;
    Object.keys(headers).forEach((key) => {
      headers[key].default = TEMPLATES_LIST_HEADERS_CONFIG[key].default;
      headers[key].direction = TEMPLATES_LIST_HEADERS_CONFIG[key].direction;
    });
    this.setState({ templateHeadersConfig: headers });
  }

  handleTabs = (index) => {
    const updatedState = {
      selectedKey: index,
      sortOrder: 'asc',
      searchText: '',
    };
    const defaultSortKey = getDefaultSortKey(index === NAVIGATION_TABS.forms ? FORMS_LIST_HEADERS_CONFIG : TEMPLATES_LIST_HEADERS_CONFIG);
    if (index === NAVIGATION_TABS.forms) {
      updatedState.sortKey = defaultSortKey;
      this.setDefaultSortOnForms();
    } else {
      updatedState.sortKey = defaultSortKey;
      this.setDefaultSortOnTemplates();
    }
    this.setState(updatedState);
    const url = index === NAVIGATION_TABS.forms ? 'forms' : 'templates';
    this.props.history.push(`/settings/organization/library/${url}`);
  };

  handleFavorite = (isFavorite, id, e) => {
    e.stopPropagation();
    if (isFavorite) {
      e.currentTarget.classList.remove('u-text-danger');
    } else {
      e.currentTarget.classList.add('u-text-danger');
    }
    if (this.state.selectedKey === NAVIGATION_TABS.forms) {
      const payload = {
        isFavorite: !isFavorite,
      };
      this.props.updateFormTemplates(id, payload, this.state.selectedFilter).then(() => {
        this.handleSort(this.state.sortKey);
      });
    } else {
      this.props.updateFavoriteStatus(id, { userId: this.props.currentUser.id, favorited: !isFavorite }, this.state.selectedFilter).then(() => {
        this.handleSort(this.state.sortKey);
      });
    }
  }

  handleUpdateFormTemplateFilterId = (formTemplateFilterId) => {
    const defaultSortKey = getDefaultSortKey(this.state.selectedKey === NAVIGATION_TABS.forms ? FORMS_LIST_HEADERS_CONFIG : TEMPLATES_LIST_HEADERS_CONFIG);
    if (this.state.selectedKey === NAVIGATION_TABS.forms) {
      const favoriteForms = this.props.allForms.filter((form) => form.isFavorite);
      const nonFavoriteForms = this.props.allForms.filter((form) => !form.isFavorite);
      favoriteForms.sort((a, b) => compareObjectByKey(a, b, defaultSortKey));
      nonFavoriteForms.sort((a, b) => compareObjectByKey(a, b, defaultSortKey));
      this.setDefaultSortOnForms();
      this.setState({ selectedFilter: formTemplateFilterId, searchText: '' }, () => {
        if (formTemplateFilterId === 'favorite') {
          this.props.updateFormsList(favoriteForms);
        } else {
          this.props.updateFormsList([...favoriteForms, ...nonFavoriteForms]);
        }
      });
    } else {
      const favoriteTemplates = this.props.allTemplates.filter((template) => template.favorited);
      const nonFavoriteTemplates = this.props.allTemplates.filter((template) => !template.favorited);
      favoriteTemplates.sort((a, b) => compareObjectByKey(a, b, defaultSortKey));
      nonFavoriteTemplates.sort((a, b) => compareObjectByKey(a, b, defaultSortKey));
      this.setDefaultSortOnTemplates();
      this.setState({ selectedFilter: formTemplateFilterId, searchText: '' }, () => {
        if (formTemplateFilterId === 'favorite') {
          this.props.updateTemplatesList(favoriteTemplates);
        } else {
          this.props.updateTemplatesList([...favoriteTemplates, ...nonFavoriteTemplates]);
        }
      });
    }
  }

  handleSearch = (name, searchText) => {
    const { selectedKey } = this.state;
    let forms = [...this.props.allForms];
    let templates = [...this.props.allTemplates];
    if (this.state.selectedFilter === 'favorite') {
      forms = this.props.allForms.filter((form) => form.isFavorite === true);
      templates = this.props.allTemplates.filter((messageTemplate) => messageTemplate.favorited === true);
    }

    if (searchText.length > 2) {
      if (selectedKey === NAVIGATION_TABS.forms) {
        forms = forms.filter((form) => form.title.toLowerCase().includes(searchText.toLowerCase()));
        this.setDefaultSortOnForms();
        this.props.updateFormsList(forms);
      } else {
        templates = templates.filter((template) =>
          (template.subject.toLowerCase().includes(searchText.toLowerCase()) || template.message.toLowerCase().includes(searchText.toLowerCase())));
        this.setDefaultSortOnTemplates();
        this.props.updateTemplatesList(templates);
      }
    } else if (searchText.length === 0) {
      if (selectedKey === NAVIGATION_TABS.forms) {
        this.setDefaultSortOnForms();
        this.props.updateFormsList(forms);
      } else {
        this.setDefaultSortOnTemplates();
        this.props.updateTemplatesList(templates);
      }
      this.handleUpdateFormTemplateFilterId(this.state.selectedFilter);
    }
    this.setState({
      searchText,
      focusSearchInput: true,
    });
  }

  handleSort = (type) => {
    if (this.state.selectedKey === NAVIGATION_TABS.forms) {
      const favoriteForms = this.props.formTemplates.filter((form) => form.isFavorite);
      const nonFavoriteForms = this.props.formTemplates.filter((form) => !form.isFavorite);
      let sortedFavorites = [];
      let sortedNonFavorites = [];
      sortedFavorites = favoriteForms.sort((a, b) => compareObjectByKey(a, b, type));
      sortedNonFavorites = nonFavoriteForms.sort((a, b) => compareObjectByKey(a, b, type));
      if (this.state.formHeadersConfig[type].direction === -1) {
        sortedFavorites.reverse();
        sortedNonFavorites.reverse();
      }
      const allForms = [...sortedFavorites, ...sortedNonFavorites];
      this.props.updateFormsList(allForms);
      this.setState({ sortKey: type });
    } else {
      const favoriteTemplates = this.props.messageTemplates.filter((template) => template.favorited);
      const nonFavoriteTemplates = this.props.messageTemplates.filter((template) => !template.favorited);
      let sortedFavorites = [];
      let sortedNonFavorites = [];
      sortedFavorites = favoriteTemplates.sort((a, b) => compareObjectByKey(a, b, type));
      sortedNonFavorites = nonFavoriteTemplates.sort((a, b) => compareObjectByKey(a, b, type));
      if (this.state.templateHeadersConfig[type].direction === -1) {
        sortedFavorites.reverse();
        sortedNonFavorites.reverse();
      }
      const allTemplates = [...sortedFavorites, ...sortedNonFavorites];
      this.props.updateTemplatesList(allTemplates);
      this.setState({ sortKey: type });
    }
  }

  handleTogglePanel = (id) => {
    const paramId = this.state.selectedKey === NAVIGATION_TABS.forms ? this.props.match.params.id : parseInt(this.props.match.params.id, 10);
    const shouldClose = this.state.panelOpen && (paramId === id || !id);
    const url = this.state.selectedKey === NAVIGATION_TABS.forms ? 'forms' : 'templates';

    if (shouldClose) {
      this.props.history.push(`/settings/organization/library/${url}`);
    } else {
      this.props.history.push(`/settings/organization/library/${url}/${id}`);
    }

    if (!id) {
      this.props.fetchMembers();
    }
  }

  handleCreateRequest = () => {
    this.props.history.push('/settings/organization/library/templates/create');
  }

  createRhinoForm = () => {
    this.props.history.push('/settings/organization/library/forms/create');
  }

  renderedComponent = (props) => {
    if (this.state.selectedKey === NAVIGATION_TABS.forms) {
      if (this.props.isRhinoformEnabled) {
        return <OrganizationLibrary {...props} />;
      } else {
        return <EmptyMessage section="Restricted" />;
      }
    } else {
      return <OrganizationLibrary {...props} />;
    }
  }

  getFormS3Url = (formId) => {
    const { formTemplates, formS3Url } = this.props;
    const attachedForm = formTemplates.find((form) => form.formId === formId);
    const hasFormUrl = formS3Url && formS3Url[formId]?.formS3Url;
    if (!attachedForm && !hasFormUrl) {
      this.props.getFormTemplateById(formId);
    }
    return attachedForm?.formS3Url || hasFormUrl;
  }

  render() {
    const props = {
      activeItemId: this.state.selectedKey === NAVIGATION_TABS.forms ? this.props.match.params.id : parseInt(this.props.match.params.id, 10),
      count: this.props.count,
      createRhinoForm: this.createRhinoForm,
      filterOptions,
      focusSearchInput: this.state.focusSearchInput,
      formListRef: (formListRef) => (this.formListRef = formListRef),
      formS3Url: this.props.formS3Url,
      formTemplates: this.props.formTemplates,
      formTemplatesHeadersConfig: this.state.formHeadersConfig,
      getFormS3Url: this.getFormS3Url,
      handleCreateRequest: this.handleCreateRequest,
      handleFavorite: this.handleFavorite,
      handleSearch: this.handleSearch,
      handleSort: this.handleSort,
      handleTabs: this.handleTabs,
      handleTogglePanel: this.handleTogglePanel,
      handleUpdateFormTemplateFilterId: this.handleUpdateFormTemplateFilterId,
      hasPagination: false,
      isRhinoformEnabled: this.props.isRhinoformEnabled,
      loggedInUserPermissions: this.props.loggedInUserPermissions,
      messageTemplates: this.props.messageTemplates,
      onScroll: this.onScroll,
      pageLoading: this.props.pageLoading,
      panelOpen: this.state.panelOpen,
      searchText: this.state.searchText,
      selectedFilterId: this.state.selectedFilter,
      selectedKey: this.state.selectedKey,
      templatesListHeadersConfig: this.state.templateHeadersConfig,
      templatesPageLoading: this.props.templatesPageLoading,
      totalTemplates: this.props.totalTemplates,
      types: this.props.types,
    };

    return this.renderedComponent(props);
  }
}

OrganizationLibraryContainer.propTypes = {
  allTemplates: PropTypes.array,
  count: PropTypes.number,
  currentFormsPageNo: PropTypes.number,
  currentTemplatesPageNo: PropTypes.number,
  fetchFormTemplates: PropTypes.func.isRequired,
  fetchMessageTemplates: PropTypes.func.isRequired,
  formTemplates: PropTypes.array,
  isRhinoformEnabled: PropTypes.bool.isRequired,
  loggedInUserPermissions: PropTypes.array.isRequired,
  messageTemplates: PropTypes.array,
  messageTemplatesLoading: PropTypes.bool,
  pageLoading: PropTypes.bool,
  templatesPageLoading: PropTypes.bool,
  totalTemplates: PropTypes.number,
  types: PropTypes.object,
  updateFavoriteStatus: PropTypes.func.isRequired,
  updateFormsList: PropTypes.func.isRequired,
  updateTemplatesList: PropTypes.func.isRequired,
  allForms: PropTypes.array,
  formS3Url: PropTypes.object,
  getFormTemplateById: PropTypes.func,
  fetchMembers: PropTypes.func,
  updateFormTemplates: PropTypes.func,
  currentUser: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { form, messageTemplate, type } = state;
  return {
    allForms: form.allForms,
    allTemplates: messageTemplate.allTemplates,
    count: form.count,
    currentFormsPageNo: form.pageNo,
    currentTemplatesPageNo: messageTemplate.pageNo,
    currentUser: getLoggedInUser(state),
    favoritedForms: form.favoritedForms,
    formS3Url: form.formS3Url,
    formTemplates: form.formTemplates,
    isRhinoformEnabled: !!form.org?.isRhinoformEnabled,
    loggedInUserPermissions: getLoggedInUserPermissionNames(state),
    messageTemplates: messageTemplate.messageTemplates,
    messageTemplatesLoading: messageTemplate.loading,
    pageLoading: form.pageLoading,
    templatesPageLoading: messageTemplate.pageLoading,
    totalTemplates: messageTemplate.total,
    types: type.types,
  };
};

const actions = {
  fetchFormTemplates,
  fetchMessageTemplates,
  getFormTemplateById,
  updateFavoriteStatus,
  updateFormsList,
  updateFormTemplates,
  updateTemplatesList,
};

export default connect(mapStateToProps, actions)(OrganizationLibraryContainer);
