import {
  TYPE_PRESCRIPTION_STATUS_UNCONSENTED,
  TYPE_PRESCRIPTION_STATUS_CANCELLED,
  TYPE_PRESCRIPTION_STATUS_CONSENTED,
} from '../constants/Types';
import { PRESCRIPTION_MESSAGE_VARIABLE_OPTIONS } from '../constants/VariableMessageConstants';

export function formatConsentStatus(prescriptionStatusTypeId) {
  const consentStatuses = {
    [TYPE_PRESCRIPTION_STATUS_UNCONSENTED]: 'Unconsented',
    [TYPE_PRESCRIPTION_STATUS_CANCELLED]: 'Cancelled',
    [TYPE_PRESCRIPTION_STATUS_CONSENTED]: 'Consented',
  };
  return consentStatuses[prescriptionStatusTypeId] || '';
}

export const PRESCRIPTION_NOTIFICATION_HEADERS = {
  name: {
    default: true,
    direction: 1,
  },
  lastUpdatedAt: {
    default: false,
    direction: -1,
  },
};

export const PRESCRIPTION_HEADERS = {
  createdAt: {
    default: true,
    direction: 1,
  },
  prescriptions: {
    default: false,
    direction: -1,
  },
  isRefill: {
    default: false,
    direction: -1,
  },
  payorNames: {
    default: false,
    direction: -1,
  },
  payorBins: {
    default: false,
    direction: -1,
  },
  consentStatusTypeId: {
    default: false,
    direction: -1,
  },
  lastName: {
    default: false,
    direction: -1,
  },
  name: {
    default: false,
    direction: -1,
  },
  saleCompletedDate: {
    default: false,
    direction: -1,
  },
  patientId: {
    default: false,
    direction: -1,
  },
};

export function validateQueryStringValue(value, key) {
  if (key === 'status') {
    return ['Completed', 'Sent', 'Expired'].includes(value) ? value : null;
  } if (key === 'sortBy') {
    return PRESCRIPTION_HEADERS[key] ? value : null;
  } return value;
}

const consentVariables = PRESCRIPTION_MESSAGE_VARIABLE_OPTIONS.filter((variableOptions) => variableOptions.category === 'CodifiedResponse').map((variableOptions) => variableOptions.variable);
const consentRegExPattern = new RegExp(consentVariables.join('|'), 'i');

export function templateContainsConsent(template) {
  return consentRegExPattern.test(template);
}

const weightingLookupTable = {
  type: { TYPE_PRESCRIPTION_CAMPAIGN_INITAL_TYPE: 0, TYPE_PRESCRIPTION_CAMPAIGN_REFILL_TYPE: 1 },
  direction: { TYPE_PRESCRIPTION_CAMPAIGN_DELIVERY_DIRECTION_PRE: 0, TYPE_PRESCRIPTION_CAMPAIGN_DELIVERY_DIRECTION_POST: 1 },
  baseOnDate: { TYPE_EVENT_DATE_CREATED_AT: 0, TYPE_EVENT_DATE_PRESCRIPTION_REFILL: 1 },
};

function getScore(campaignMessage) {
  const w0 = 1000 * weightingLookupTable.type[campaignMessage.type];
  const w1 = 100 * weightingLookupTable.direction[campaignMessage.deliveryHoursDirection];
  const w2 = 10 * weightingLookupTable.baseOnDate[campaignMessage.deliveryHoursType];
  const w3 = campaignMessage.deliveryHours;
  return w0 + w1 + w2 + w3;
}

export function sortMessagesByDeliveryHours(a, b) {
  const aVal = getScore(a);
  const bVal = getScore(b);
  return bVal - aVal;
}
