import PropTypes from 'prop-types';
import React, { Fragment, useState, useRef } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import cx from 'classnames';
import ReactDOM from 'react-dom';

import {
  Button,
  Dropdown,
  DropdownMenuHeader,
  DropdownMenuItem,
  DropdownMenuDivider,
  DropdownMenuItemWild,
  Icon,
  ResourceGroup,
  UtilityInlineGrid,
  UtilitySystem,
} from 'rhinostyle';
import ReactHtmlParser from 'react-html-parser';
import { AppConstants } from '../constants';
import {
  UserHelpers,
  UIHelpers,
  EventHelpers,
} from '../helpers';
import {
  THREAD_VIEW,
  THREAD_UNREAD_EDIT,
  THREAD_FOLLOW_EDIT,
  CONTACT_VIEW,
  CONVERSATION_CLOSE_DEFAULT,
  ASSIGNMENT_SELF_CREATE,
  ASSIGNMENT_GROUP_CREATE,
  ASSIGNMENT_MEMBER_CREATE,
  CONTENT_SELECT_AND_SAVE_EDIT,
  TEAM_THREAD_UNREAD_EDIT,
  CONVERSATION_CONTACT_MOBILE,
  CONVERSATION_TEAM_MOBILE,
} from '../constants/UserPermissionsConstants';
import {
  getInboxContext,
} from '../selectors/inboxSelectors';
import { getIsRhinocallStartButtonEnabled } from '../selectors/rhinocallSelector';
import { getIsVideoStartButtonEnabled } from '../selectors/rhinovideoSelectors';
import { userHasAnyOfPermissions, userHasAllOfPermissions } from '../helpers/UserHelpers';
import ConnectedPartyItemContainer from '../containers/ConnectedPartyItemContainer';
import UserBadges from './UserBadges';
import VideoStartButton from './VideoStartButton';
import { isMobile } from '../helpers/BrowserHelpers';
import RhinocallStartButton from './RhinocallStartButton';
import * as ThreadReducer from '../reducers/threadReducer';
import * as InboxReducer from '../reducers/inboxReducer';
import {
  getLoggedInUser,
  getLoggedInUserOrganization,
  userHasLimitedProviderRole,
} from '../selectors/userSelectors';
import * as SavedContentReducer from '../reducers/savedContentReducer';
import * as AssignmentActions from '../actions/AssignmentActions';
import {
  PANEL_OPTIONS,
}
  from '../constants/ThreadConstants';
import { useActiveUser, useSelectThreadEvents, useLastEvent } from '../hooks';

const InboxThreadHeader = (props) => {
  const {
    activePanel,
    channels,
    connectedParties,
    currentUserId,
    filtersApplied,
    inboxContext,
    isProfileNoteAboveTruncateLength,
    isRhinocallStartButtonEnabled,
    isRhinopayEnabled,
    isSavedContentEnabled,
    isSelectionModeEnabled,
    profileOpen,
    threadLoading,
    types,
    userOrganization,
    threadFilteredChannelIds,
    isMobileThreadHeaderEnabled,
    isVideoStartButtonEnabled,
    isSelectionPreviewModeEnabled,
    isCcr,
    openAssignments,
    isLimitedProvider,
    assignees,
  } = props;
  if (isSelectionPreviewModeEnabled) return null;
  const { mostRecentEvent } = useLastEvent();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const activeUser = useActiveUser();
  const mobileHeaderDropdownRef = useRef();
  const connectedPartiesDropdownRef = useRef();
  const selectedEventsQueryResult = useSelectThreadEvents();
  const hasMessageEvent = selectedEventsQueryResult?.data?.ids?.some((id) => {
    const event = selectedEventsQueryResult?.data?.entities?.[id];
    return EventHelpers.isMessageEvent(event) || EventHelpers.isSecureEvent(event);
  });

  const isFollowing = selectedEventsQueryResult?.data?.ids?.some((id) => {
    const event = selectedEventsQueryResult?.data?.entities?.[id];
    return event.following;
  });
  const [isProfileNoteTruncated, setIsProfileNoteTruncated] = useState(false);
  const [headerActionLoading, setHeaderActionLoading] = useState(false);
  const { groupId } = params;
  const hasPatientCommInfo = (activeUser?.phones?.length > 0)
    || (activeUser?.facebooks?.length > 0) || (activeUser?.rhinograms?.length > 0);
  const followingText = isFollowing ? 'Unfollow' : 'Follow';
  const defaultRoutes = Object.keys(channels).reduce((filtered, channel) => {
    if (threadFilteredChannelIds.includes(parseInt(channel, 10))) {
      if (!channels[channel].deleted && channels[channel].route) {
        const defaultChannelName = channels[channel].route.name;
        filtered.push(defaultChannelName);
      }
    }

    const newFilteredSet = [...new Set([...filtered])];
    return newFilteredSet;
  }, []);
  const isAssignment = inboxContext === 'assigned' || mostRecentEvent?.assigned || mostRecentEvent?.isAssigned;
  const notificationType = isAssignment ? 'completeAssignment' : 'closeConversation';
  const completeText = isAssignment ? 'Assignment Complete' : 'Close Conversation';
  const showAssignToMe = hasMessageEvent && hasPatientCommInfo && !['assigned', 'following', 'direct', 'all'].includes(inboxContext);
  const showComplete = hasMessageEvent && (inboxContext !== 'following');
  const showCloseOptionsForAllRoute = (inboxContext === 'all' || inboxContext === 'assigned');
  const showUnread = hasMessageEvent && inboxContext !== 'all';
  const externalId = UserHelpers.getExternalId(activeUser);
  const showCloseConversationDropdown = isLimitedProvider && showCloseOptionsForAllRoute;
  const assignedToMeOpenConversations = openAssignments.filter((oc) => oc.assignedUserId === currentUserId);

  const getDescriptionForNonAllRouteAction = () => {
    if (!isAssignment) return null;
    if (userOrganization.allowClosingByAssignee) {
      return <><i>Conversation will be closed</i></>;
    }
    return <><i>Conversation will return to <strong>{defaultRoutes.map((route) => route).join(', ')}</strong></i></>;
  };

  const renderCloseConversationMenuForNonAllRoute = () => (
    <DropdownMenuItem
      disabled={headerActionLoading}
      label={completeText}
      dataCypress="close-conversation-menu-item"
      labelDesc={getDescriptionForNonAllRouteAction()}
      onClick={() => handleComplete()}
    />
  );

  const isAssignmentRouted = (openAssignment) => {
    if (openAssignment.assignedGroupId && openAssignment.assignedGroupId !== openAssignment.startGroupId) return true;
    if (openAssignment.assignedUserId && openAssignment.assignedUserId !== openAssignment.startUserId) return true;
    return false;
  };

  const getDefaultRouteName = (openAssignment) => {
    if (openAssignment.startGroupId) return openAssignment.startGroupName;
    if (openAssignment.startUserId) return `${openAssignment.startUserLastName}, ${openAssignment.startUserFirstName}`;
    return '';
  };

  const getAssignedRouteName = (openAssignment) => {
    if (!isAssignmentRouted(openAssignment)) return getDefaultRouteName(openAssignment);
    if (openAssignment.assignedGroupId) return openAssignment.assignedGroupName;
    if (openAssignment.assignedUserId) return 'Assigned to Me';
    return '';
  };

  const getDescriptionForAllRouteAction = (openAssignment) => {
    if (!userOrganization.allowClosingByAssignee && isAssignmentRouted(openAssignment)) {
      return <><div className="u-m-l-large u-text-base"><i>return to <strong>{getDefaultRouteName(openAssignment)}</strong></i></div></>;
    }
    return <><div className="u-m-l-large u-text-base"><i>conversation will be closed</i></div></>;
  };

  const getLabelForAllRouteAction = (openAssignment) => (
    <div className="u-m-l-large u-text-base">in <strong>{getAssignedRouteName(openAssignment)}</strong></div>
  );

  const renderCloseConversationMenuItemForAllRoute = (openAssignment) => (
    <DropdownMenuItem
      key={openAssignment.id}
      disabled={headerActionLoading}
      dataCypress={`close-conversation-menu-item-${openAssignment.id}`}
      label={getLabelForAllRouteAction(openAssignment)}
      labelDesc={getDescriptionForAllRouteAction(openAssignment)}
      onClick={() => handleComplete(openAssignment)}
    />
  );

  const renderCloseConversationMenuForAllRoute = () => {
    if (showCloseConversationDropdown || openAssignments.length === 0) return null;
    const isAssignmentAction = openAssignments.some((oa) => isAssignmentRouted(oa));
    const parentCloseMenuText = isAssignmentAction ? 'Assignment Complete' : 'Close Conversation';
    return (
      <div>
        <DropdownMenuItem
          disabled
          label={<div style={{ color: '#404040' }}>{parentCloseMenuText}</div>}
        />
        {openAssignments.map(renderCloseConversationMenuItemForAllRoute)}
      </div>
    );
  };

  const renderCloseConversationMenuForLimitedProviders = () => {
    if (assignedToMeOpenConversations.length === 0) return null;
    return (
      <div>
        <DropdownMenuItem
          disabled
          label={<div style={{ color: '#404040' }}>Close Conversation</div>}
        />
        {assignedToMeOpenConversations.map((assignedToMeOpenConversation) => (
          <DropdownMenuItem
            key={assignedToMeOpenConversations.id}
            disabled={headerActionLoading}
            dataCypress={`close-conversation-menu-item-${assignedToMeOpenConversation.id}`}
            label={getLabelForAllRouteAction(assignedToMeOpenConversation)}
            labelDesc={getDescriptionForAllRouteAction(assignedToMeOpenConversation)}
            onClick={() => handleComplete(assignedToMeOpenConversation)}
          />
        ))}
      </div>
    );
  };

  const renderCloseConversationMenu = () => {
    if (showCloseOptionsForAllRoute) return renderCloseConversationMenuForAllRoute();
    else return renderCloseConversationMenuForNonAllRoute();
  };

  const handlePanelClick = (panel) => {
    if (!location.pathname.includes(activePanel) && [PANEL_OPTIONS.library, PANEL_OPTIONS.savedContent].includes(activePanel)) {
      history.push({ pathname: location.pathname, search: `?activePanel=${activePanel}` });
    } else if (UIHelpers.panelBreakpointMax()) {
      dispatch(ThreadReducer.toggleProfile());
    }
    dispatch(ThreadReducer.setActivePanel(panel));
  };

  function toggleProfileNoteTruncate() {
    setIsProfileNoteTruncated((current) => !current);
  }

  function toggleHighlightsDropdown() {
    const $dropdown = ReactDOM.findDOMNode(connectedPartiesDropdownRef?.current);
    if ($dropdown) {
      const $dropdownToggle = $dropdown.querySelector('.dropdown__toggle');
      $dropdownToggle.click();
    }
  }

  const handleToggleContentSelectionMode = () => {
    dispatch(SavedContentReducer.setIsSelectionModeEnabled(true));
  };

  async function handleComplete(openAssignment) {
    const payload = {
      patientUserId: activeUser.id,
      conversationActionType: notificationType,
    };
    if (!openAssignment) {
      setHeaderActionLoading(true);
      dispatch(AssignmentActions.shapeInboxContentForCompletion(payload, groupId)).then(() => {
        redirectToInbox();
      });
    } else {
      // this is being completed from the all route, so do not redirect
      payload.assignmentId = openAssignment.id;
      if (openAssignment.assignedUserId) payload.assigned = true;
      else if (openAssignment.assignedGroupId) payload.groupId = Number(openAssignment.assignedGroupId);
      await dispatch(AssignmentActions.closeAssignment(payload));
      if (inboxContext === 'assigned') {
        redirectToInbox();
      }
    }
  }

  function handleFollow(following) {
    const followingOpts = {
      userId: activeUser.id,
      followingFlag: following,
    };
    if (inboxContext === 'group') {
      followingOpts.groupId = groupId;
    } else if (inboxContext === 'assigned') {
      followingOpts.assigned = true;
    } else if (inboxContext === 'direct') {
      followingOpts.direct = true;
    } else if (inboxContext === 'following') {
      followingOpts.following = true;
    }
    if (threadFilteredChannelIds.length > 0) followingOpts.channelIds = threadFilteredChannelIds;
    dispatch(InboxReducer.updateFollowing(followingOpts));
  }
  const handleAssignToMe = () => {
    const newLocation = location.pathname.substring(0, location.pathname.indexOf('/user'));

    const payload = {
      patientUserId: activeUser.id,
      groupId: Number(groupId),
      toMemberId: currentUserId,
    };
    setHeaderActionLoading(true);
    dispatch(AssignmentActions.createAssignmentSelf(payload, true)).then(() => {
      history.push(newLocation); // redirect back to current inbox
    });
  };

  const handleMarkUnread = async () => {
    setHeaderActionLoading(true);
    await updateReadStatus(false);
    redirectToInbox();
  };

  async function updateReadStatus(isRead, audit = true) {
    if (activeUser && threadFilteredChannelIds?.length > 0) {
      const readOpts = {
        userId: activeUser.id,
        read: isRead,
        audit,
        channelIds: threadFilteredChannelIds,
        ...inboxContext === 'group' && {
          groupId,
        },
        ...inboxContext === 'assigned' && {
          assigned: true,
        },
        ...inboxContext === 'direct' && {
          direct: true,
        },
        ...inboxContext === 'following' && {
          following: true,
        },
      };
      await dispatch(InboxReducer.updateRead(readOpts));
    }
  }

  function redirectToInbox() {
    const newLocation = location.pathname.substring(0, location.pathname.indexOf('/user'));
    history.push(newLocation); // redirect back to current inbox
  }

  const handleCloseInboxThread = () => {
    if (history.canGoBack) {
      const lastUrl = history.historyUrls[history.historyUrls.length - 2];
      if (!(lastUrl.includes('inbox') && !lastUrl.includes('user'))) {
        return history.goBack();
      }
    }
    const { mentionEventId } = location?.state || {};
    let newLocation = null;

    if (mentionEventId) newLocation = '/inbox/mentions';
    else newLocation = inboxContext === 'all' ? `/contacts/${activeUser.id}` : location.pathname.substring(0, location.pathname.indexOf('/user'));

    const eventIds = location?.state?.selectedInboxEventIds || [];
    if (location.state) {
      const selectedIds = [...eventIds];
      let currentId = location.state.currentEventId;
      const isSelected = selectedIds.includes(currentId);
      if (selectedIds && selectedIds.length > 0) {
        if (mostRecentEvent?.id && isSelected && currentId !== mostRecentEvent.id) {
          const index = selectedIds.indexOf(currentId);
          selectedIds.splice(index, 1, mostRecentEvent.id);
          currentId = mostRecentEvent.id;
        }
      }
      const {
        currentPageNumber,
        pageNumber,
        totalPageCount,
        isChecked,
        labelValueAssociated,
        checkboxClassName,
        selectedIdCount,
        bulkActionsList,
        contactIds,
        selectedInboxEvents,
      } = location.state;
      return history.push({
        pathname: newLocation,
        state: {
          currentPageNumber,
          pageNumber,
          selectedInboxEventIds: selectedIds,
          totalPageCount,
          isChecked,
          labelValueAssociated,
          checkboxClassName,
          selectedIdCount,
          bulkActionsList,
          contactIds,
          selectedInboxEvents,
          currentEventId: currentId,
        },
      });
    }
    return history.push({
      pathname: newLocation,
    });
  };

  const getProfileNote = () => {
    const note = activeUser && activeUser.note;

    let html = note.replace(/(\n|\r)+/g, '<br />');
    if (isProfileNoteTruncated) {
      const viewMoreText = '...View More';
      html = `${html.substr(0, AppConstants.NOTE_TRUNCATE_LENGTH - viewMoreText.length)}... `;
    } else {
      html = `${html} `;
    }

    return html;
  };

  const renderConnectedParty = (id, key) => {
    const cp = connectedParties[id];

    if (!cp) return null;

    const ct = types[cp.connectionTypeId];
    return <ConnectedPartyItemContainer profileOpen={profileOpen} key={key} connection={cp} type={ct} history={history} />;
  };

  const actionButtonClasses = (panel) =>
    cx('convo__header__actions__button', {
      [UtilitySystem.config.classes.active]: activePanel === panel && profileOpen,
      'has-filter': (panel === 'filter' && filtersApplied),
    });

  const convoHeaderClasses = cx('convo__header convo__header--variation', {
    'convo__header--mobile-enabled': isMobileThreadHeaderEnabled,
  });

  const connectedPartyClasses = cx('convo__header__connected-parties__dropdown-wrapper', {
    'convo__header__connected-parties__dropdown-wrapper--left': isLimitedProvider,
  });

  const convoStartButtonClasses = cx('convo__start-button__wrapper', {
    'convo__start-button__wrapper--multiple': isRhinocallStartButtonEnabled && isVideoStartButtonEnabled,
  });

  const renderActionButtons = () => (
    <>
      {(isMobile() ?
        userHasAllOfPermissions([CONTACT_VIEW, CONVERSATION_CONTACT_MOBILE]) :
        userHasAnyOfPermissions([CONTACT_VIEW])) && (
          <div className="u-text-center">
            <Button
              title="Contact Profile"
              className={actionButtonClasses(PANEL_OPTIONS.profile)}
              onClick={() => handlePanelClick(PANEL_OPTIONS.profile)}
              reset
            >
              <Icon icon="user" />
            </Button>
            <div className="convo__header__actions__label">Profile</div>
          </div>
      )}
      {showCloseConversationDropdown && assignedToMeOpenConversations?.length > 0 && (
        <div className="u-text-center">
          <Dropdown
            title="Close Conversation"
            dataCypress="close-conversation"
            hideCaret
            className="convo__header__actions__button convo__header__actions__button--close-convo"
            position="right"
            icon="close-convo"
            wide
            reset
          >
            {renderCloseConversationMenuForLimitedProviders()}
          </Dropdown>
          <div className="convo__header__actions__label">Close Conversation</div>
        </div>
      )}
      {!isCcr
        && hasPatientCommInfo
        && hasMessageEvent
        && (isLimitedProvider || inboxContext !== 'all')
        && inboxContext !== 'following'
        && userHasAnyOfPermissions([ASSIGNMENT_GROUP_CREATE, ASSIGNMENT_MEMBER_CREATE])
        && (
          <div className="u-text-center">
            <Button
              title="Assign Conversation"
              className={actionButtonClasses(PANEL_OPTIONS.assign)}
              onClick={() => handlePanelClick(PANEL_OPTIONS.assign)}
              reset
            >
              <Icon icon="assign" />
            </Button>
            <div className="convo__header__actions__label">Assign</div>
          </div>
        )}
    </>
  );
  const renderAssignees = () => {
    const assineeNames = assignees.map((assignee) => {
      if (assignee?.toUserGroupId) {
        return assignee?.name;
      }
      return UserHelpers.formatName(assignee);
    });
    return (
      <span>{assineeNames.join(', ')}</span>
    );
  };

  const hasConnectedParties = activeUser?.connectedParties?.length > 0;
  const hasNote = activeUser?.note;
  const hasFollowPermission = isMobile() ? userHasAllOfPermissions([THREAD_FOLLOW_EDIT, CONVERSATION_CONTACT_MOBILE]) :
    userHasAnyOfPermissions([THREAD_FOLLOW_EDIT]);
  const hasMarkUnreadPermission = (isMobile() ? userHasAllOfPermissions([THREAD_UNREAD_EDIT, CONVERSATION_CONTACT_MOBILE]) :
    userHasAnyOfPermissions([THREAD_UNREAD_EDIT])) && showUnread;
  const hasMarkUnreadThreadPermission = (isMobile() ? (userHasAllOfPermissions([THREAD_UNREAD_EDIT, CONVERSATION_CONTACT_MOBILE]) ||
    userHasAllOfPermissions([TEAM_THREAD_UNREAD_EDIT, CONVERSATION_TEAM_MOBILE])) :
    userHasAnyOfPermissions([THREAD_UNREAD_EDIT, TEAM_THREAD_UNREAD_EDIT])) && showUnread;
  const assineeClasses = cx('convo__header__assinees--label', {
    'convo__header__assinees--padding': (hasNote || hasConnectedParties),
  });
  return (
    <div className={convoHeaderClasses}>
      {activeUser && !isLimitedProvider && (
        <Button
          className="u-text-muted convo__header__back-button u-m-r"
          onClick={handleCloseInboxThread}
          reset
          title="Back to list"
        >
          <Icon icon="arrow-left" />
        </Button>
      )}
      <div className="convo__header__title">
        <div className="convo__header__title__name">
          <span data-cypress="convoContactName" className="u-text-overflow">{activeUser ? UserHelpers.formatName(activeUser) : 'Unknown'}</span>
          <div className={`convo__header__title__deets ${hasNote || hasConnectedParties ? 'convo__header__title__deets--padded' : ''}`}>
            <span className="u-text-overflow">
              {activeUser ? UserHelpers.formatTypes(activeUser) : null}
              {externalId ? <span>&nbsp;(#{externalId})</span> : null}
            </span>
          </div>
          <div className="convo__header__title__badges">{activeUser && (<UserBadges user={activeUser} isRhinopayEnabled={!!isRhinopayEnabled} />)}</div>
        </div>
        {assignees.length > 0 && (
          <div className="convo__header__assinees">
            <span className={assineeClasses}>| Assigned To:</span> {renderAssignees()}
          </div>
        )}
      </div>
      {(hasNote || hasConnectedParties) && (
        <div className={connectedPartyClasses}>
          <Dropdown
            reset
            className="convo__header__connected-parties__dropdown"
            icon="connected-parties"
            hideCaret
            position="left"
            ref={connectedPartiesDropdownRef}
          >
            <Button
              className="convo__header__connected-parties__close-button"
              reset
              onClick={toggleHighlightsDropdown}
            >
              <Icon icon="close" />
            </Button>
            {hasNote && (
              <DropdownMenuItemWild className="convo__header__connected-parties__top">
                <div className="u-flex u-flex-justify-between u-m-b">
                  <DropdownMenuHeader label="Highlights" />
                </div>
                <div className={`summary-panel__note ${activeUser.noteIsImportant && 'summary-panel__note--important'}`}>
                  <Icon icon="note" bump="up" />&nbsp;&nbsp;
                  {isProfileNoteAboveTruncateLength ? (
                    <>
                      {ReactHtmlParser(getProfileNote())}
                      <Button className="u-text-primary" reset onClick={toggleProfileNoteTruncate}>View {isProfileNoteTruncated ? 'More' : 'Less'}</Button>
                    </>
                  ) :
                    ReactHtmlParser(getProfileNote())}
                </div>
              </DropdownMenuItemWild>
            )}
            {hasConnectedParties && (
              <DropdownMenuItemWild className="convo__header__connected-parties__body">
                <DropdownMenuHeader label="Connected Parties" />
                <ResourceGroup className="u-border-remove">
                  {activeUser.connectedParties.map(renderConnectedParty)}
                </ResourceGroup>
              </DropdownMenuItemWild>
            )}
          </Dropdown>
        </div>
      )}
      <div className="convo__header__actions__container">
        <div className="convo__header__actions">
          {renderActionButtons()}
          {!isCcr && (threadLoading || mostRecentEvent)
            && userHasAnyOfPermissions([CONVERSATION_CLOSE_DEFAULT, ASSIGNMENT_SELF_CREATE, THREAD_FOLLOW_EDIT, THREAD_UNREAD_EDIT])
            && (
              <Dropdown
                title="More Options"
                dataCypress="more-options"
                hideCaret
                className="convo__header__actions__button"
                position="right"
                icon="dots-vertical"
                wide
                reset
              >
                {/* NOTE: The dropdown is repeated for mobile but we're unable to abstract it to a render function because
                              putting all the DropdownMenuItem's in a Fragment results in loss of direct children for the Dropdown which means it will not
                              collapse when an item is selected.
                            */}
                {((threadLoading || mostRecentEvent) &&
                  (isMobile() ?
                    userHasAllOfPermissions([THREAD_VIEW, CONVERSATION_CONTACT_MOBILE]) :
                    userHasAnyOfPermissions([THREAD_VIEW]))) && (
                    <DropdownMenuItem
                      label="Search Conversation"
                      onClick={() => handlePanelClick('search')}
                    />
                )}
                {(isMobile() ?
                  userHasAllOfPermissions([THREAD_VIEW, CONVERSATION_CONTACT_MOBILE]) :
                  userHasAnyOfPermissions([THREAD_VIEW])) && (
                    <DropdownMenuItem
                      label="Filter Conversation"
                      onClick={() => handlePanelClick('filter')}
                    />
                )}
                {userHasAnyOfPermissions([ASSIGNMENT_SELF_CREATE]) && showAssignToMe && (
                  <DropdownMenuItem
                    disabled={headerActionLoading}
                    label="Assign to Me"
                    onClick={handleAssignToMe}
                  />
                )}
                {showComplete && (userHasAnyOfPermissions([CONVERSATION_CLOSE_DEFAULT])) && (
                  renderCloseConversationMenu()
                )}
                {hasFollowPermission && (
                  <DropdownMenuItem label={followingText} onClick={() => handleFollow(!isFollowing)} />
                )}
                {hasMarkUnreadPermission && (
                  <DropdownMenuItem
                    disabled={headerActionLoading}
                    label="Mark as Unread"
                    onClick={handleMarkUnread}
                  />
                )}
                {userHasAnyOfPermissions([CONTENT_SELECT_AND_SAVE_EDIT]) && isSavedContentEnabled && !isSelectionModeEnabled && (
                  <DropdownMenuItem
                    disabled={headerActionLoading}
                    label="Select Content"
                    onClick={handleToggleContentSelectionMode}
                  />
                )}
              </Dropdown>
            )}
          <Dropdown
            title="Toggle convo toolkit"
            hideCaret
            wrapperClassName="convo__mobile-dropdown"
            className="convo__header__actions__button"
            position="right"
            icon={isMobileThreadHeaderEnabled ? 'close' : 'list'}
            wide
            disableScroll
            reset
            onStart={() => dispatch(ThreadReducer.setIsMobileThreadHeaderEnabled())}
            onReverseStart={() => dispatch(ThreadReducer.setIsMobileThreadHeaderEnabled())}
            ref={mobileHeaderDropdownRef}
          >
            <DropdownMenuItemWild className="u-p-x-large">
              <UtilityInlineGrid size="large">
                {renderActionButtons()}
              </UtilityInlineGrid>
            </DropdownMenuItemWild>
            <DropdownMenuDivider />
            {((threadLoading || mostRecentEvent) &&
              (isMobile() ? userHasAllOfPermissions([CONVERSATION_CONTACT_MOBILE, THREAD_VIEW]) :
                userHasAnyOfPermissions([THREAD_VIEW]))) && (
                <DropdownMenuItem
                  label="Search Conversation"
                  onClick={() => handlePanelClick('search')}
                />
            )}
            {(isMobile() ? userHasAllOfPermissions([CONVERSATION_CONTACT_MOBILE, THREAD_VIEW]) :
              userHasAnyOfPermissions([THREAD_VIEW])) && (
                <DropdownMenuItem
                  label="Filter Conversation"
                  onClick={() => handlePanelClick('filter')}
                />
            )}
            {userHasAnyOfPermissions([ASSIGNMENT_SELF_CREATE]) && showAssignToMe && (
              <DropdownMenuItem
                disabled={headerActionLoading}
                label="Assign to Me"
                onClick={handleAssignToMe}
              />
            )}
            {showComplete && (userHasAnyOfPermissions([CONVERSATION_CLOSE_DEFAULT])) && (
              renderCloseConversationMenu()
            )}
            {hasFollowPermission && (
              <DropdownMenuItem label={followingText} onClick={() => handleFollow(!isFollowing)} />
            )}
            {hasMarkUnreadThreadPermission && (
              <DropdownMenuItem
                disabled={headerActionLoading}
                label="Mark as Unread"
                onClick={handleMarkUnread}
              />
            )}
            {userHasAnyOfPermissions([CONTENT_SELECT_AND_SAVE_EDIT]) && (
              <DropdownMenuItem
                disabled={headerActionLoading}
                label="Select Content"
                onClick={handleToggleContentSelectionMode}
              />
            )}
          </Dropdown>
        </div>
        <div className={convoStartButtonClasses}>
          {isVideoStartButtonEnabled && (<VideoStartButton />)}
          {isRhinocallStartButtonEnabled && (<RhinocallStartButton />)}
        </div>
      </div>
    </div>
  );
};

InboxThreadHeader.propTypes = {
  activePanel: PropTypes.oneOf(Object.values(PANEL_OPTIONS)),
  channels: PropTypes.object.isRequired,
  connectedParties: PropTypes.object,
  isCcr: PropTypes.bool,
  currentUserId: PropTypes.number,
  filtersApplied: PropTypes.bool.isRequired,
  inboxContext: PropTypes.string.isRequired,
  isMobileThreadHeaderEnabled: PropTypes.bool.isRequired,
  isProfileNoteAboveTruncateLength: PropTypes.bool,
  isRhinocallStartButtonEnabled: PropTypes.bool,
  isRhinopayEnabled: PropTypes.bool,
  isSavedContentEnabled: PropTypes.bool,
  isSelectionModeEnabled: PropTypes.bool.isRequired, // Governs state of selection mode on/off.
  isVideoStartButtonEnabled: PropTypes.bool,
  profileOpen: PropTypes.bool,
  threadFilteredChannelIds: PropTypes.array,
  threadLoading: PropTypes.bool,
  types: PropTypes.object.isRequired,
  userOrganization: PropTypes.object,
  isSelectionPreviewModeEnabled: PropTypes.bool,
  openAssignments: PropTypes.array,
  isLimitedProvider: PropTypes.bool,
  assignees: PropTypes.array,
};

const mapStateToProps = (state) => {
  const {
    auth,
    channel,
    connectedParty,
    inbox,
    pay,
    thread,
    type,
    savedContent,
  } = state;
  return {
    activePanel: thread.activePanel,
    channels: channel.channels,
    connectedParties: connectedParty.connectedParties,
    isCcr: getLoggedInUser(state).isCcr,
    currentUserId: auth.currentUser,
    inboxContext: getInboxContext(state),
    isMobileThreadHeaderEnabled: thread.isMobileThreadHeaderEnabled,
    isRhinopayEnabled: pay.merchant.isRhinopayEnabled,
    isSavedContentEnabled: !!getLoggedInUserOrganization(state)?.isSavedContentEnabled,
    isSelectionModeEnabled: savedContent.isSelectionModeEnabled,
    isSelectionPreviewModeEnabled: savedContent.isSelectionPreviewModeEnabled,
    isVideoStartButtonEnabled: getIsVideoStartButtonEnabled(state),
    profileOpen: thread.profileOpen,
    threadFilteredChannelIds: inbox.threadFilteredChannelIds,
    threadLoading: inbox.threadLoading,
    assignees: inbox.assignees,
    types: type.types,
    userOrganization: getLoggedInUserOrganization(state),
    isRhinocallStartButtonEnabled: getIsRhinocallStartButtonEnabled(state),
    filtersApplied: inbox.threadSelectedChannelIds?.length > 0 && state.inbox?.threadSelectedChannelIds !== state.inbox.threadFilteredChannelIds,
    openAssignments: inbox.openAssignments,
    isLimitedProvider: userHasLimitedProviderRole(state),
  };
};

export default connect(mapStateToProps)(InboxThreadHeader);
