import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

import NotificationService from '../services/NotificationService';
import { exists } from '../helpers/DataHelpers';

import * as OrganizationActionTypes from '../constants/OrganizationActionTypes';
import { PAGE_SIZE } from '../constants/AppConstants';

import { setError } from './uiReducer';

// SLICE
const billingSlice = createSlice({
  name: 'BILLING',
  initialState: {
    coupons: [],
    payment: {},
    products: [],
    billing: {},
    statements: [],
    subscription: {},
    totalStatements: 0,
    subscriptionLoading: false,
    loading: false,
    billingError: false,
    draftInvoiceId: null,
    isValidCaptcha: true,
    errorMessage: '',
  },
  reducers: {
    receiveBilling: receiveBillingData,
    invalidCaptcha: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    receiveBillingStatements: (state, action) => ({
      ...state,
      statements: [
        ...(state.statements || []),
        ...action.payload.statements,
      ],
    }),
    receiveBillingProducts: (state, action) => ({
      ...state,
      products: [
        ...action.payload.products,
      ],
    }),
    receiveBillingPayment: receivePaymentData,
    receiveBillingCoupons: (state, action) => ({
      ...state,
      coupons: [
        ...action.payload.coupons,
      ],
    }),
    receiveUpdateBillingContact: receiveBillingData,
    receiveUpdatePaymentProfile: receivePaymentData,
    receiveSubscription: (state, action) => ({
      ...state,
      subscription: action.payload.subscription,
    }),
    receiveSubscriptionAndBilling: (state, action) => ({
      ...state,
      subscription: action.payload.subscription,
      billing: action.payload.billing,
    }),
    receiveTotalStatements: (state, action) => ({
      ...state,
      totalStatements: action.payload.totalStatements,
    }),
    resetStatements: (state) => ({
      ...state,
      statements: [],
    }),
    requestData: (state, action) => ({
      ...state,
      subscriptionLoading: action.payload,
    }),
    setLoading: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    receiveBillingErrors: (state, action) => ({
      ...state,
      billingError: action.payload.billingError,
    }),
    receiveDraftInvoiceId: (state, action) => ({
      ...state,
      draftInvoiceId: action.payload.draftInvoiceId,
    }),
  },
  extraReducers: {
    [OrganizationActionTypes.receiveOrganizationProfileView]: receiveBillingData,
  },
});

export default billingSlice.reducer;

// ACTIONS
export const {
  receiveBilling,
  receiveBillingStatements,
  receiveBillingProducts,
  receiveBillingPayment,
  receiveBillingCoupons,
  receiveUpdateBillingContact,
  receiveUpdatePaymentProfile,
  receiveSubscription,
  receiveSubscriptionAndBilling,
  receiveTotalStatements,
  resetStatements,
  requestData,
  setLoading,
  receiveBillingErrors,
  receiveDraftInvoiceId,
  invalidCaptcha,
} = billingSlice.actions;

// REDUCER HELPERS

function receiveBillingData(state, action) {
  return {
    ...state,
    billing: action.payload.billing,
    loading: false,
  };
}

function receivePaymentData(state, action) {
  return {
    ...state,
    payment: action.payload.payment,
  };
}

// THUNKS -- ASYNC ACTION CREATORS

export function fetchBillingProducts() { // returns an array of Billing Plans to choose from
  return (dispatch) =>
    axios.get('/billing/products')
      .then((response) => dispatch(receiveBillingProducts({ products: response.data })))
      .catch((err) => console.error(err));
}

function receiveBillingSubscriptionPayload(subscriptionResponse) {
  return (dispatch) => dispatch(receiveSubscription(subscriptionResponse));
}

function receiveBillingStatementsPayload(statementResponse) {
  return (dispatch) => {
    if (statementResponse.data.count) {
      dispatch(receiveTotalStatements({ totalStatements: statementResponse.data.count }));
    }
    dispatch(receiveBillingStatements({ statements: statementResponse.data.statements }));
  };
}

export function fetchOrganizationBillingView(billManagerSubscriptionId, billManagerCustomerId, billingPaymentId) {
  return (dispatch) => {
    const promises = [fetchSubscriptionData(billManagerSubscriptionId), fetchBillingStatementsBySubscriptionId(billManagerCustomerId), fetchInvoiceId()];
    if (exists(billingPaymentId)) { // If this payment id exists, only then we will call this api
      promises.push(fetchBillingPayment());
    }
    return axios.all(promises)
      .then(axios.spread((subscriptionResponse, statementResponse, invoiceResponse, billingResponse) => {
        dispatch(receiveBillingSubscriptionPayload({ subscription: subscriptionResponse.data }));
        dispatch(receiveBillingStatementsPayload(statementResponse));
        if (exists(billingPaymentId)) {
          dispatch(receiveBillingPayment({ payment: billingResponse.data }));
        }
        dispatch(receiveDraftInvoiceId({ draftInvoiceId: invoiceResponse.data }));
      }))
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(receiveBillingErrors({ billingError: true })); // when fusebill fails.
        console.error(err);
      });
  };
}

export function fetchBilling() { // returns entire billing object, with billing contact information, but no payment information
  return (dispatch) =>
    axios.get('/billing')
      .then((response) => dispatch(receiveBilling({ billing: response.data })))
      .catch((err) => console.error(err));
}

export function fetchBillingStatements(subscriptionId, pageNo = 0) {
  return (dispatch) =>
    fetchBillingStatementsBySubscriptionId(subscriptionId, pageNo)
      .then((response) => {
        dispatch(receiveBillingStatementsPayload(response));
      })
      .catch((err) => console.error(err));
}

export function fetchBillingAndSubscriptionPlan() {
  return (dispatch) => {
    dispatch(requestData(false));
    dispatch(setLoading(true));
    axios.get('/billing')
      .then((response) => {
        if (response && response.data && response.data.billManagerSubId) {
          const subscriptionId = response.data.billManagerSubId;
          axios.get(`/billing/subscription/${subscriptionId}`)
            .then((subResponse) => {
              dispatch(receiveSubscriptionAndBilling({ subscription: subResponse.data, billing: response.data }));
              dispatch(requestData(true));
              dispatch(setLoading(false));
            })
            .catch((err) => {
              dispatch(setLoading(false));
              dispatch(receiveBillingErrors({ billingError: true })); // when fusebill fails.
              console.error(err);
            });
        } else {
          dispatch(setLoading(false));
          dispatch(requestData(true));
        }
      })
      .catch((err) => console.error(err));
  };
}
export function fetchBillingCoupons() {
  return (dispatch) =>
    axios.get('/billing/coupons')
      .then((response) => dispatch(receiveBillingCoupons({ coupons: response.data })))
      .catch((err) => console.error(err));
}

export function updatePayment(payload) {
  return (dispatch) =>
    axios.patch('/billing/paymentprofile', payload)
      .then((response) => {
        dispatch(receiveUpdatePaymentProfile({ payment: response.data }));

        NotificationService('updateBillingPayment', response);

        dispatch(setError(null));
      })
      .catch((err) => {
        console.error(err.response || err);
        if (err?.response?.status === 403) {
          dispatch(invalidCaptcha({ isValidCaptcha: false, errorMessage: err.response.data?.message }));
        }
        dispatch(setError(err.response || err));

        NotificationService('updateBillingPayment', err.response);
      });
}

export function updateBillingContact(custId, payload) {
  return (dispatch) =>
    axios.patch(`/billing/customer/${custId}`, payload)
      .then((response) => {
        dispatch(receiveUpdateBillingContact({ billing: response.data }));

        NotificationService('updateBillingContact', response);

        dispatch(setError(null));
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));

        NotificationService('updateBillingContact', err.response);
      });
}

export function connectOrgWithCustId(custId) {
  return (dispatch) => {
    dispatch(setLoading(true));
    axios.post('/billing/connect', custId)
      .then((response) => {
        dispatch(receiveBilling({ billing: response.data }));

        NotificationService('connectOrgWithCustId', response);

        dispatch(setError(null));
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));

        dispatch(setLoading(false));
        NotificationService('connectOrgWithCustId', err.response);
      });
  };
}

export function updateCreditCard(payload) {
  return (dispatch) =>
    axios.patch('/billing/update/cc', payload)
      .then((response) => {
        dispatch(receiveUpdatePaymentProfile({ payment: response.data }));

        NotificationService('updateBillingPayment', response);

        dispatch(setError(null));
      })
      .catch((err) => {
        console.error(err.response || err);
        if (err?.response?.status === 403) {
          dispatch(invalidCaptcha({ isValidCaptcha: false, errorMessage: err.response.data?.message }));
        }

        dispatch(setError(err.response || err));

        NotificationService('updateBillingPayment', err.response);
      });
}

export function resetBillingPaymentHistory() {
  return (dispatch) => dispatch(resetStatements());
}

// AXIOS HELPERS

export function getBilling() { // returns entire billing object, with billing contact information, but no payment information
  return axios.get('/billing')
    .catch((err) => console.error(err));
}

export function fetchInvoiceId() {
  return axios.get('/billing/draft_invoice')
    .catch((err) => console.error(err));
}

export function fetchSubscriptionData(subscriptionId) { // returns an array of Billing Plans to choose from
  return axios.get(`/billing/subscription/${subscriptionId}`)
    .catch((err) => console.error(err));
}

export function fetchBillingStatementsBySubscriptionId(subscriptionId, pageNo = 0) {
  return axios.get(`billing/statements/${subscriptionId}/${pageNo}/${PAGE_SIZE}`)
    .catch((err) => console.error(err));
}

export function fetchBillingPayment() { // returns payment information not provided in billing object
  return axios.get('/billing/payment')
    .catch((err) => console.error(err));
}
