import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEqual from 'lodash.isequal';

import { userHasAnyOfPermissions, userHasAllOfPermissions } from '../helpers/UserHelpers';
import { THREAD_VIEW, CONVERSATION_CONTACT_MOBILE, RHINOFORMS_ADMIN } from '../constants/UserPermissionsConstants';
import * as UserReducer from '../reducers/userReducer';
import { fetchBillingAndSubscriptionPlan } from '../reducers/billingReducer';
import * as UploadActions from '../actions/UploadActions';
import * as AuthReducer from '../reducers/authReducer';
import * as AuditLogReducer from '../reducers/auditLogReducer';
import OrganizationMemberForm from '../components/OrganizationMemberForm';
import unsavedChanges from '../components/UnsavedChangesHOC';
import { ValidationService, ValidationShapers } from '../services/ValidationService';
import { UserHelpers, ValidationHelpers, AddonHelpers, RoleHelpers, DataHelpers } from '../helpers';
import { Types, AppConstants } from '../constants';
import { getLoggedInUser } from '../selectors/userSelectors';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import CustomError from '../helpers/CustomErrorHelper';
import { userSelectors } from '../selectors';
import PageLoader from '../components/PageLoader';
import { isMobile } from '../helpers/BrowserHelpers';
import StorageService from '../services/StorageService';

const { REACT_APP_AVATAR_BUCKET: AVATAR_BUCKET } = process.env;

class OrganizationMemberFormContainer extends React.Component {
  state = {
    afterHoursEnabled: false,
    autoResponse: '',
    businessHours: [],
    businessTitle: '',
    defaultChannelEnabled: false,
    defaultChannelId: -1,
    errors: {},
    fee: 0,
    firstName: '',
    id: -1,
    isCreateMember: true,
    isInviteSent: false, // default, add only without invite
    isMemberAddAndInviteInProgress: false,
    isMemberAddInProgress: false,
    isMemberProfileUpdateInProgress: false,
    isMFAEnabled: false,
    isMFALoading: false,
    isMFARequired: false,
    isPasswordFormActive: false,
    isWarning: false,
    lastName: '',
    loginEmail: '',
    middleName: '',
    mode: 'create',
    observesDst: false,
    pageLoading: true,
    preferredName: '',
    prefixId: -1,
    profileImageUrl: '',
    selectedChannelIds: [],
    selectedCheckBox: false,
    selectedGroupIds: [],
    selectedRoles: [],
    selectedTagIds: [],
    selectedUsers: [],
    showRhinoformSeatsValidationModal: false,
    suffixId: -1,
    timeZoneId: null,
    uploadAvatarModalOpen: false,
    viewAllGroups: true,
  };

  componentDidMount() {
    const profileUserId = this.getProfileUserId();
    this.props.fetchFilterData('', 'members');
    if (this.props.location.pathname === '/settings/organization/members/create') {
      this.props.fetchBillingAndSubscriptionPlan();
      this.props.fetchNewMemberProfileFormView();
    } else {
      this.props.fetchMemberProfileFormView(profileUserId);
    }
    const activeUser = this.props.users[profileUserId];

    if (activeUser) {
      const {
        afterHours,
        defaultChannelId,
      } = activeUser;

      this.setState({
        afterHoursEnabled: !!afterHours,
        defaultChannelEnabled: !!defaultChannelId,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const activeUser = this.props.users[this.getProfileUserId()];
    const updateMode = activeUser;
    const fee = AddonHelpers.getAddonFee(0, this.props, AppConstants.MEMBER_PRODUCT);
    // eslint-disable-next-line no-unused-vars
    let updatedState = { ...fee };
    if (updateMode) updatedState = { ...updatedState, selectedChannelIds: activeUser.routedChannels };
    if (updateMode && this.state.id === -1) {
      const {
        autoResponse,
        businessHours,
        businessTitle,
        defaultChannelId,
        firstName,
        groups,
        id,
        isInviteSent,
        isMFAEnabled,
        isMFARequired,
        lastName,
        loginEmail,
        middleName,
        observesDst,
        preferredName,
        prefixId,
        profileImageUrl,
        roles,
        routedChannels,
        suffixId,
        tags,
        timeZoneId,
      } = activeUser;

      this.setState({ // eslint-disable-line react/no-did-update-set-state
        autoResponse: autoResponse || '',
        businessHours,
        businessTitle,
        defaultChannelEnabled: !!defaultChannelId,
        defaultChannelId: defaultChannelId || -1,
        errors: {},
        firstName,
        id,
        isInviteSent,
        isMFAEnabled,
        isMFARequired,
        lastName,
        loginEmail,
        middleName,
        mode: 'update',
        myProfile: this.isMyProfile(),
        observesDst: !!observesDst,
        preferredName,
        prefixId: prefixId || -1,
        profileImageUrl,
        selectedChannelIds: routedChannels,
        selectedGroupIds: groups,
        selectedRoles: roles,
        selectedTagIds: tags,
        suffixId: suffixId || -1,
        timeZoneId,
        viewAllGroups: false,
      });
    }
    if (prevState.selectedCheckBox !== this.state.selectedCheckBox) {
      const selectedRoles = this.state.selectedRoles.filter((permission) => permission.name !== 'RhinoForms Admin');
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selectedRoles, selectedCheckBox: false });
    }
    if (this.props.pageLoading !== prevProps.pageLoading) {
      this.setState({ ...updatedState, pageLoading: this.props.pageLoading }); // eslint-disable-line react/no-did-update-set-state
    } else if (this.props.billingLoading !== prevProps.billingLoading) {
      this.setState({ ...updatedState }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  get payload() {
    const payload = {
      afterHours: this.state.afterHoursEnabled,
      autoResponse: this.state.autoResponse,
      businessHours: this.state.businessHours,
      businessTitle: this.state.businessTitle,
      defaultChannelId: this.state.defaultChannelId,
      firstName: this.state.firstName,
      groupIds: this.state.selectedGroupIds,
      id: this.state.id,
      isInviteSent: this.state.isInviteSent,
      isMFAEnabled: this.state.isMFAEnabled,
      lastName: this.state.lastName,
      loginEmail: this.state.loginEmail,
      middleName: this.state.middleName,
      observesDst: this.state.observesDst,
      preferredName: this.state.preferredName,
      prefixId: this.state.prefixId,
      profileImageUrl: this.state.profileImageUrl,
      roles: this.state.selectedRoles,
      routedChannels: this.state.selectedChannelIds,
      suffixId: this.state.suffixId,
      tagIds: this.state.selectedTagIds,
      ...this.state.afterHoursEnabled && this.state.timeZoneId > -1 && { timeZoneId: this.state.timeZoneId },
      typeId: Types.TYPE_MEMBER,
    };

    if (payload.suffixId === -1) payload.suffixId = '';
    if (payload.prefixId === -1) payload.prefixId = '';
    if (payload.defaultChannelId === -1) payload.defaultChannelId = '';

    return payload;
  }

  getTitles = () => {
    if (this.state.myProfile) {
      return {
        actionTitle: 'Save Profile',
        pageTitle: 'My Profile',
        actionType: 'primary',
      };
    } else if (this.state.mode === 'update') {
      return {
        actionTitle: 'Update Member',
        pageTitle: 'Edit Member',
        actionType: 'primary',
      };
    }
    return {
      actionTitle: 'Add Member',
      addInviteActionTitle: 'Add & Invite Member',
      pageTitle: 'Create Member',
      actionType: 'secondary',
    };
  }

  getProfileUserId = () => (this.isMyProfile() ? this.props.user.id : this.props.match.params.memberId);

  isMyProfile = () => this.props.location.pathname === '/settings/profile';

  handleChange = (name, value) => {
    const updatedState = { [name]: value };
    this.setState(updatedState);
  }

  handleDefaultChannelChange = (name, value) => {
    if (!value && DataHelpers.exists(this.props.users[this.state.id])) {
      this.setState({ defaultChannelId: undefined });
    }
    this.setState({ [name]: value });
  }

  handleAfterHoursChange = (name, value) => {
    if (!value && DataHelpers.exists(this.props.users[this.state.id])) {
      const { businessHours } = this.props.users[this.state.id];
      this.setState({ businessHours });
    }
    this.setState({ [name]: value });
  }

  handleSubmit = (isInviteSent) => {
    const errors = ValidationService(ValidationShapers.shapeMemberProfile(this.payload));
    const errorCount = Object.keys(errors).length;
    this.setState({ errors });
    if (errorCount > 0) {
      ValidationHelpers.handleValidationErrors(errors, this.pageContainer);
    } else if (this.state.mode === 'update') {
      this.setState({ isMemberProfileUpdateInProgress: true });
      this.props.updateUser(this.payload.id, this.payload, true, this.props.currentUser.id, (isMobile() ?
        userHasAllOfPermissions([THREAD_VIEW, CONVERSATION_CONTACT_MOBILE]) :
        userHasAnyOfPermissions([THREAD_VIEW])))
        .then(() => {
          this.setState({ isMemberProfileUpdateInProgress: false });
          if (this.props.error && this.props.error.data) {
            this.setState({
              errors: ValidationHelpers.handleServerError(this.props.error.data),
            }, () => {
              ValidationHelpers.handleValidationErrors(this.state.errors, this.pageContainer);
            });
          } else {
            this.props.resetFormChanges();
            if (!this.state.myProfile) this.props.history.push(`/settings/organization/members/${this.payload.id}`);
          }
        });
    } else {
      let addMemberLoader;

      if (isInviteSent) {
        addMemberLoader = 'isMemberAddAndInviteInProgress';
      } else if (!isInviteSent) {
        addMemberLoader = 'isMemberAddInProgress';
      }
      this.setState({ [addMemberLoader]: true });
      // use for add / add & invite buttons
      const updatedPayloadWithInvitation = { ...this.payload, isInviteSent };
      this.props.createUser(updatedPayloadWithInvitation)
        .then((response) => {
          this.setState({ [addMemberLoader]: false });
          if (this.props.error && this.props.error.data) {
            this.setState({
              errors: ValidationHelpers.handleServerError(this.props.error.data),
            }, () => {
              ValidationHelpers.handleValidationErrors(this.state.errors, this.pageContainer);
            });
          } else {
            this.props.resetFormChanges();

            this.props.history.push(`/settings/organization/members/${response.payload.userId}`);
          }
        });
    }
  }

  handleToggleMFA = () => {
    this.setState({ isMFALoading: true });

    if (this.state.isMFAEnabled) {
      this.props.disableMFA(this.state.id)
        .then(() => {
          this.setState({ isMFAEnabled: false, isMFALoading: false });
        });
    } else {
      this.props.enrollMFA(this.state.id)
        .then(() => {
          this.setState({ isMFAEnabled: true, isMFALoading: false });
        });
    }
  }

  handleEnrollMFA = () => {
    this.setState({ isMFALoading: true });
    this.props.enrollMFA(this.state.id)
      .then(() => {
        this.setState({ isMFAEnabled: true, isMFALoading: false });
      });
  }

  handleResetMFA = () => {
    this.setState({ isMFALoading: true });
    this.props.resetMFA(this.state.id)
      .then(() => {
        this.setState({ isMFAEnabled: false, isMFALoading: false });
      });
  }

  handleToggle = (name) => {
    this.setState({ [name]: !this.state[name] });
  }

  handleUpdateSelectedIds = (name, id) => {
    let selectedIds = [...this.state[name]];

    const addAction = !selectedIds.includes(id);

    if (addAction) {
      selectedIds = selectedIds.concat(id);
    } else {
      selectedIds = selectedIds.filter((selectedId) => selectedId !== id);
    }

    this.setState({ [name]: selectedIds }, () => this.props.handleFormChanges());
  }

  isModalDisplay = () => {
    this.setState({
      showRhinoformSeatsValidationModal: true,
    });
  }
  handleToggleModal = () => {
    this.setState({
      showRhinoformSeatsValidationModal: false,
    });
  }

  activeUsersIds = (selectedIds) => selectedIds?.filter((userId) => this.props.members[userId]?.active === true);
  /**
   * Take a single role object as input, and add/remove said role from the array of selected roles for a user.
   * Does not return a value, just calls setState with updated array of selectedRoles.
   * @param {Object} role
   * @return {void}
   */
  // eslint-disable-next-line consistent-return
  handleUpdateSelectedRoles = (role) => {
    // Since role comes in from organization it includes permissions that we need to strip away in order to compare it
    // With the user role, which is just includes the basic role properties.
    const roleNoPermissions = {
      id: role.id,
      name: role.name,
      description: role.description,
      systemRole: role.systemRole,
    };
    const { users } = role;
    const RhinoformExistingUsers = this.activeUsersIds(users);
    let selectedRoles = [...this.state.selectedRoles];
    let { selectedCheckBox } = this.state;
    const isRoleInSelectedRoles = selectedRoles.filter((selectedRole) => isEqual(selectedRole, roleNoPermissions)).length > 0;
    const isCurrentMemberPresent = RhinoformExistingUsers.includes(Number(this.getProfileUserId()));
    if (!isRoleInSelectedRoles) {
      // eslint-disable-next-line max-len
      if (roleNoPermissions.name === RHINOFORMS_ADMIN && (RhinoformExistingUsers.length === this.props.seatsLimit) && !isCurrentMemberPresent) {
        this.isModalDisplay();
        selectedCheckBox = true;
      }
      selectedRoles.push(roleNoPermissions);
    } else {
      selectedRoles = selectedRoles.filter((selectedRole) => !isEqual(selectedRole, roleNoPermissions));
      selectedCheckBox = false;
    }
    this.setState({ selectedRoles, selectedCheckBox }, () => this.props.handleFormChanges());
  }

  handleUploadAvatar = (image, sourceFilename) => {
    UploadActions.uploadFileFromDataUrl(image, AVATAR_BUCKET, sourceFilename)
      .then((response) => {
        this.setState({
          profileImageUrl: response.file,
        });
      })
      .finally(() => {
        this.setState({
          uploadAvatarModalOpen: false,
        });
      });
  }

  handlePasswordFormToggle = (isPasswordFormActive) => {
    this.setState({ isPasswordFormActive });
  }

  isLoading = () => this.state.pageLoading || (Object.keys(this.props.subscription).length !== 0 ? !this.props.subscriptionLoading : false) || this.props.billingLoading;

  render() {
    if (this.props.pageLoading) {
      return <PageLoader />;
    }

    if (this.props.billingError) {
      throw new CustomError({ type: 'Billing API', message: AppConstants.BILLING_ERROR_MESSAGE });
    }

    const props = {
      ...this.getTitles(),
      afterHoursEnabled: this.state.afterHoursEnabled,
      autoResponse: this.state.autoResponse,
      avatarName: UserHelpers.formatAvatarName(this.state.firstName, this.state.lastName),
      businessHours: this.state.businessHours,
      businessTitle: this.state.businessTitle,
      channelIds: this.props.channelIds,
      channelOptions: [{ id: -1, value: '--' }].concat(this.props.channelIds.filter((id) => {
        const channel = this.props.channels[id];
        return !channel?.deleted && [Types.TYPE_SMS, Types.TYPE_TWILIO].includes(channel.typeId);
      }).map((id) => ({ id, value: this.props.channels[id]?.name }))),
      channels: this.props.channels,
      defaultChannelEnabled: this.state.defaultChannelEnabled,
      defaultChannelId: this.state.defaultChannelId,
      errors: this.state.errors,
      firstName: this.state.firstName,
      groupIds: this.props.groupIds,
      groups: this.props.groups,
      handleAfterHoursChange: this.handleAfterHoursChange,
      handleChange: this.handleChange,
      handleDefaultChannelChange: this.handleDefaultChannelChange,
      handleEnrollMFA: this.handleEnrollMFA,
      handleFormChanges: this.props.handleFormChanges,
      handlePasswordFormToggle: this.handlePasswordFormToggle,
      handleResetMFA: this.handleResetMFA,
      handleSubmit: this.handleSubmit,
      handleToggle: this.handleToggle,
      handleToggleMFA: this.handleToggleMFA,
      handleToggleModal: this.handleToggleModal,
      handleUpdateSelectedIds: this.handleUpdateSelectedIds,
      handleUpdateSelectedRoles: this.handleUpdateSelectedRoles,
      handleUploadAvatar: this.handleUploadAvatar,
      isCcr: this.props.user.isCcr,
      isMemberAddAndInviteInProgress: this.state.isMemberAddAndInviteInProgress,
      isMemberAddInProgress: this.state.isMemberAddInProgress,
      isMemberProfileUpdateInProgress: this.state.isMemberProfileUpdateInProgress,
      isMFAEnabled: this.state.isMFAEnabled,
      isMFALoading: this.state.isMFALoading,
      isMFARequired: this.props.currentOrganization.isMFARequired,
      isMultiOrgEnabled: StorageService.readEntry('multiOrgs'),
      isPasswordFormActive: this.state.isPasswordFormActive,
      isRhinoformEnabled: this.props.isRhinoformEnabled,
      isSSOEnabled: this.props.currentOrganization.isSSOEnabled,
      isSSORolesEnabled: this.props.currentOrganization.isSSORolesEnabled,
      isWarning: this.state.isWarning,
      lastName: this.state.lastName,
      loginEmail: this.state.loginEmail,
      memberRoles: this.state.selectedRoles,
      middleName: this.state.middleName,
      mode: this.state.mode,
      myProfile: this.state.myProfile,
      observesDst: this.state.observesDst,
      organizationRoles: this.props.organizationRoles,
      pageContainerRef: (pageContainer) => (this.pageContainer = pageContainer),
      pageLoading: this.isLoading(),
      perMemberFee: this.state.fee,
      phones: this.props.phones,
      preferredName: this.state.preferredName,
      prefixId: this.state.prefixId,
      prefixOpts: [{ id: -1, value: '--' }].concat(this.props.prefixIds.map((id) => this.props.prefixes[id])),
      profileImageUrl: this.state.profileImageUrl ? `${AppConstants.AVATAR_BASE_URL}${this.state.profileImageUrl}` : '',
      resetFormChanges: this.props.resetFormChanges,
      routedChannelsAreVisible: this.state.mode === 'update',
      selectedChannelIds: this.state.selectedChannelIds,
      selectedGroupIds: [...this.state.selectedGroupIds].sort(DataHelpers.sortBy(this.props.groups, 'name')),
      selectedTagIds: this.state.selectedTagIds,
      showRhinoformSeatsValidationModal: this.state.showRhinoformSeatsValidationModal,
      suffixId: this.state.suffixId,
      suffixOpts: [{ id: -1, value: '--' }].concat(this.props.suffixIds.map((id) => this.props.suffixes[id])),
      tagIds: this.props.tagIds,
      tags: this.props.tags,
      timeZoneId: this.state.timeZoneId,
      timeZones: this.props.timeZones,
      uploadAvatarModalOpen: this.state.uploadAvatarModalOpen,
      userId: this.state.id,
      viewAllGroups: this.state.viewAllGroups,
      isEditIntegratedContactEnabled: this.props.currentOrganization.isEditIntegratedContactEnabled,
      integrationPartnerTypeId: this.props.currentOrganization.integrationPartnerTypeId,
    };

    return <OrganizationMemberForm {...props} />;
  }
}

OrganizationMemberFormContainer.propTypes = {
  billing: PropTypes.object,
  billingError: PropTypes.bool,
  billingLoading: PropTypes.bool,
  channelIds: PropTypes.array.isRequired,
  channels: PropTypes.object.isRequired,
  createUser: PropTypes.func.isRequired,
  currentOrganization: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  disableMFA: PropTypes.func.isRequired,
  enrollMFA: PropTypes.func.isRequired,
  error: PropTypes.object,
  fetchBillingAndSubscriptionPlan: PropTypes.func.isRequired,
  fetchFilterData: PropTypes.func.isRequired,
  fetchMemberProfileFormView: PropTypes.func.isRequired,
  fetchNewMemberProfileFormView: PropTypes.func.isRequired,
  groupIds: PropTypes.array.isRequired,
  groups: PropTypes.object.isRequired,
  handleFormChanges: PropTypes.func.isRequired,
  history: PropTypes.object,
  isRhinoformEnabled: PropTypes.bool,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  members: PropTypes.object,
  organizationRoles: PropTypes.array.isRequired,
  pageLoading: PropTypes.bool.isRequired,
  phones: PropTypes.object.isRequired,
  prefixes: PropTypes.object.isRequired,
  prefixIds: PropTypes.array.isRequired,
  resetFormChanges: PropTypes.func.isRequired,
  resetMFA: PropTypes.func,
  seatsLimit: PropTypes.number,
  subscription: PropTypes.object,
  subscriptionLoading: PropTypes.bool.isRequired,
  suffixes: PropTypes.object.isRequired,
  suffixIds: PropTypes.array.isRequired,
  tagIds: PropTypes.array.isRequired,
  tags: PropTypes.object.isRequired,
  timeZones: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  isEditIntegratedContactEnabled: PropTypes.bool,
  integrationPartnerTypeId: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { auditLog, channel, group, tag, ui, user, timeZone, phone, prefix, suffix, billing, role, form } = state;
  const currentOrganization = { ...getCurrentOrg(state) };
  const userWithRoles = { ...getLoggedInUser(state) };
  return {
    billing: billing.billing,
    billingError: billing.billingError,
    billingLoading: billing.loading,
    channelIds: channel.channelIds,
    channels: channel.channels,
    currentOrganization,
    error: ui.error,
    groupIds: [...group.groupIds].sort(DataHelpers.sortBy(group.groups, 'name')),
    groups: group.groups,
    isRhinoformEnabled: form.org.isRhinoformEnabled,
    members: auditLog.members,
    organizationRoles: [...RoleHelpers.shapeRolePermissions(role.systemRoles), ...RoleHelpers.shapeRolePermissions(role.customRoles)],
    pageLoading: user.loading,
    phones: phone.phones,
    prefixes: prefix.prefixes,
    prefixIds: prefix.prefixIds,
    productCount: userSelectors.getActiveMemberIds(state).length,
    seatsLimit: form.org.seatsLimit,
    subscription: billing.subscription,
    subscriptionLoading: billing.subscriptionLoading,
    suffixes: suffix.suffixes,
    suffixIds: suffix.suffixIds,
    tagIds: tag.tagIds,
    tags: tag.tags,
    timeZones: timeZone.timeZones,
    user: userWithRoles,
    users: user.users,
  };
};

const actions = {
  createUser: UserReducer.createUser,
  disableMFA: AuthReducer.disableMFA,
  enrollMFA: AuthReducer.enrollMFA,
  fetchBillingAndSubscriptionPlan,
  fetchFilterData: AuditLogReducer.fetchFilterData,
  fetchMemberProfileFormView: UserReducer.fetchMemberProfileFormView,
  fetchMembers: UserReducer.fetchMembers,
  fetchNewMemberProfileFormView: UserReducer.fetchNewMemberProfileFormView,
  resetMFA: AuthReducer.resetMFA,
  updateUser: UserReducer.updateUser,
};

export default connect(mapStateToProps, actions)(unsavedChanges(OrganizationMemberFormContainer));
