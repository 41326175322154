import React from 'react';
import { connect } from 'react-redux';
import RhinoformView from '../components/RhinoformView';
import * as FormReducer from '../reducers/formReducer';
import { ValidationService, ValidationShapers } from '../services/ValidationService';
import { ValidationHelpers, AttachmentHelpers } from '../helpers';
import { getCurrentOrg } from '../selectors/organizationSelectors';

class RhinoformViewContainer extends React.Component {
  state = {
    activeKey: 1,
    formType: null,
    submissionType: null,
    formName: '',
    errors: {},
    scratchRhinoform: null,
    iFrameLoading: false,
    pdfFileName: '',
    pdfFile: null,
    updatingForm: false,
    isFormStateUpdated: false,
    isEditMode: false,
    open: false,
    formId: '', // eslint-disable-line react/no-unused-state
    fileInputKey: '',
    fileProgressText: '0% Uploaded',
    activeUpload: false,
    autoSendToEHR: false,
  };

  componentDidMount() {
    this.setState({
      open: true,
    });
  }

  static getDerivedStateFromProps(props, state) {
    const newState = {};
    if (props.match.params.id && props.match.params.id !== state.formId) {
      newState.formId = props.match.params.id;
      newState.activeKey = 2;
      newState.isEditMode = true;
      newState.iFrameLoading = true;

      const payLoad = {
        formId: props.match.params.id,
        edit: false,
      };
      const isFormEditableMode = true;
      props.getAuthenticateOrgUser(payLoad, '', '', '', isFormEditableMode);
      return newState;
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.formExternalId !== this.props.formExternalId && this.state.isEditMode) {
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        formName: this.props.formTitle,
        formType: this.props.isPdf ? 2 : 1,
        submissionType: this.props.enableSmartPdf ? 1 : 2,
        isFormStateUpdated: true,
        scratchRhinoform: !this.props.isPdf,
        pdfFileName: this.props.pdfFileName,
        autoSendToEHR: this.props.autoSendToEHR,
      });
    }

    if ((prevProps?.formUploadPostProcessing.length !== this.props?.formUploadPostProcessing.length) &&
      !this.props?.formUploadPostProcessing?.includes(this.state.fileInputKey)) {
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        activeKey: 2,
        iFrameLoading: true,
        isFormStateUpdated: true,
      });
    }
  }

  updateActiveKey = (index) => {
    if ((this.props.rhinoformUrl && !this.props.isFormLoading)) {
      this.setState({
        activeKey: index,
      });
    }
  };

  handleTypeChange = (typeId) => {
    this.setState({
      formType: typeId,
      formName: '',
      pdfFileName: '',
      errors: {},
    });
  }

  handleSubmissionTypeChange = (submissionTypeId) => {
    this.setState({
      submissionType: submissionTypeId,
    });
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  }

  getFormData = (file, fileName, formExternalId) => {
    const formData = new FormData();
    formData.append('pdf', file, fileName);
    formData.append('title', this.state.formName);
    formData.append('isPdf', 'true');
    if (formExternalId) {
      formData.append('formExternalId', formExternalId);
    }
    return formData;
  }

  handleUploadComplete = (newState) => {
    this.setState({
      activeUpload: false,
      fileInputKey: newState.attachments[0].key,
      pdfFileName: newState.attachments[0].name,
      processingText: 'Processing file...',
    });
    setTimeout(() => {
      this.setState({ processingText: 'Still processing, please wait...' });
    }, 10000);
    this.props.uploadPresignPDF({ ...newState.attachments[0], title: this.state.formName });
    this.props.authenticatePage();
  };

  handleAddFile = (event) => {
    this.setState({ activeUpload: true });
    const handleProgressDisplay = (progressText) => {
      this.setState({ fileProgressText: progressText });
    };

    const uploadOpts = {
      file: event.target.files[0],
      bucket: process.env.REACT_APP_PUBLIC_TEMPLATES_BUCKET,
      progressCallback: handleProgressDisplay,
    };

    AttachmentHelpers.handleAddFile(uploadOpts, this.handleUploadComplete);
  };

  toggleRhinoFormCover = (open) => {
    this.setState({ open });
  }

  handleUpdateForm = (type) => {
    const { formExternalId } = this.props;
    const { submissionType } = this.state;
    this.handleformConfiguration(type, '', '', formExternalId, '', 'update', submissionType);
    this.setState({
      iFrameLoading: false,
      updatingForm: true,
    });
  }

  handleResetForm = () => {
    const payLoad = {
      formExternalId: this.props.formExternalId,
    };

    if (this.props.rhinoformUrl) {
      this.props.deleteRhinoform(payLoad);
    }
    this.setState({
      formType: null,
      submissionType: null,
      formName: '',
      pdfFileName: '',
      errors: {},
      isFormStateUpdated: false,
    });
  }

  handleformConfiguration = (type, formData, config, formExternalId, formState, isUpdate, submissionType) => {
    const isPdf = type !== 1;
    const errors = ValidationService(ValidationShapers.shapeRhinoform(this.state.formName));
    const errorCount = Object.keys(errors).length;
    this.setState({
      errors,
      scratchRhinoform: !isPdf,
    });
    if (errorCount > 0) {
      ValidationHelpers.handleValidationErrors(errors, this.pageContainer);
    } else {
      const payLoad = {
        title: this.state.formName,
        isPdf,
      };
      const isFormEditableMode = false;
      if (isUpdate) {
        payLoad.formExternalId = formExternalId;
        payLoad.formId = this.props.formId;
        payLoad.favorites = this.props.favorites;
        payLoad.version = this.props.version;
        payLoad.edit = false;
        payLoad.enableSmartPdf = submissionType === 1;
        payLoad.autoSendToEHR = this.state.autoSendToEHR;
        this.props.buildRhinoform(payLoad, type, formData, config, isUpdate)
          .then(() => {
            this.updateFormState(formState);
          });
      } else {
        this.props.getAuthenticateOrgUser(payLoad, type, formData, config, isFormEditableMode)
          .then(() => {
            this.updateFormState(formState);
          });
      }
    }
  }

  updateFormState = (formState) => {
    if (this.props.rhinoformUrl) {
      this.setState({
        activeKey: 2,
        iFrameLoading: true,
        isFormStateUpdated: true,
        ...formState,
      });
    }
  };

  handleCreateForm = (isPublished) => {
    const { rhinoformUrl, formUrl } = this.props;
    const { autoSendToEHR } = this.state;
    const isPdf = this.state.formType !== 1;
    const enableSmartPdf = this.state.submissionType === 1;

    const payload = {
      isPublished,
      iFrameUrl: rhinoformUrl,
      formUrl,
      isPdf,
      title: this.state.formName,
      formExternalId: this.props.formExternalId,
      pdfFileName: this.state.pdfFileName,
      enableSmartPdf,
      autoSendToEHR,
    };

    if (this.state.isEditMode) {
      payload.isPdf = this.props.isPdf;
      payload.formId = this.props.formId;
      payload.version = this.props.version;
      payload.isEditMode = this.state.isEditMode;
      payload.favorites = this.props.favorites;
      payload.sourceId = this.props.sourceId;
    }
    this.props.createRhinoForm(payload)
      .then(() => {
        this.toggleRhinoFormCover(false);
        this.props.history.push(`/settings/organization/library/forms/${this.props.formId}`);
        this.setState({
          formType: null,
          submissionType: null,
          formName: '',
          pdfFileName: '',
          activeKey: 1,
          errors: {},
          isFormStateUpdated: false,
        });
      });
  }

  closeCover = () => {
    let response = '';
    if ((this.state.formType || this.state.formName || this.state.submissionType)) {
      response = window.confirm( // eslint-disable-line no-alert
        "You've made changes to this page that haven't been saved.Are you sure you want to discard them?",
      );
    } else {
      this.toggleRhinoFormCover(false);
    }

    if (response) {
      const payLoad = {
        formExternalId: this.props.formExternalId,
      };
      if (this.props.rhinoformUrl) {
        this.props.deleteRhinoform(payLoad);
      }
      this.toggleRhinoFormCover(false);
      this.setState({
        formType: null,
        submissionType: null,
        formName: '',
        pdfFileName: '',
        activeKey: 1,
        isFormStateUpdated: false,
        errors: {},
      });
    }
    this.props.history.replace('/settings/organization/library/forms');
  };

  iframeLoaded = () => {
    this.setState({ iFrameLoading: false });
  }

  render() {
    const props = {
      openModal: this.state.open,
      closeCover: this.closeCover,
      activeKey: this.state.activeKey,
      updateActiveKey: this.updateActiveKey,
      handleTypeChange: this.handleTypeChange,
      handleSubmissionTypeChange: this.handleSubmissionTypeChange,
      formType: this.state.formType,
      submissionType: this.state.submissionType,
      handleformConfiguration: this.handleformConfiguration,
      rhinoformUrl: this.props.rhinoformUrl,
      handleChange: this.handleChange,
      errors: this.state.errors,
      pageContainerRef: (pageContainer) => (this.pageContainer = pageContainer),
      scratchRhinoform: this.state.scratchRhinoform,
      handleUpdateForm: this.handleUpdateForm,
      handleResetForm: this.handleResetForm,
      formName: this.state.formName,
      handleCreateForm: this.handleCreateForm,
      iframeLoaded: this.iframeLoaded,
      iFrameLoading: this.state.iFrameLoading,
      handleAddFile: this.handleAddFile,
      pdfFileName: this.state.pdfFileName,
      handlePdfForm: this.handlePdfForm,
      pdfFile: this.state.pdfFile,
      processingText: this.state.processingText,
      isFormLoading: this.props.isFormLoading,
      updatingForm: this.state.updatingForm,
      formUploadPostProcessing: this.props.formUploadPostProcessing,
      editFormName: this.state.editFormName,
      formTitle: this.props.formTitle,
      formSubmissionType: this.props.enableSmartPdf ? 1 : 2,
      isFormStateUpdated: this.state.isFormStateUpdated,
      isEditMode: this.state.isEditMode,
      isSaveFormLoading: this.props.isSaveFormLoading,
      isSaveDraftFormLoading: this.props.isSaveDraftFormLoading,
      fileInputKey: this.state.fileInputKey,
      fileProgressText: this.state.fileProgressText,
      activeUpload: this.state.activeUpload,
      autoSendToEHR: this.state.autoSendToEHR,
      formAutoSendToEHR: this.props.autoSendToEHR,
      currentOrganization: this.props.currentOrganization,
    };

    return (
      <RhinoformView {...props} />
    );
  }
}

const mapStateToProps = (state) => {
  const { form } = state;
  return {
    currentOrganization: getCurrentOrg(state),
    rhinoformUrl: form.form.iFrameUrl,
    formExternalId: form.form.formExternalId,
    isFormLoading: form.isFormLoading,
    isSaveDraftFormLoading: form.isSaveDraftFormLoading,
    isSaveFormLoading: form.isSaveFormLoading,
    formTitle: form.form.title,
    autoSendToEHR: form.form.autoSendToEHR,
    enableSmartPdf: form.form.enableSmartPdf,
    formId: form.form.formId,
    formUrl: form.form.formUrl,
    version: form.form.version,
    isPdf: form.form.isPdf,
    favorites: form.form.favorites,
    pdfFileName: form.form.pdfFileName,
    sourceId: form.form.sourceId,
    formUploadPostProcessing: form.formUploadPostProcessing,
  };
};

const actions = {
  getAuthenticateOrgUser: FormReducer.getAuthenticateOrgUser,
  authenticatePage: FormReducer.authenticatePage,
  deleteRhinoform: FormReducer.deleteRhinoform,
  createRhinoForm: FormReducer.createRhinoForm,
  buildRhinoform: FormReducer.buildRhinoform,
  fetchFormTemplates: FormReducer.fetchFormTemplates,
  uploadPresignPDF: FormReducer.uploadPresignPDF,
};

export default connect(mapStateToProps, actions)(RhinoformViewContainer);
