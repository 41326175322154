import { MessageStatus as MessageStatusEnum } from 'rhinotilities/lib/core/enums/MessageStatus';

export const EventModelType = {
  Message: 'RoutedMessage',
  Note: 'Note',
  SecureMessage: 'SecureMessage',
  DirectMessage: 'DirectMessage',
  HipaaConsent: 'HipaaConsent',
  AppointmentReminder: 'AppointmentReminder',
  AssignmentEvent: 'AssignmentEvent',
  RhinopayConsent: 'RhinopayConsent',
  SavedContent: 'SavedContent',
  MarketingConsent: 'MarketingConsent',
  VideoCall: 'VideoCall',
  SavedContentSent: 'SavedContentSent',
  PatientMerge: 'PatientMerge',
  CopyContent: 'CopyContent',
  Rhinoform: 'Rhinoform',
  AppointmentReminderNotification: 'AppointmentReminderNotification',
  AudioCall: 'AudioCall',
  PrescriptionReminder: 'PrescriptionReminder',
  HipaaRequest: 'HipaaRequest',
};

export const MessageStatus = {
  ...MessageStatusEnum,
  NetworkError: 'Network Error', // client only status
};

export const QUERY_STATUS_FULFILLED = 'fulfilled';
