import PropTypes from 'prop-types';
import React, { Fragment, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import {
  Close,
  Icon,
  Input,
  Scrollbars,
  LoaderPulse,
} from 'rhinostyle';
import { AppConstants } from '../constants';
import ThreadEvent from './ThreadEventSearch';
import * as InboxReducer from '../reducers/inboxReducer';
import { LocationHelpers, ScrollHelpers } from '../helpers';
import { setActivePanel } from '../reducers/threadReducer';
import { PANEL_OPTIONS } from '../constants/ThreadConstants';

const ThreadSearchPanel = (props) => {
  const {
    eventSearchIds,
    loading,
    focus,
    threadSearchPageNo,
    threadSelectedChannelIds,
    threadFilteredChannelIds,
    isActivePanel,
    endOfSearchResults,
  } = props;
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const searchInputRef = useRef();
  const searchBodyRef = useRef();
  const [searchText, setSearchText] = useState('');
  const currentChannelIds = threadSelectedChannelIds?.length > 0 ? threadSelectedChannelIds : threadFilteredChannelIds;
  const options = LocationHelpers.getInboxOptions(params, location);
  if (currentChannelIds.length > 0) options.channelIds = currentChannelIds;
  if (!isActivePanel) return null;

  const handleSearchTextChange = (id, value) => {
    if (value && value.length > 2) {
      dispatch(InboxReducer.fetchThreadSearch({ ...options, search: value }));
    } else {
      dispatch(InboxReducer.clearThreadSearch());
    }
    setSearchText(value);
  };

  const handleSearchTextClear = () => {
    setSearchText('');
    dispatch(InboxReducer.clearThreadSearch());
  };

  function onSearchScroll() {
    if (loading || endOfSearchResults) return;
    const scrollContainer = searchBodyRef?.current.container.firstChild;
    const lastEventId = eventSearchIds[eventSearchIds.length - 1];
    if (ScrollHelpers.isElementVisible(`#js-convo__item-${lastEventId}`, scrollContainer)) {
      options.search = searchText;
      options.pageNo = threadSearchPageNo + 1;
      const searchOptions = {
        ...options,
        pageNo: threadSearchPageNo + 1,
        search: searchText,
      };
      dispatch(InboxReducer.fetchThreadSearch(searchOptions));
    }
  }

  const renderSearchHelp = () => {
    if (loading) {
      return <LoaderPulse className="summary-panel__results__loader" type="secondary" />;
    } else if (searchText.length > 2 && eventSearchIds.length === 0 && !loading) {
      return <div className="search__no-results u-m-t-large">No matching search results.</div>;
    }
    return null;
  };

  const renderSearchResult = (id, key) => (
    <div key={key} className="search-panel__result">
      <ThreadEvent
        eventId={id}
        searchText={searchText}
        location={location}
      />
    </div>
  );

  return (
    <>
      <div className="summary-panel__wrapper app-panels--hide-icons-desktop">
        <div className="summary-panel">
          <div className="app-page__header">
            <div className="app-page__header__title">Search Conversation</div>
            <div className="app-page__header__action">
              <Close
                className="app-page__header__action__close"
                onClick={() => dispatch(setActivePanel(PANEL_OPTIONS.profile))}
              />
            </div>
          </div>
          <Scrollbars className="summary-panel__body" onScroll={onSearchScroll} ref={searchBodyRef}>
            <div className="summary-panel__content summary-panel__content--search">
              <Input
                placeholder="Search messages and notes"
                addon="left"
                size="large"
                initialValue={searchText}
                name="search"
                onChange={handleSearchTextChange}
                onClear={handleSearchTextClear}
                focus={focus}
                clear
                ref={searchInputRef}
              >
                <Icon icon="search" />
              </Input>
              {searchText && eventSearchIds.length > 0 && (
                <>
                  <h4>Search Results</h4>
                  <div className="search__group">
                    <div className="search__sub">
                      {eventSearchIds.length === AppConstants.EVENT_SIZE ? `${eventSearchIds.length}+ Results` : `${eventSearchIds.length} Results`}
                    </div>
                    {eventSearchIds.length > 0 && eventSearchIds.map(renderSearchResult)}
                  </div>
                </>
              )}
              {renderSearchHelp()}
            </div>
          </Scrollbars>
        </div>
      </div>
    </>
  );
};

ThreadSearchPanel.propTypes = {
  eventSearchIds: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  focus: PropTypes.bool,
  threadSearchPageNo: PropTypes.number,
  threadSelectedChannelIds: PropTypes.array,
  threadFilteredChannelIds: PropTypes.array,
  isActivePanel: PropTypes.bool,
  endOfSearchResults: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  threadActiveChannelIds: state.inbox.threadActiveChannelIds,
  focus: state.thread.focus === 'profile',
  eventSearchIds: state.inbox.eventSearchIds,
  loading: state.inbox.threadSearchLoading,
  threadSearchPageNo: state.inbox.threadSearchPageNo,
  threadSelectedChannelIds: state.inbox.threadSelectedChannelIds,
  threadFilteredChannelIds: state.inbox.threadFilteredChannelIds,
  endOfSearchResults: state.inbox.endOfSearchResults,
});

export default connect(mapStateToProps)(ThreadSearchPanel);
