import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  UtilityInlineGrid,
  Icon,
} from 'rhinostyle';

const HIPAAConsentMessagingModal = (props) => {
  const {
    handleSendHIPAAConsentMessage,
    isOpenHIPAAModal,
    handleCloseModal,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(isOpenHIPAAModal);
  }, [isOpenHIPAAModal]);

  return (
    <Modal open={isOpen} modalDialogClass="modal__dialog__warning">
      <ModalHeader onClose={handleCloseModal} />
      <ModalBody>
        <div className="u-text-small u-text-left">
          <Icon bump="up" icon="alert-triangle" className="inbox__item__body__failed-icon" />You cannot communicate with this contact until they have granted HIPAA consent. Click below to send the HIPAA consent request. Once HIPAA consent is granted, your administrator will update the Communication Consent status for this contact and text messaging will be enabled.
        </div>
      </ModalBody>
      <ModalFooter>
        <UtilityInlineGrid align="right">
          <Button type="link" onClick={handleCloseModal}>Cancel</Button>
          <Button type="primary" onClick={handleSendHIPAAConsentMessage}>Send HIPAA Consent Message</Button>
        </UtilityInlineGrid>
      </ModalFooter>
    </Modal>
  );
};

export default HIPAAConsentMessagingModal;
