import React, { useEffect, useState } from 'react';
import isEqual from 'lodash.isequal';
import moment from 'moment-timezone';
import {
  Scrollbars,
  SmartTable,
  Button,
  Checkbox,
} from 'rhinostyle';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NotificationService from '../services/NotificationService';
import EmptyMessage from '../components/EmptyMessage';
import PageLoader from '../components/PageLoader';
import { QUERY_STATUS_FULFILLED } from '../constants/v3';
import FilterPage from '../components/FilterPage';
import { usePrescriptions, useQueryParams } from '../hooks';
import SmartTableHeader from '../components/SmartTableHeader';
import { cloneDeep } from '../helpers/DataHelpers';
import { convertUnixTimestampMsToUTCDateString } from '../helpers/DateHelpers';
import { calculatePastDateRange } from '../helpers/AppointmentHelpers';
import PrescriptionFilters from '../components/PrescriptionFilters';
import { CHECKBOX_COLUMN_WIDTH } from '../constants/AppConstants';
import { getLoggedInUserOrganization, getLoggedInUser } from '../selectors/userSelectors';
import { hasBulkMessagePermission } from '../helpers/PermissionHelpers';
import BulkSelect from '../components/BulkSelect';
import { validateQueryStringValue, formatConsentStatus, PRESCRIPTION_HEADERS } from '../helpers/PrescriptionHelpers';

const PrescriptionManager = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const { id: currentOrganizationId, isBulkMessagingEnabled } = useSelector(getLoggedInUserOrganization);
  const { isCcr } = useSelector(getLoggedInUser);
  const defaultDate = calculatePastDateRange(6);

  const initialValue = {
    sortBy: 'createdAt',
    sortOrder: 'desc',
    pageNo: 0,
    consentStatusTypeIds: [],
    payors: [],
    prescriptionSearch: '',
    activeKey: 3,
    startDate: defaultDate.startDate,
    endDate: defaultDate.endDate,
  };
  const [headersConfig] = useState({ ...PRESCRIPTION_HEADERS });
  const [filters, onSetFilters] = useQueryParams({ initialValue, validateQueryStringValue });

  const {
    sortBy,
    sortOrder,
    pageNo,
    consentStatusTypeIds,
    payors,
    prescriptionSearch,
    activeKey,
    startDate,
    endDate,
  } = filters;

  const query = usePrescriptions({
    organizationId: currentOrganizationId,
    startDate: convertUnixTimestampMsToUTCDateString(startDate || initialValue.startDate, false),
    endDate: convertUnixTimestampMsToUTCDateString(endDate || initialValue.endDate, false),
    payorIds: payors,
    consentTypeIds: consentStatusTypeIds,
    sortBy,
    sortOrder,
    pageNo,
    activeKey,
    prescriptionSearch,
  });
  const { data, error, status } = query;
  const { total, results: prescriptions } = data || {};
  function handlePagination(type) {
    if ((type === 'previous' && pageNo > 0) || type === 'next') {
      onSetFilters({
        pageNo: type === 'previous' ? pageNo - 1 || 0 : pageNo + 1 || 0,
      });
    }
  }

  function handleClearAll() {
    onSetFilters(initialValue);
  }

  function getOpposite(sortDirection) {
    if (sortDirection === 'asc') return 'desc';
    return 'asc';
  }

  function handleSort(type) {
    onSetFilters(({
      sortBy: type,
      sortOrder: type === sortBy ? getOpposite(sortOrder) : 'desc',
      pageNo: 0,
    }));
  }
  useEffect(() => {
    if (status === QUERY_STATUS_FULFILLED) {
      setLoading(false);
    }
  }, [status]);

  useEffect(() => {
    if (error) {
      NotificationService('fetchRoutes', error);
    }
  }, [error]);

  if (loading) {
    return <PageLoader />;
  }

  function formatNames(names) {
    return names?.replaceAll('|', ', ') || '';
  }

  function formatFillType(isRefill) {
    return isRefill ? 'Refill' : 'New Fill';
  }

  // set contact list format
  function getContactList() {
    const uniquePatientIds = new Set(selectedItems.map((item) => item.patientId));
    const contactList = [...uniquePatientIds].map((id) => ({ id }));
    return contactList;
  }

  const maxColumnWidth = 300;
  const headers = cloneDeep(headersConfig);
  const headerClassName = 'u-p-a-0 u-flex-align-items-center u-flex u-p-t-small u-p-b-small u-m-t-0';

  const columns = [
    {
      Header: () => (
        <SmartTableHeader
          headerName="Prescription Date"
          sortKey="createdAt"
          headers={headers}
          className={headerClassName}
        />
      ),
      headerClassName,
      maxWidth: 220,
      accessor: 'createdAt',
      Cell: (row) => (
        <div className="row__text">{moment(row.value).format('MM/DD/YYYY')}</div>
      ),
      sortMethod: () => handleSort('createdAt'),
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="Prescription"
          sortKey="name"
          headers={headers}
          className={headerClassName}
        />
      ),
      headerClassName,
      accessor: 'name',
      maxWidth: maxColumnWidth,
      sortMethod: () => handleSort('name'),
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="Refill Date"
          sortKey="saleCompletedDate"
          headers={headers}
          className={headerClassName}
        />
      ),
      headerClassName,
      accessor: 'saleCompletedDate',
      maxWidth: 150,
      Cell: (row) => {
        const date = moment(row.value);
        if (date.isValid()) return (<div className="row__text">{date.format('MM/DD/YYYY')}</div>);
        return null;
      },
      sortMethod: () => handleSort('saleCompletedDate'),
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="Fill Type"
          sortKey="isRefill"
          headers={headers}
          className={headerClassName}
        />
      ),
      headerClassName,
      sortable: true,
      accessor: 'isRefill',
      sortMethod: () => handleSort('isRefill'),
      Cell: (row) => formatFillType(row.value),
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="Payor"
          sortKey="payorNames"
          headers={headers}
          className={headerClassName}
        />
      ),
      headerClassName,
      sortable: true,
      accessor: 'payorNames',
      sortMethod: () => handleSort('payorNames'),
      Cell: (row) => formatNames(row.value),
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="Bin"
          sortKey="payorBins"
          headers={headers}
          className={headerClassName}
        />
      ),
      headerClassName,
      sortable: true,
      accessor: 'payorBins',
      sortMethod: () => handleSort('payorBins'),
      Cell: (row) => formatNames(row.value),
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="Consent"
          sortKey="consentStatusTypeId"
          headers={headers}
          className={headerClassName}
        />
      ),
      headerClassName,
      sortable: true,
      accessor: 'consentStatusTypeId',
      sortMethod: () => handleSort('consentStatusTypeId'),
      maxWidth: 200,
      Cell: (row) => formatConsentStatus(row.value),
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="Contact"
          sortKey="lastName"
          headers={headers}
          className={headerClassName}
        />
      ),
      headerClassName,
      sortable: true,
      accessor: 'lastName',
      sortMethod: () => handleSort('lastName'),
      Cell: (row) => (
        <Button
          type="link"
          size="small"
          className="analytics__button-link u-text-primary u-p-l-0 u-p-r-0"
          onClick={() => history.push(`/inbox/all/user/${row.original.patientId}`)}
        >
          <span className="u-text-overflow">
            {`${row.original.firstName} ${row.original.lastName}`}
          </span>
        </Button>
      ),
    },
  ];

  function isSelected(selected) {
    return !!selectedItems?.find(((item) => item.id === selected.id));
  }

  if (hasBulkMessagePermission(isCcr, isBulkMessagingEnabled)) {
    columns.unshift({
      Header: () => '',
      accessor: 'id',
      sortable: false,
      fixed: 'left',
      width: CHECKBOX_COLUMN_WIDTH,
      className: 'u-flex-align-items-center u-flex-direction-row u-flex u-p-l-0 u-p-r-',
      Cell: (row) => (
        <span className="appointments-checkbox" key={row.original.id}>
          <Checkbox name="prescriptionGroupCheckbox" isChecked={isSelected(row.original)} onChange={() => handleSelect(row.original)} label="checkbox" />
        </span>
      ),
    });
  }

  function handleUpdateFilters(changeObj) {
    onSetFilters({
      ...changeObj,
      ...!changeObj.pageNo && {
        pageNo: 0,
      },
    });
  }

  function getActiveFilterValues(values) {
    const {
      pageNo: pn,
      startDate: sd,
      endDate: ed,
      ...activeValues
    } = values;
    return activeValues;
  }

  const showClearAll = !isEqual(getActiveFilterValues(filters), getActiveFilterValues(initialValue));

  const renderFilters = () => (
    <PrescriptionFilters handleChange={handleUpdateFilters} filters={filters} />
  );
  const rowProps = () => ({
    style: {
      cursor: 'pointer',
    },
    className: 'form-row form-row--prescriptions',
  });

  const renderList = () => (prescriptions?.length > 0 ? (
    <SmartTable
      data={prescriptions}
      getTrProps={rowProps}
      showPagination={false}
      pageSize={prescriptions?.length}
      sticky
      columns={columns}
      sortable
      manual={false}
      striped
    />
  ) : (
    <div className="form-list__noforms_wrapper">
      <EmptyMessage section="Prescription Manager" />
    </div>
  ));

  function handleSelect(selected) {
    if (isSelected(selected)) {
      setSelectedItems((current) => current.filter((item) => item.id !== selected.id));
    } else {
      setSelectedItems((current) => [...new Set([...current, selected])]);
    }
  }

  function handleUnselectContact(selected) {
    setSelectedItems((current) => current.filter((item) => item.user?.id !== selected.id));
  }

  function getUnselectedOnPage() {
    return prescriptions.filter((prescription) => !selectedItems.some((selected) => prescription.id === selected.id));
  }

  function handleSelectAllOnPage() {
    setSelectedItems((current) => {
      if (getUnselectedOnPage().length === prescriptions.length) {
        return [...new Set([...current, ...prescriptions])];
      } return current.filter((item) => !prescriptions.some((appointment) => appointment.id === item.id));
    });
  }

  function handleUnselectAll() {
    setSelectedItems([]);
  }

  return (
    <div className="app-page">
      <div className="app-panels">
        <div className="list-panel__wrapper">
          <div className="list-panel">
            <div className="list-panel__body" data-feature-tag="routingCategoryList">
              <Scrollbars className="library-scroll__container">
                <div className="list-panel">
                  <div className="app-page__header">
                    <div className="app-page__header__title">
                      Prescription Manager
                    </div>
                  </div>
                  <FilterPage
                    hasPanels
                    hasFilters
                    header="Total Prescriptions"
                    totalCount={total}
                    showClearAll={showClearAll}
                    clearAllFilters={handleClearAll}
                    pageNumber={pageNo}
                    loading={loading}
                    handlePagination={handlePagination}
                    pageItemCount={prescriptions?.length}
                    type="prescriptions"
                    displayBottomPagination
                    renderFilters={renderFilters}
                  >
                    <>
                      {hasBulkMessagePermission(isCcr, isBulkMessagingEnabled) && prescriptions.length > 0 && (
                      <BulkSelect
                        selectedItems={selectedItems}
                        pageItems={prescriptions}
                        handleSelect={handleUnselectAll}
                        handleUnselectContact={handleUnselectContact}
                        totalCount={total}
                        type="prescriptions"
                        handleGetContacts={getContactList}
                        handleSelectAll={handleSelectAllOnPage}
                        getUnselectedOnPage={getUnselectedOnPage}
                      />
                      )}
                      <div className="list-panel__body" data-feature-tag="prescriptionCampaignList">
                        <div className="list-panel" />
                        <div className="filter-page__table">
                          {renderList()}
                        </div>
                      </div>
                    </>
                  </FilterPage>
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrescriptionManager;
