import axios from 'axios';
import { normalize } from 'normalizr';
import moment from 'moment-timezone';
import { createSlice } from '@reduxjs/toolkit';
import { JobType } from 'rhinotilities/lib/core/enums/JobType';
import { AutoAssignmentType } from 'rhinotilities/lib/core/enums/AutoAssignmentType';
import { cloneDeep, getObjectKeys } from '../helpers/DataHelpers';
import { AudioHelpers, UIHelpers, UserHelpers, ChannelHelpers, DataHelpers, EventHelpers, BrowserHelpers } from '../helpers';
import NotificationService from '../services/NotificationService';
import OutboundPostMessageService from '../services/OutboundPostMessageService';
import { SavedContentActionTypes, AppConstants } from '../constants';
import { setError, receiveInboxSections, fetchInboxSections } from './uiReducer';
import * as UserReducer from './userReducer';
import { event, channel, user, mention } from '../actions/NormalizrSchema';
import * as FormReducer from './formReducer';
import * as ChannelReducer from './channelReducer';
import { getInboxContext, getMostRecentEvent, getInboxOptionsInReducer } from '../selectors/inboxSelectors';
import { getActiveUser, getLoggedInUserOrganization, userHasLimitedProviderRole } from '../selectors/userSelectors';
import { sendFormById } from '../services/RhinoformService';
import threadService, { upsertEventInQueryResults } from '../services/threadService';
import * as ThreadReducer from './threadReducer';
import { getNetworkErroMessage } from '../helpers/ErrorCodeHelpers';
import { MessageStatus } from '../constants/v3';

const { EVENT_SIZE, THREAD_SIZE } = AppConstants;
let cancelInboxRequest;

// SLICE

const inboxSlice = createSlice({
  name: 'INBOX',
  initialState: {
    pageLoading: false,
    inboxLoading: false,
    threadLoading: false,
    threadSearchLoading: false,
    inboxPageNo: 0,
    threadSearchPageNo: 0,
    assigned: false,
    mentions: false,
    direct: false,
    groupId: null,
    userId: null,
    following: false,
    events: {},
    eventIds: [],
    eventMentions: {},
    mentionIds: [],
    currentUserChannel: {},
    eventSearchIds: [],
    inboxEventIds: [],
    threadFilteredChannelIds: [],
    threadActiveChannelIds: [],
    threadFromChannelIds: [],
    activeToChannelId: null,
    activeFromChannelId: null,
    activeSecureFromChannelId: null,
    activeSecureNotificationToChannelId: null,
    activeSecureNotificationFromChannelId: null,
    mostRecentConversationLanguageId: null,
    threadSelectedChannelIds: [],
    searchJumpEventId: null,
    endOfSearchResults: false,
    openAssignments: [],
    assignees: [],
    socketEventIds: [],
    optimisticEvents: null,
  },
  reducers: {
    resetEventData: resetEventDataMutation,
    receiveInbox: receiveInboxData,
    receiveEventMentions: receiveEventMentionsInboxData,
    receiveWebSocketMention: receiveMentionsInboxData,
    removeInboxEvent: removeInboxEventMutation,
    clearInboxLoading: (state) =>
      ({
        ...state,
        inboxLoading: false,
      }),
    receiveError: (state) =>
      ({
        ...state,
        inboxLoading: false,
        pageLoading: false,
      }),
    receiveRecommendedLanguageId: receiveRecommendedLanguageIdMutation,
    receiveInboxThreadView: (state, action) => ({
      ...state,
      threadFilteredChannelIds: [...new Set([...state.threadFilteredChannelIds, ...action.payload.threadFilteredChannelIds])],
      threadActiveChannelIds: [...new Set([...state.threadActiveChannelIds, ...action.payload.threadActiveChannelIds])],
      threadFromChannelIds: [...new Set([...state.threadFromChannelIds, ...action.payload.threadFromChannelIds])],
      pageLoading: false,
      threadLoading: false,
    }),
    receiveInboxThread: receiveThreadData,
    replaceInboxThread: replaceThreadData,
    receiveThreadSearch: receiveThreadSearchData,
    pushReceiveThread: pushReceiveThreadData,
    receiveSecureMessageThread: receiveThreadData,
    receiveWebSocketEvent: receiveEventData,
    receiveCreateEvent: receiveEventData,
    receiveCreateTempEvent: receiveEventData,
    receiveOptimisticEvent: receiveOptimisticEventData,
    receiveOpenAssignments: receiveOpenAssignmentsData,
    receiveAssignees: receiveAssigneesData,
    receiveWebSocketEventStatus: (state, action) =>
      ({
        ...state,
        events: {
          ...state.events,
          [action.payload.eventId]: action.payload.event,
        },
      }),
    receiveInboxThreadSearch: (state, action) =>
      ({
        ...state,
        events: {
          ...state.events,
          ...action.payload.events,
        },
        eventSearchIds: action.payload.pageNo === 0 ? [...action.payload.eventIds] : [...new Set([...state.eventSearchIds, ...action.payload.eventIds])],
        threadSearchPageNo: action.payload.pageNo,
        threadSearchLoading: false,
        threadSearchEventId: null,
        endOfSearchResults: action.payload.eventIds?.length < EVENT_SIZE,
      }),
    receiveUpdateFollowing: (state, action) =>
      ({
        ...state,
        events: {
          ...state.events,
          [action.payload.eventId]: action.payload.event[action.payload.eventId],
        },
      }),
    receiveTranslatedText: receiveTranslatedData,
    requestInboxData: (state) =>
      ({
        ...state,
        inboxLoading: true,
      }),
    setSelectedThreadChannelIds: (state, action) => ({
      ...state,
      threadSelectedChannelIds: action.payload,
    }),
    requestInboxThreadData: (state) =>
      ({
        ...state,
        threadLoading: true,
      }),
    requestInboxThreadSearchData: (state) =>
      ({
        ...state,
        threadSearchLoading: true,
      }),
    requestInboxViewData: (state) =>
      ({
        ...state,
        pageLoading: true,
        threadLoading: true,
      }),
    clearThreadEvents: (state) =>
      ({
        ...state,
        eventIds: [],
      }),
    clearThreadSearch: (state) =>
      ({
        ...state,
        eventSearchIds: [],
        threadSearchPageNo: 0,
        searchJumpEventId: null,
        endOfSearchResults: false,
      }),
    setActiveFromChannel: (state, action) =>
      ({
        ...state,
        activeFromChannelId: action.payload,
      }),
    setActiveToChannel: (state, action) =>
      ({
        ...state,
        activeToChannelId: action.payload,
      }),
    setActiveSecureFromChannel: (state, action) =>
      ({
        ...state,
        activeSecureFromChannelId: action.payload,
      }),
    setActiveSecureNotificationToChannel: (state, action) =>
      ({
        ...state,
        activeSecureNotificationToChannelId: action.payload,
      }),
    setActiveSecureNotificationFromChannel: (state, action) =>
      ({
        ...state,
        activeSecureNotificationFromChannelId: action.payload,
      }),
    setFilteredChannelIds: (state, action) =>
      ({
        ...state,
        threadFilteredChannelIds: action.payload,
      }),
    clearThreadChannels: (state) =>
      ({
        ...state,
        threadActiveChannelIds: [],
      }),
    setInboxContext: (state, action) =>
      ({
        ...state,
        groupId: action.payload.groupId,
        userId: action.payload.userId,
        assigned: action.payload.assigned,
        direct: action.payload.direct,
        following: action.payload.following,
        mentions: action.payload.mentions,
        optimisticEvents: null,
      }),
    requestInitialThreadView: (state, action) => (
      {
        ...state,
        threadLoading: true,
        pageLoading: true,
        groupId: action.payload.groupId || null,
        userId: action.payload.userId || null,
        assigned: action.payload.assigned || false,
        direct: action.payload.direct || false,
        following: action.payload.following || false,
        mentions: action.payload.mentions || false,
        events: {},
        eventIds: [],
        currentUserChannel: {},
        inboxEventIds: [],
        socketEventIds: [],
        threadFilteredChannelIds: [],
        threadActiveChannelIds: [],
        threadFromChannelIds: [],
        activeToChannelId: null,
        activeFromChannelId: null,
        activeSecureFromChannelId: null,
        activeSecureNotificationToChannelId: null,
        activeSecureNotificationFromChannelId: null,
        openAssignments: [],
        assignees: [],
        endOfSearchResults: false,
        eventSearchIds: [],
        threadSearchPageNo: 0,
        searchJumpEventId: null,
        threadSelectedChannelIds: [],
        optimisticEvents: null,
      }
    ),
    setInboxCommunicationContext: (state, action) =>
      ({
        ...state,
        activeToChannelId: action.payload?.activeToChannelId,
        activeFromChannelId: action.payload?.activeFromChannelId,
        activeSecureFromChannelId: action.payload?.activeSecureFromChannelId,
        activeSecureNotificationToChannelId: action.payload?.activeSecureNotificationToChannelId,
        activeSecureNotificationFromChannelId: action.payload?.activeSecureNotificationFromChannelId,
      }),

    receiveMentionReadStatus: (state, action) => {
      const eventToUpdate = state.events[action.payload.eventId];
      if (eventToUpdate) {
        eventToUpdate.eventMentionStatusLoading = false;
        const mentionToUpdate = eventToUpdate.eventMentions?.find((eventMention) => eventMention.id === action.payload.id);
        if (mentionToUpdate) mentionToUpdate.isRead = true;
      }
    },
    setMentionStatusLoading: (state, action) => ({
      ...state,
      events: {
        ...state.events,
        [action.payload.eventId]: {
          ...state.events[action.payload.eventId],
          eventMentionStatusLoading: true,
        },
      },
    }),
    setSearchJumpEventId: (state, action) => ({
      ...state,
      searchJumpEventId: action.payload,
    }),
    receiveSocketEventId: (state, action) => ({
      ...state,
      socketEventIds: [...new Set([...state.socketEventIds, action.payload])],
    }),
    setOptimisticEvents: (state, action) => {
      state.optimisticEvents = action.payload;
    },
  },
  extraReducers: {
    [SavedContentActionTypes.receiveEventsForSavedContent]: (state, action) =>
      ({
        ...state,
        events: {
          ...state.events,
          ...action.payload.events,
        },
        eventIds: [...new Set([...state.eventIds, ...action.payload.eventIds])],
      }),
    [SavedContentActionTypes.receiveCreatedSavedContentItem]: (state, action) =>
      ({
        ...state,
        events: {
          ...state.events,
          ...action.payload.events,
        },
        eventIds: [...new Set([...state.eventIds, ...action.payload.eventIds])],
      }),
    [SavedContentActionTypes.removeSavedContentItem]: (state, action) => {
      const newEvents = cloneDeep(state.events);
      if (action.payload.isCopiedContent === false) {
        const foundEventIndex = newEvents ? Object.keys(newEvents).find((eventId) => newEvents[eventId]?.details?.savedContentItemId === action.payload.id) : -1;
        if (foundEventIndex > -1) newEvents[foundEventIndex].details.deleted = 1;
      }

      return {
        ...state,
        events: {
          ...newEvents,
        },
      };
    },
    [SavedContentActionTypes.updateSavedContentSent]: (state, action) => {
      const newEvents = cloneDeep(state.events);
      const foundEvent = newEvents ? newEvents[action.payload.event.id] : false;
      if (foundEvent) {
        newEvents[action.payload.event.id].statusTypeId = action.payload.event.statusTypeId;
      }
      return {
        ...state,
        events: {
          ...newEvents,
        },
      };
    },
  },
});

export default inboxSlice.reducer;

// ACTIONS

export const {
  setOptimisticEvents,
  receiveOptimisticEvent,
  clearInboxLoading,
  clearThreadChannels,
  clearThreadEvents,
  clearThreadSearch,
  pushReceiveThread,
  receiveCreateEvent,
  receiveCreateTempEvent,
  receiveEventMentions,
  receiveInbox,
  receiveInboxThread,
  receiveInboxThreadSearch,
  receiveInboxThreadView,
  receiveMentionReadStatus,
  receiveOpenAssignments,
  receiveAssignees,
  receiveRecommendedLanguageId,
  receiveSecureMessageThread,
  receiveTranslatedText,
  receiveUpdateFollowing,
  receiveWebSocketEvent,
  receiveWebSocketEventStatus,
  receiveWebSocketMention,
  removeInboxEvent,
  requestInboxData,
  requestInboxThreadData,
  requestInboxThreadSearchData,
  requestInboxViewData,
  resetEventData,
  setActiveFromChannel,
  setActiveSecureFromChannel,
  setActiveSecureNotificationFromChannel,
  setActiveSecureNotificationToChannel,
  setActiveToChannel,
  setFilteredChannelIds,
  setInboxCommunicationContext,
  setInboxContext,
  setMentionStatusLoading,
  receiveError,
  setSelectedThreadChannelIds,
  setSearchJumpEventId,
  replaceInboxThread,
  receiveThreadSearch,
  requestInitialThreadView,
  receiveSocketEventId,
} = inboxSlice.actions;

// REDUCER HELPERS

function resetEventDataMutation(state) {
  return {
    ...state,
    userId: null,
    groupId: null,
    following: false,
    assigned: false,
    mentions: false,
    direct: false,
    events: {},
    eventIds: [],
    currentUserChannel: {},
    inboxEventIds: [],
    threadFilteredChannelIds: [],
    threadActiveChannelIds: [],
    threadFromChannelIds: [],
    activeToChannelId: null,
    activeFromChannelId: null,
    activeSecureFromChannelId: null,
    activeSecureNotificationToChannelId: null,
    activeSecureNotificationFromChannelId: null,
    openAssignments: [],
    assignees: [],
    endOfSearchResults: false,
    eventSearchIds: [],
    threadSearchPageNo: 0,
    searchJumpEventId: null,
    socketEventIds: [],
  };
}

const sortInboxIdsByTimestampDesc = (events, inboxEventIds) =>
  inboxEventIds.filter((eventId) => !!events[eventId]).sort((a, b) => moment(events[b].timestamp).diff(moment(events[a].timestamp)));

const sortMentionIdsByTimestampDesc = (mentions, ids) =>
  ids.sort((a, b) => moment(mentions[b].timestamp).diff(moment(mentions[a].timestamp)));

function receiveInboxData(state, action) {
  return {
    ...state,
    events: action.payload.events,
    inboxEventIds: action.payload.inboxEventIds,
    pageLoading: false,
    inboxLoading: false,
    inboxPageNo: action.payload.pageNo,
    currentUserChannel: action.payload.currentUserChannel,
  };
}

function receiveEventMentionsInboxData(state, action) {
  return {
    ...state,
    eventMentions: action.payload.eventMentions,
    mentionIds: sortMentionIdsByTimestampDesc(action.payload.eventMentions, action.payload.mentionIds),
    pageLoading: false,
    inboxLoading: false,
    inboxPageNo: action.payload.pageNo,
  };
}

function receiveOpenAssignmentsData(state, action) {
  return {
    ...state,
    openAssignments: action.payload.data,
  };
}

function receiveAssigneesData(state, action) {
  return {
    ...state,
    assignees: action.payload.data,
  };
}

function receiveRecommendedLanguageIdMutation(state, action) {
  return {
    ...state,
    mostRecentConversationLanguageId: action.payload,
  };
}

function receiveMentionsInboxData(state, action) {
  const mergedEvents = {
    ...state.events,
    ...action.payload.events,
  };
  const mergedInboxEventIds = [
    ...state.inboxEventIds,
    action.payload.inboxEventIds,
  ];
  const eventMentions = {
    ...state.eventMentions,
    ...action.payload.eventMentions,
  };
  const mentionIds = [
    ...state.mentionIds,
    action.payload.mentionIds,
  ];
  return {
    ...state,
    events: mergedEvents,
    inboxEventIds: sortInboxIdsByTimestampDesc(mergedEvents, mergedInboxEventIds),
    pageLoading: false,
    inboxLoading: false,
    eventMentions,
    mentionIds: sortMentionIdsByTimestampDesc(eventMentions, mentionIds),
  };
}
function sortEventIdsByTimestampDesc(events, eventIds) {
  return eventIds.filter((eventId) => !!events[eventId]).sort((a, b) => moment(events[a].timestamp).diff(moment(events[b].timestamp)));
}

function receiveThreadData(state, action) {
  const mergedEvents = {
    ...state.events,
    ...action.payload.events,
  };

  const mergedEventIds = [...new Set([...action.payload.eventIds, ...state.eventIds])];

  return {
    ...state,
    events: mergedEvents,
    eventIds: sortEventIdsByTimestampDesc(mergedEvents, mergedEventIds),
    pageLoading: false,
    threadLoading: false,
  };
}

function replaceThreadData(state, action) {
  return {
    ...state,
    events: action.payload.events,
    eventIds: sortEventIdsByTimestampDesc(action.payload.events, action.payload.eventIds),
    pageLoading: false,
    threadLoading: false,
  };
}

function receiveThreadSearchData(state, action) {
  const mergedEvents = {
    ...state.events,
    ...action.payload.events,
  };
  return {
    ...state,
    events: mergedEvents,
    eventIds: sortEventIdsByTimestampDesc(action.payload.events, action.payload.eventIds),
    pageLoading: false,
    threadLoading: false,
    searchEventId: false,
  };
}

export function pushReceiveThreadData(state, action) {
  const mergedEvents = {
    ...state.events,
    ...action.payload.events,
  };

  return {
    ...state,
    events: mergedEvents,
    eventIds: [...getObjectKeys(mergedEvents)],
    pageLoading: false,
    threadLoading: false,
  };
}

function receiveEventData(state, action) {
  const currentInboxThreadId = state.inboxEventIds.find((id) => (
    id !== action.payload.eventId && state.events[id] && action.payload.event[action.payload.eventId] &&
    EventHelpers.shapeEventToV3(state.events[id])?.eventUserId === action.payload.event[action.payload.eventId].eventUserId
  ));
  // If payload has optimisticId, it was sent originally optimistically. We filter it out of the original list.
  const tempEventId = action.payload.event[action.payload.eventId].optimisticId;

  const optimisticEventIds = [...state.eventIds, action.payload.eventId].filter((i) => typeof i !== 'number' && tempEventId !== i);
  const realEventIds = [...state.eventIds, action.payload.eventId].filter((i) => typeof i === 'number');
  const mergedEvent = {
    ...state.events[action.payload.eventId],
    ...action.payload.event[action.payload.eventId],
  };

  const mergedEvents = {
    ...state.events,
    [action.payload.eventId]: mergedEvent,
  };
  if (tempEventId) {
    delete mergedEvents[tempEventId];
  }

  return ({
    ...state,
    events: mergedEvents,
    eventIds: [...new Set([...sortEventIdsByTimestampDesc(mergedEvents, realEventIds), ...optimisticEventIds])],
    inboxEventIds: [...new Set([action.payload.eventId, ...state.inboxEventIds.filter((i) => i !== currentInboxThreadId)])],
  });
}

function receiveOptimisticEventData(state, action) {
  const currentValues = state.optimisticEvents || [];
  const optimisticEvent = { ...action.payload, id: action.payload.optimisticId };
  const currentOptimisticEventIndex = currentValues.findIndex((currentEvent) => currentEvent.optimisticId === action.payload.optimisticId);
  if (currentOptimisticEventIndex > -1) {
    state.optimisticEvents[currentOptimisticEventIndex] = {
      ...state.optimisticEvents[currentOptimisticEventIndex],
      ...optimisticEvent,
    };
  } else {
    state.optimisticEvents = [...currentValues, optimisticEvent];
  }
}

function removeInboxEventMutation(state, action) {
  return {
    ...state,
    inboxEventIds: [...state.inboxEventIds.filter((inboxEventId) => action.payload.eventId !== inboxEventId)],
  };
}

function receiveTranslatedData(state, action) {
  const eventToUpdate = state.events[action.payload.data.id];
  eventToUpdate.details.translatedText = action.payload.data.message.translatedText;
}

// THUNKS -- ASYNC ACTION CREATORS

export function requestInboxView() {
  return (dispatch) => {
    if (cancelInboxRequest) cancelInboxRequest();
    dispatch(requestInboxViewData());
  };
}

export function getTranslatedText(payload) {
  return (dispatch) => axios.post('/languageTranslation', payload)
    .then((response) => response.data)
    .catch((err) => dispatch(setError(err.response || err)));
}

export function translateEventText(payload) {
  return (dispatch) => {
    dispatch(getTranslatedText(payload)).then((data) => {
      dispatch(updateEventTranslatedText(data.translatedText, payload.id));
    });
  };
}

export const updateMentionReadStatus = (eventMentionId, eventId) => (dispatch) => {
  const url = '/chat/mentions/updateRead';
  dispatch(setMentionStatusLoading({ eventId }));
  return axios.patch(url, { eventMentionId, isRead: true }).then(({ data: updatedMention }) => {
    dispatch(receiveMentionReadStatus(updatedMention));
    dispatch(fetchInboxSections());
  })
    .catch((err) => {
      console.error(err.response || err);
    });
};

export function updateConsentStatus({ hipaaStatus, rhinopayConsentStatus, marketingConsentStatus }) {
  return (dispatch) => {
    const hipaaStatusPayload = {
      userId: hipaaStatus.userId,
      typeId: hipaaStatus.typeId,
      trustee: hipaaStatus.trustee,
      changed: hipaaStatus.hasChanged,
    };
    const rhinopayConsentStatusPayload = {
      userId: rhinopayConsentStatus.userId,
      typeId: rhinopayConsentStatus.typeId,
      trustee: rhinopayConsentStatus.trustee,
      changed: rhinopayConsentStatus.hasChanged,
    };

    const marketingConsentStatusPayload = {
      userId: marketingConsentStatus.userId,
      typeId: marketingConsentStatus.typeId,
      trustee: marketingConsentStatus.trustee,
      changed: marketingConsentStatus.hasChanged,
    };

    const promises = [];
    const isRhinopayConsentStatusUpdated = rhinopayConsentStatus.hasChanged;
    const isHipaaStatusUpdated = hipaaStatus.hasChanged;
    const isMarketingConsentStatusUpdated = marketingConsentStatus.hasChanged;

    if (isRhinopayConsentStatusUpdated) promises.push(patchRhinoPayStatus(rhinopayConsentStatusPayload));
    if (isHipaaStatusUpdated) promises.push(patchHipaaStatus(hipaaStatusPayload));
    if (isMarketingConsentStatusUpdated) promises.push(patchMarketingStatus(marketingConsentStatusPayload));

    return Promise.all(promises)
      .then((response) => {
        const normalized = normalize(response[0].data, event);
        dispatch(receiveCreateEvent(getInboxEventPayload(normalized)));
        // If both consent statuses were updated, we know that response[1] will contain the rhinopay response
        if (isHipaaStatusUpdated && isRhinopayConsentStatusUpdated) {
          const normalizedRhinopay = normalize(response[1].data, event);
          dispatch(receiveCreateEvent(getInboxEventPayload(normalizedRhinopay)));
        }
      })
      .catch((err) => {
        console.error(err.response || err);
        dispatch(setError(err.response || err));
        NotificationService('updateConsentStatus', err.response || err);
      });
  };
}

export function getRecommendedLanguageId(userId) {
  const url = `/languages/recommended/user/${userId}`;

  return (dispatch) => { // eslint-disable-line arrow-body-style
    return axios.get(url)
      .then((response) => {
        dispatch(receiveRecommendedLanguageId(response.data?.languageId));
      })
      .catch((err) => {
        console.error(err.response || err);
        dispatch(setError(err.response || err));
      });
  };
}

export function resetEvents() {
  return (dispatch) => {
    dispatch(resetEventData());
  };
}

export function fetchInbox({ groupId = 0, following = false, direct = false, pageNumber, sort = 'descending', minimal = true, mentions = false }) {
  const pageNo = pageNumber ?? 0;
  const pageSize = THREAD_SIZE;
  let url = `/inbox?pageNo=${pageNo}&pageSize=${pageSize}&sort=${sort}`;
  if (groupId) {
    url = `${url}&groupId=${groupId}`;
  } else if (following) {
    url = `${url}&following=1`;
  } else if (direct) {
    url = `${url}&direct=1`;
  } else if (mentions) {
    url = `/mentions?pageNo=${pageNo}&pageSize=${pageSize}&sort=${sort}`;
  } else {
    url = `${url}&assigned=1`;
  }

  if (minimal && !mentions) url = `${url}&minimal=1`;
  return (dispatch, getState) => {
    dispatch(requestInboxData());
    return axios.get(url, {
      cancelToken: new axios.CancelToken(((cancelFunction) => {
        cancelInboxRequest = cancelFunction;
      })),
    })
      .then((response) => {
        const normalized = mentions ? normalize(response.data, [mention]) : normalize(response.data, [event]);
        if (normalized.result) {
          let page = pageNo;

          if (pageNo > 0 && !normalized.result.length) {
            page = pageNo - 1;
          }
          if (mentions) {
            const { currentUser } = getState().auth;
            // Remove current user, which we always load, from normalized payload to prevent shallow merge overwritting props.
            if (normalized?.entities?.users && normalized?.entities?.users[currentUser]) delete normalized?.entities?.users[currentUser];
            if (normalized?.entities?.senders && normalized?.entities?.senders[currentUser]) delete normalized?.entities?.senders[currentUser];
            dispatch(receiveEventMentions(getMentionsInboxPayload(normalized, page || 0)));
          } else {
            dispatch(receiveInbox(getInboxPayload(normalized, page || 0)));
          }
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          cancelInboxRequest = null;
          dispatch(receiveError());
          console.error(err.response || err);
          dispatch(setError(err.response || err));
        }
      });
  };
}

export function fetchSecureMessageThread(userId, pageNo = 0, sort = 'descending') {
  const pageSize = EVENT_SIZE;
  const url = `/smThread/${userId}?pageNo=${pageNo}&pageSize=${pageSize}&sort=${sort}`;

  return (dispatch) => {
    dispatch(requestInboxThreadData());

    return axios.get(url)
      .then((response) => {
        const normalized = normalize(response.data, [event]);

        if (normalized.result) {
          let page = pageNo;

          if (pageNo > 0 && (normalized.result.length === 0 || normalized.result.length < pageSize)) {
            page = pageNo - 1;
          }

          dispatch(receiveSecureMessageThread(getSecureMessageThreadPayload(normalized, page || 0)));
        }
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));
      });
  };
}

export function fetchOpenAssignments(patientId) {
  const url = `/openAssignments/${patientId}`;
  return (dispatch) => axios.get(url)
    .then((response) => {
      dispatch(receiveOpenAssignments(response));
    })
    .catch((err) => {
      dispatch(receiveError());
      console.error(err.response || err);
      dispatch(setError(err.response || err));
    });
}

export function fetchAssignees({ userId }) {
  const url = `assignment/assignees/${userId}`;
  return (dispatch) => axios.get(url)
    .then((response) => {
      dispatch(receiveAssignees(response));
    })
    .catch((err) => {
      dispatch(receiveError());
      console.error(err.response || err);
      dispatch(setError(err.response || err));
    });
}

export const handleApplyThreadFilters = (threadFilteredChannelIds) => async (dispatch) => {
  dispatch(setSelectedThreadChannelIds(threadFilteredChannelIds));
};

export function fetchInboxThreadView({
  assigned = false,
  direct = false,
  following = false,
  groupId = 0,
  userId,
}) {
  return (dispatch, getState) => { // eslint-disable-line arrow-body-style
    const currentState = getState();
    return axios.all([
      UserReducer.getUser(userId),
      ChannelReducer.getThreadFromChannels(userId, groupId, assigned, following, direct),
      ChannelReducer.getThreadActiveChannels(userId, groupId, assigned, following, direct),
    ])
      .then(axios.spread((userResult, channelFromResult, channelActiveResult) => {
        const normalizedUser = userResult ? normalize(userResult.data, user) : null;
        const normalizedFromChannel = channelFromResult ? normalize(channelFromResult.data, [channel]) : null;
        const normalizedActiveChannel = channelActiveResult ? normalize(channelActiveResult.data.channels, [channel]) : null;
        let selectedChannelIds = channelActiveResult ? channelActiveResult.data.selectedChannelIds : [];
        const userOrganization = getLoggedInUserOrganization(currentState);
        if (userOrganization?.areChannelFiltersEnabled) {
          selectedChannelIds = normalizedActiveChannel.result;
          dispatch(handleApplyThreadFilters(selectedChannelIds));
        }
        dispatch(receiveInboxThreadView(getInboxThreadViewPayload(
          normalizedUser,
          normalizedFromChannel,
          normalizedActiveChannel,
          selectedChannelIds,
          userId,
        )));
        return selectedChannelIds;
      }))
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));
      });
  };
}

const { CancelToken } = axios;
let cancelTokenSource;
export function fetchThreadSearch({ userId,
  search,
  groupId = 0,
  assigned = false,
  following = false,
  direct = false,
  pageNo = 0,
  channelIds = null,
  sort = 'ascending',
}) {
  const pageSize = EVENT_SIZE;

  let url = `/threadSearch/${userId}?searchTerm=${search}&pageSize=${pageSize}&pageNo=${pageNo}&minimal=1&sort=${sort}`;

  if (groupId) {
    url = `${url}&groupId=${groupId}`;
  } else if (following) {
    url = `${url}&following=1`;
  } else if (direct) {
    url = `${url}&direct=1`;
  } else if (assigned) {
    url = `${url}&assigned=1`;
  }

  if (channelIds) url = `${url}&channelIds=${channelIds}`;

  return (dispatch) => {
    if (cancelTokenSource && pageNo === 0) cancelTokenSource.cancel();
    cancelTokenSource = CancelToken.source();
    dispatch(requestInboxThreadSearchData());
    return axios.get(url, {
      cancelToken: cancelTokenSource.token,
    })
      .then((response) => {
        const normalized = normalize(response.data, [event]);

        if (normalized.result) {
          dispatch(receiveInboxThreadSearch(getInboxThreadPayload(normalized, pageNo || 0)));
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error(err.response || err);

          dispatch(setError(err.response || err));
        }
      }).finally(() => { cancelTokenSource = null; });
  };
}
export function createTempInboxEvent(postEvent, eventType = 'messages', hipaaRequestType) {
  const payload = postEvent;
  let typeId = null;

  const updatedEventType = hipaaRequestType ?? eventType;
  switch (updatedEventType) {
    case 'messages':
      typeId = 26;
      break;
    case 'notes':
      typeId = 27;
      break;
    case 'secureMessages':
      typeId = 28;
      break;
    case 'HIPAA Request':
      typeId = 169;
      break;
    default:
      typeId = 26;
  }

  payload.typeId = typeId;

  return (dispatch, getState) => {
    payload.sender = getState().auth.currentUser;
    dispatch(receiveOptimisticEvent(payload));
    dispatch(receiveCreateTempEvent(getTempInboxEventPayload(payload)));
  };
}

function getTempInboxEventPayload(payload) {
  let channels;

  if (payload.channelId) {
    channels = [payload.channelId];
  } else if (payload.secureChannelId) {
    channels = [payload.secureChannelId];
  } else {
    channels = [];
  }
  return {
    event: {
      [payload.optimisticId]: {
        attachments: payload.attachments || [],
        channels,
        id: payload.optimisticId,
        optimisticId: payload.optimisticId,
        incoming: false,
        read: true,
        user: payload.userId,
        sender: payload.sender,
        typeId: payload.typeId,
        assigned: payload.assigned,
        following: payload.following,
        mentions: payload.mentions,
        languageId: payload.languageId,
        details: {
          text: payload.text,
          translatedText: payload.translatedText,
          languageId: payload.languageId,
        },
        rhinoFormContent: payload.rhinoFormContent,
        eventMentions: payload.eventMentions,
      },
    },
    eventId: payload.optimisticId,
  };
}

export function createInboxEvent(postEvent, eventType = 'messages', context = {}, threadOptions, tempEvent) {
  const { groupId, following, direct, assigned, isAssigned } = context;
  const { organizationId, type } = postEvent;
  const isBulkMessage = type === JobType.RhinoBlast;
  let url = `/events/${eventType}?minimal=1`;
  if (groupId) {
    url = `${url}&groupId=${groupId}`;
  } else if (following) {
    url = `${url}&following=1`;
  } else if (direct) {
    url = `${url}&direct=1`;
  } else if (assigned) {
    url = `${url}&assigned=1`;
  }

  url = isAssigned ? `${url}&isAssigned=1` : `${url}&isAssigned=0`;

  if (eventType === 'messages') {
    if (isBulkMessage) {
      url = `${process.env.REACT_APP_RHINOMESSAGE_URL}/organizations/${organizationId}/batchJobs`;
    } else {
      url = `${process.env.REACT_APP_RHINOMESSAGE_URL}/organizations/${organizationId}/jobs`;
    }
  }

  return (dispatch, getState) => {
    const currentState = getState();
    const soundOn = !isBulkMessage && AudioHelpers.getSoundOnOff(currentState.organization.organizations, currentState.user.users[currentState.auth.currentUser]);

    return axios.post(url, postEvent)
      .then((response) => {
        const eventData = response.data;
        if (isBulkMessage) {
          NotificationService('createBulkMessage', response);
        } else {
          if (eventType === 'messages' && response.status !== 202) {
            NotificationService('createMessage', null, eventData);
          }
          if (soundOn && (eventType === 'secureMessages' || eventType === 'messages')) AudioHelpers.playAudio('message-sent');
          return eventData;
        }
        return null;
      })
      .catch((err) => {
        dispatch(setError(err.response || err));
        if (isBulkMessage) {
          NotificationService('createBulkMessage', err.response);
        } else {
          if (soundOn && eventType === 'messages') AudioHelpers.playAudio('message-failed');
          const customMessage = getNetworkErroMessage(err);
          NotificationService('createMessage', err.response, customMessage, true);
          dispatch(receiveOptimisticEvent({ optimisticId: tempEvent.optimisticId, status: MessageStatus.NetworkError }));
        }
      });
  };
}

const getMentionFromWebSocketEvent = (mentions, userId) => {
  const currentUserMention = mentions?.find((userMention) => userMention?.userId === userId && !userMention.isRead);
  return currentUserMention;
};

function shapeEventForQuery(eventPayload, threadOptions) {
  return (dispatch) => {
    const shapedEvent = getQueryPayload(eventPayload);
    const updateEvent = shapedEvent.event[shapedEvent.eventId];
    dispatch(upsertEventInQueryResults(updateEvent, threadOptions));
  };
}

export function receiveWebsocketEventMessage(wsEvent) {
  return (dispatch, getState) => {
    const currentState = getState();
    const currentUserId = currentState.auth.currentUser;
    const currentUser = currentState.user.users[currentUserId];
    const pathName = window.location.pathname.substring(1).split('/');
    const webSocketEvent = EventHelpers.shapeEventToV3(wsEvent.event);
    if (webSocketEvent.isIncoming && !webSocketEvent.user) {
      webSocketEvent.user = webSocketEvent.senderId;
    }
    const isSentByCurrentUser = webSocketEvent.sender?.id === currentUserId;
    const {
      isIncoming,
    } = webSocketEvent;
    const webSocketContext = wsEvent.context;
    const isAssignmentToCurrentUser = webSocketEvent?.toMemberId === currentUserId || (webSocketContext.assigned && webSocketContext.memberId === currentUserId);
    const soundOn = AudioHelpers.getSoundOnOff(currentState.organization.organizations, currentUser);
    const webSocketUserId = webSocketEvent.eventUserId;
    const isBulkMessageEvent = webSocketEvent.isBulkMessage || webSocketEvent.isAutomatedCampaignEvent;
    // make a copy from state, so we can update without mutating
    const inboxSections = { inbox: [...currentState.ui.inboxSections.inbox || []], chat: [...currentState.ui.inboxSections.chat || []] };
    const {
      groupIds: webSocketGroupIds,
      assigned: webSocketAssigned,
      following: webSocketFollowing,
      direct: webSocketDirect,
      channelId: webSocketChannelId,
    } = webSocketContext;

    // current member context
    const { groupId, userId, threadFilteredChannelIds, threadSelectedChannelIds, inboxPageNo, events, socketEventIds, inboxEventIds } = currentState.inbox;
    const activeUserRhinoformEvent = ((webSocketEvent.fromUserId === currentUserId) || (wsEvent.userId === currentUserId)) &&
           webSocketEvent.isRhinoformSendEvent; // handle rhinoform events in all of contact's threads
    const following = pathName.includes('following');
    const direct = pathName.includes('direct');
    const all = pathName.includes('all');
    const assigned = !groupId && !following && !direct && !all;

    const sameThread = webSocketUserId === userId; // is a member viewing the user's thread of the incoming websocket event?
    const unreadMention = getMentionFromWebSocketEvent(webSocketEvent.eventMentions, currentUserId);
    // is member in an inbox of the same context as the websocket event?
    const sameInbox = (
      (all)
      || (webSocketGroupIds.length > 0 && webSocketGroupIds.includes(groupId))
      || (webSocketDirect && webSocketDirect === direct)
      || (webSocketAssigned && webSocketAssigned === assigned)
      || (webSocketFollowing && webSocketFollowing === following)
    );
    const currentChannelIds = threadSelectedChannelIds?.length > 0 ? threadSelectedChannelIds : threadFilteredChannelIds;
    // Event does not belong in any member inboxes
    const isOutsideMemberContext = !webSocketGroupIds.length && !webSocketFollowing && !webSocketAssigned && !sameThread && !webSocketDirect;
    const isEventInActiveChannels = currentChannelIds && webSocketChannelId ? currentChannelIds.includes(webSocketChannelId) : false;
    const isNewEvent = !DataHelpers.hasData(events?.[webSocketEvent.id]) && !socketEventIds?.includes(webSocketEvent.id);
    const isReassignedFromInbox = !sameThread && !sameInbox && webSocketEvent.isAssignmentEvent && groupId && groupId === webSocketEvent.fromGroupId;
    if (isNewEvent) {
      if (sameThread && isEventInActiveChannels) { // if thread open from same user of incoming message and event is in filtered channels
        dispatch(updateRead({ userId, groupId, assigned, following, direct, channelIds: currentChannelIds, audit: false }));
        webSocketEvent.read = true;
      } else if (unreadMention && isSentByCurrentUser) {
        dispatch(updateMentionReadStatus(unreadMention.id, webSocketEvent.id));
        webSocketEvent.read = true;
        unreadMention.isRead = true;
      } else if (isBulkMessageEvent) {
        webSocketEvent.read = true;
      } else {
      // Show unread indicator & increase badge count for outgoing messages in threads assigned to me
        if (isAssignmentToCurrentUser && !webSocketEvent.isRead) {
          webSocketEvent.read = false;
        }
        if (!isOutsideMemberContext || isReassignedFromInbox) {
          // Remove event from inbox if it has been reassigned
          const reassignedEventId = isReassignedFromInbox && inboxEventIds?.find(
            (eventId) => {
              const inboxEvent = events?.[eventId] && EventHelpers.shapeEventToV3(events[eventId]);
              return inboxEvent?.channelId === webSocketEvent?.channelId
               && webSocketEvent.eventUserId === inboxEvent?.eventUserId;
            },
          );
          if (reassignedEventId) {
            dispatch(removeInboxEvent({ eventId: reassignedEventId }));
          }
          // adjust badges
          inboxSections.inbox = inboxSections.inbox.map((section) => {
            const adjustedSection = { ...section };

            // if context matches section, then adjust count
            if (webSocketChannelId
          && ((section.type === 'group' && webSocketGroupIds.includes(section.userGroupId))
            || (section.type === 'following' && webSocketFollowing)
            || (section.type === 'direct' && webSocketDirect)
            || (section.type === 'assigned' && webSocketAssigned
            ))
            ) {
              if (!adjustedSection.totalUserIds.includes(webSocketUserId)) {
                adjustedSection.totalCount += 1;
              }
              if (!adjustedSection.unreadUserIds.includes(webSocketUserId) && !webSocketEvent.read) { // if unread event and userId is new, add to count
                adjustedSection.unreadUserIds = [...adjustedSection.unreadUserIds, webSocketUserId];
                adjustedSection.unreadCount = adjustedSection.unreadUserIds.length;
              } else if (adjustedSection.unreadUserIds.includes(webSocketUserId) && webSocketEvent.read) { // if event is read and userId is existing, remove from count
                adjustedSection.unreadUserIds = adjustedSection.unreadUserIds.filter((u) => u !== webSocketUserId);
                adjustedSection.unreadCount = adjustedSection.unreadUserIds.length;
              }
            }
            if ((section.type === 'group' && webSocketEvent.fromGroupId === section.userGroupId)) {
              adjustedSection.unreadUserIds = adjustedSection.unreadUserIds.filter((u) => u !== webSocketUserId);
              adjustedSection.unreadCount = adjustedSection.unreadUserIds.length;
            }

            return adjustedSection;
          });
        }
        // update mentions badge count if message contains mention
        if (unreadMention) {
          inboxSections.inbox = inboxSections.inbox.map((section) => {
            const adjustedSection = { ...section };
            if (adjustedSection.type === 'mentions') {
              const { unreadInboxMentions } = adjustedSection;
              adjustedSection.unreadInboxMentions = [...unreadInboxMentions, { mentionId: unreadMention.id, eventId: webSocketEvent.id }];
              adjustedSection.totalCount += 1;
            } return adjustedSection;
          });

          if (pathName.includes('mentions')) {
            webSocketEvent.eventPatientId = webSocketUserId;
            webSocketEvent.eventId = webSocketEvent.id;
            const normalized = normalize(webSocketEvent, mention);
            dispatch(receiveWebSocketMention(getMentionsInboxPayload(normalized)));
          }
        }
      }

      if (activeUserRhinoformEvent) {
        dispatch(FormReducer.refetchContactForms());
      }
      dispatch(receiveInboxSections(inboxSections));
      if (sameThread) {
        dispatch(fetchOpenAssignments(userId));
        if (assigned) {
          dispatch(fetchAssignees({ userId }));
        }
      }
      // Update native app badge count
      const badgeCount = UIHelpers.getTotalUnreadCount();
      OutboundPostMessageService.postMessage({
        type: 'setBadgeCount',
        data: { badgeCount },
      });
    }
    // add event if member is in same inbox or (is viewing the user and event is a non-channel event or user has the channel filtered to show)
    if ((sameInbox && !isBulkMessageEvent) || (sameThread && (!webSocketChannelId || isEventInActiveChannels))) {
      const normalized = normalize(webSocketEvent, event);
      const languageId = webSocketEvent?.languageId;
      if (languageId) {
        dispatch(receiveRecommendedLanguageId(languageId));
      }
      const rtkOptions = {
        groupId: webSocketGroupIds?.find((wsGroupId) => groupId === wsGroupId) || groupId || null,
        assigned: webSocketAssigned && webSocketAssigned === assigned,
        following: webSocketFollowing && webSocketFollowing === following,
        direct: webSocketDirect && webSocketDirect === direct,
        userId: webSocketUserId,
        channelIds: threadSelectedChannelIds?.length > 0 ? threadSelectedChannelIds : [],
        organizationId: wsEvent.orgId,
        initialPageNo: window.history?.state?.state?.pageNo || 0,
      };
      dispatch(shapeEventForQuery(normalized, rtkOptions));
      if (inboxPageNo === 0) {
        dispatch(receiveWebSocketEvent(getWebSocketEventPayload(normalized)));
      }
      dispatch(upsertEventInQueryResults(webSocketEvent, rtkOptions));
    }
    // If a language id is present, this is a language detection event, so don't play sound
    const isLanguageDetectionEvent = !!webSocketEvent?.languageId;
    // If a thread is assigned to the logged in user, it displays in the inbox just like a new message. Therefore, sound.
    if (isNewEvent && soundOn && !isOutsideMemberContext &&
      (isIncoming || isAssignmentToCurrentUser || unreadMention)
      && !isLanguageDetectionEvent && !isBulkMessageEvent) {
      const options = {
        assigned: isAssignmentToCurrentUser,
        mention: !!unreadMention,
        ...isIncoming && {
          following: webSocketFollowing,
          direct: webSocketDirect,
          groupIds: webSocketGroupIds,
        },
        messageType: 'inbox',
      };
      const playIncomingSound = UserHelpers.getIncomingSoundPreference(currentState, options, webSocketEvent.id);
      if (playIncomingSound) {
        AudioHelpers.playAudio('message-received');
      }
      dispatch(receiveSocketEventId(webSocketEvent.id));
    }
  };
}

export function receiveWebSocketEventStatusMessage(webSocketEvent) {
  return (dispatch, getState) => {
    const { data: { event: updatedEvent } } = webSocketEvent;
    const currentState = getState();
    // current member context
    const pathName = window.location.pathname.substring(1).split('/');
    const { groupId, userId, threadSelectedChannelIds } = currentState.inbox;
    const following = pathName.includes('following');
    const direct = pathName.includes('direct');
    const all = pathName.includes('all');
    const assigned = !groupId && !following && !direct && !all;
    // dispatch
    const rtkOptions = {
      groupId,
      assigned,
      following,
      direct,
      userId,
      channelIds: threadSelectedChannelIds?.length > 0 ? threadSelectedChannelIds : [],
      organizationId: webSocketEvent.orgId,
      initialPageNo: window.history?.state?.state?.pageNo || 0,
    };
    dispatch(upsertEventInQueryResults(updatedEvent, rtkOptions));
  };
}

export function updateFollowing(
  { userId,
    followingFlag = true,
    following = false,
    assigned = null,
    groupId = null,
    direct = null,
    channelIds = null,
    originalReadStatus = true },
) {
  let url = `/thread/${userId}`;

  if (groupId) {
    url = `${url}?groupId=${groupId}`;
  } else if (following) {
    url = `${url}?following=1`;
  } else if (direct) {
    url = `${url}?direct=1`;
  } else if (assigned) {
    url = `${url}?assigned=1`;
  }

  if (channelIds) url = url.includes('?') ? `${url}&channelIds=${channelIds}` : `${url}?channelIds=${channelIds}`;

  return (dispatch) => { // eslint-disable-line arrow-body-style
    return axios.patch(url, { following: followingFlag })
      .then((response) => {
        const followingData = response.data;
        followingData.read = originalReadStatus;
        // triggering a follow event on threads with last event being a non channel event will change the value of read.
        // We need to override that changed value to the original correct value
        const normalized = normalize(followingData, event);
        dispatch(receiveUpdateFollowing(getInboxEventPayload(normalized)));
        if (followingFlag) NotificationService('updateFollow', response);
        if (!followingFlag) NotificationService('updateUnfollow', response);
        dispatch(threadService.util.invalidateTags(['Thread']));
      })
      .catch((err) => {
        console.error(err.response || err);
        if (followingFlag) NotificationService('updateFollow', err.response);
        if (!followingFlag) NotificationService('updateUnfollow', err.response);
        dispatch(setError(err.response || err));
      });
  };
}

export function refetchOnFocus() {
  return (dispatch, getState) => {
    if (BrowserHelpers.isMobile() || BrowserHelpers.isTouchDevice()) {
      const inboxOptions = getInboxOptionsInReducer(getState());
      dispatch(fetchInboxSections());
      if (inboxOptions.userId) {
        dispatch(threadService.util.invalidateTags(['Thread']));
      } else {
        dispatch(fetchInbox(inboxOptions));
      }
    }
  };
}

export function updateRead({
  userId,
  read = true,
  assigned = false,
  following = false,
  groupId = false,
  direct = false,
  channelIds = null,
  minimal = true,
  audit = true, // Used to prevent auditing of non-user initiated updates to read status
}) {
  let url = `/thread/updateRead/${userId}`;

  if (groupId) {
    url = `${url}?groupId=${groupId}`;
  } else if (following) {
    url = `${url}?following=1`;
  } else if (direct) {
    url = `${url}?direct=1`;
  } else if (assigned) {
    url = `${url}?assigned=1`;
  }

  if (channelIds) url = url.includes('?') ? `${url}&channelIds=${channelIds}` : `${url}?channelIds=${channelIds}`;
  if (minimal) url = `${url}&minimal=1`;
  if (!audit) url = `${url}&audit=0`;

  return (dispatch) =>
    axios.patch(url, { read })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));
      });
}

// Helper methods
function patchHipaaStatus(payload) {
  return axios.patch(`/users/${payload.userId}/hipaa`, payload);
}

function patchRhinoPayStatus(payload) {
  return axios.patch(`/users/${payload.userId}/rhinopayConsent`, payload);
}

function patchMarketingStatus(payload) {
  return axios.patch(`/users/${payload.userId}/marketingConsent`, payload);
}

function getInboxThreadViewPayload(
  normalizedUser,
  normalizedFromChannel,
  normalizedActiveChannel,
  selectedChannelIds,
  userId,
) {
  return {
    users: {
      ...normalizedUser.entities.users,
    },
    userIds: [
      ...new Set([
        ...getObjectKeys(normalizedUser.entities.users),
      ]),
    ],
    pageNo: 0,
    connectedParties: {
      ...normalizedUser.entities.connectedParties,
    },
    connectedPartyIds: getObjectKeys(normalizedUser.entities.connectedParties),
    emails: {
      ...normalizedUser.entities.emails,
    },
    emailIds: getObjectKeys(normalizedUser.entities.emails),
    phones: {
      ...normalizedUser.entities.phones,
      ...normalizedFromChannel.entities.phones,
      ...normalizedActiveChannel.entities.phones,
    },
    phoneIds: [
      ...new Set([
        ...getObjectKeys(normalizedUser.entities.phones),
        ...getObjectKeys(normalizedFromChannel.entities.phones),
        ...getObjectKeys(normalizedActiveChannel.entities.phones),
      ]),
    ],
    facebooks: {
      ...normalizedUser.entities.facebooks,
    },
    facebookIds: getObjectKeys(normalizedUser.entities.facebooks),
    instagrams: {
      ...normalizedUser.entities.instagrams,
    },
    instagramIds: getObjectKeys(normalizedUser.entities.instagrams),
    rhinograms: {
      ...normalizedUser.entities.rhinograms,
    },
    rhinogramIds: getObjectKeys(normalizedUser.entities.rhinograms),
    tags: {
      ...normalizedUser.entities.tags,
      ...normalizedFromChannel.entities.tags,
      ...normalizedActiveChannel.entities.tags,
    },
    tagIds: [
      ...new Set([
        ...getObjectKeys(normalizedUser.entities.tags),
        ...getObjectKeys(normalizedFromChannel.entities.tags),
        ...getObjectKeys(normalizedActiveChannel.entities.tags),
      ]),
    ],
    channels: {
      ...normalizedFromChannel.entities.channels,
      ...normalizedActiveChannel.entities.channels,
    },
    channelIds: [
      ...new Set([
        ...getObjectKeys(normalizedFromChannel.entities.channels),
        ...getObjectKeys(normalizedActiveChannel.entities.channels),
      ]),
    ],
    threadActiveChannelIds: normalizedActiveChannel.result,
    threadFromChannelIds: normalizedFromChannel.result,
    threadFilteredChannelIds: selectedChannelIds,
    organizations: {
      ...normalizedUser.entities.organizations,
    },
    organizationIds: getObjectKeys(normalizedUser.entities.organizations),
    groups: {
      ...normalizedUser.entities.groups,
    },
    groupIds: getObjectKeys(normalizedUser.entities.groups),
    roles: {
      ...normalizedUser.entities.roles,
    },
    roleIds: getObjectKeys(normalizedUser.entities.roles),
    userId,
  };
}

function getSecureMessageThreadPayload(normalizedThread, pageNo) {
  return {
    users: {
      ...normalizedThread.entities.senders,
      ...normalizedThread.entities.users,
    },
    userIds: [...new Set([...getObjectKeys(normalizedThread.entities.senders), ...getObjectKeys(normalizedThread.entities.users)])],
    events: {
      ...normalizedThread.entities.events,
    },
    eventIds: normalizedThread.result,
    pageNo,
    connectedParties: {
      ...normalizedThread.entities.connectedParties,
    },
    connectedPartyIds: getObjectKeys(normalizedThread.entities.connectedParties),
    emails: {
      ...normalizedThread.entities.emails,
    },
    emailIds: getObjectKeys(normalizedThread.entities.emails),
    phones: {
      ...normalizedThread.entities.phones,
    },
    phoneIds: getObjectKeys(normalizedThread.entities.phones),
    facebooks: {
      ...normalizedThread.entities.facebooks,
    },
    facebookIds: getObjectKeys(normalizedThread.entities.facebooks),
    instagrams: {
      ...normalizedThread.entities.instagrams,
    },
    instagramIds: getObjectKeys(normalizedThread.entities.instagrams),
    rhinograms: {
      ...normalizedThread.entities.rhinograms,
    },
    rhinogramIds: getObjectKeys(normalizedThread.entities.rhinograms),
    tags: {
      ...normalizedThread.entities.tags,
    },
    tagIds: getObjectKeys(normalizedThread.entities.tags),
    channels: {
      ...normalizedThread.entities.channels,
    },
    channelIds: getObjectKeys(normalizedThread.entities.channels),
    organizations: {
      ...normalizedThread.entities.organizations,
    },
    organizationIds: getObjectKeys(normalizedThread.entities.organizations),
    roles: {
      ...normalizedThread.entities.roles,
    },
    roleIds: getObjectKeys(normalizedThread.entities.roles),
  };
}

export function getInboxThreadPayload(normalizedThread, pageNo = 0) {
  return {
    users: {
      ...normalizedThread.entities.senders,
      ...normalizedThread.entities.users,
    },
    userIds: getObjectKeys(normalizedThread.entities.senders),
    phones: {
      ...normalizedThread.entities.phones,
    },
    phoneIds: getObjectKeys(normalizedThread.entities.phones),
    events: {
      ...normalizedThread.entities.events,
    },
    eventIds: normalizedThread.result,
    pageNo,
  };
}

function getInboxPayload(normalizedInbox, pageNo) {
  return {
    users: {
      ...normalizedInbox.entities.senders,
      ...normalizedInbox.entities.users,
    },
    userIds: [...new Set([...getObjectKeys(normalizedInbox.entities.senders), ...getObjectKeys(normalizedInbox.entities.users)])],
    phones: {
      ...normalizedInbox.entities.phones,
    },
    phoneIds: getObjectKeys(normalizedInbox.entities.phones),
    events: {
      ...normalizedInbox.entities.events,
    },
    inboxEventIds: normalizedInbox.result,
    pageNo,
    currentUserChannel: { ...normalizedInbox.entities.channels },
  };
}

function getMentionsInboxPayload(normalizedInbox, pageNo) {
  return {
    users: {
      ...normalizedInbox.entities.senders,
      ...normalizedInbox.entities.users,
    },
    phones: {
      ...normalizedInbox.entities.phones,
    },
    phoneIds: [...new Set(getObjectKeys(normalizedInbox.entities.phones))],
    userIds: [...new Set([...getObjectKeys(normalizedInbox.entities.senders), ...getObjectKeys(normalizedInbox.entities.users)])],
    eventMentions: {
      ...normalizedInbox.entities.mention,
    },
    mentionIds: normalizedInbox.result,
    pageNo,
  };
}

function getInboxEventPayload(normalizedEvent) {
  return {
    users: {
      ...normalizedEvent.entities.senders,
      ...normalizedEvent.entities.users,
    },
    userIds: [...new Set([...getObjectKeys(normalizedEvent.entities.senders), ...getObjectKeys(normalizedEvent.entities.users)])],
    phones: {
      ...normalizedEvent.entities.phones,
    },
    phoneIds: getObjectKeys(normalizedEvent.entities.phones),
    event: normalizedEvent.entities.events,
    eventId: normalizedEvent.result,
  };
}

function getQueryPayload(normalizedEvent) {
  return {
    event: normalizedEvent.entities.events,
    eventId: normalizedEvent.result,
  };
}

function getWebSocketEventPayload(normalizedEvent) {
  return {
    users: {
      ...normalizedEvent.entities.users,
      ...normalizedEvent.entities.senders,
    },
    userIds: [...new Set([...getObjectKeys(normalizedEvent.entities.users), ...getObjectKeys(normalizedEvent.entities.senders)])],
    phones: {
      ...normalizedEvent.entities.phones,
    },
    phoneIds: getObjectKeys(normalizedEvent.entities.phones),
    facebooks: {
      ...normalizedEvent.entities.facebooks,
    },
    facebookIds: getObjectKeys(normalizedEvent.entities.facebooks),
    instagrams: {
      ...normalizedEvent.entities.instagrams,
    },
    instagramIds: getObjectKeys(normalizedEvent.entities.instagrams),
    rhinograms: {
      ...normalizedEvent.entities.rhinograms,
    },
    rhinogramIds: getObjectKeys(normalizedEvent.entities.rhinograms),
    events: {
      ...normalizedEvent.entities.events,
    },
    event: normalizedEvent.entities.events,
    eventId: normalizedEvent.result,
  };
}

export function updateEventTranslatedText(translatedText, id) {
  const url = `/events/${id}`;
  return (dispatch) =>
    axios.patch(url, { translatedText })
      .then((response) => dispatch(receiveTranslatedText(response)))
      .catch((err) => dispatch(setError(err.response || err)));
}
// BULK ACTIONS

export function updateBulkToggleRead(payload, actionType, options) {
  return (dispatch, getState) => {
    dispatch(requestInboxData());
    const currentState = getState();
    const { groupId } = currentState.inbox;
    let url = '/bulkactions/updateRead';
    if (groupId) {
      url = `${url}?groupId=${groupId}`;
    } else if (options.following) {
      url = `${url}?following=1`;
    } else if (options.direct) {
      url = `${url}?direct=1`;
    } else if (options.assigned) {
      url = `${url}?assigned=1`;
    } if (options.mentions) {
      url = '/bulkactions/mentions';
    }
    return axios.patch(url, payload)
      .then((response) => {
        dispatch(fetchInbox(options)).then(() => {
          const updatedInboxCount = (payload && payload.eventIds && payload.eventIds.length) || 0;
          NotificationService(actionType, response, updatedInboxCount);
        });
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(clearInboxLoading());
        dispatch(setError(err.response || err));

        NotificationService(actionType, err.response);
      });
  };
}

export function updateBulkToggleFollow(payload, actionType, options) {
  return (dispatch) => {
    dispatch(requestInboxData());
    return axios.patch('/bulkactions/follow', payload)
      .then((response) => {
        dispatch(fetchInbox(options)).then(() => {
          const updatedInboxCount = (payload && payload.eventIds && payload.eventIds.length) || 0;
          NotificationService(actionType, response, updatedInboxCount);
        });
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(clearInboxLoading());
        dispatch(setError(err.response || err));
        NotificationService(actionType, err.response);
      });
  };
}

export function closeMentionBulkAction(payload, actionType, options) {
  return (dispatch) => {
    dispatch(requestInboxData());
    return axios.patch('/bulkactions/mentions', payload)
      .then((response) => {
        dispatch(fetchInbox(options)).then(() => {
          const closeCount = payload?.eventIds?.length || 0;
          NotificationService(actionType, response, closeCount);
        });
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(clearInboxLoading());
        dispatch(setError(err.response || err));
        NotificationService(actionType, err.response);
      });
  };
}

export function closeConversationBulkAction(payload, action, options) {
  return (dispatch) => {
    dispatch(requestInboxData());
    return axios.post('/bulkactions/done', payload)
      .then((response) => {
        dispatch(fetchInbox(options)).then(() => {
          const updatedInboxCount = (payload && payload.length) || 0;
          NotificationService(action, response, updatedInboxCount);
        });
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(clearInboxLoading());
        dispatch(setError(err.response || err));
        NotificationService(action, err.response);
      });
  };
}

export function createBulkAssignmentGroup(payload, options) {
  return (dispatch) => {
    dispatch(requestInboxData());
    return axios.post('/bulkactions/assignGroup', payload)
      .then((response) => {
        dispatch(fetchInbox(options)).then(() => {
          const updatedInboxCount = (payload && payload.length) || 0;
          NotificationService('bulkAssignment', response, updatedInboxCount);
        });
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(clearInboxLoading());
        dispatch(setError(err.response || err));
        NotificationService('bulkAssignment', err.response);
      });
  };
}

export function createBulkAssignmentSelf(payload, options) {
  return (dispatch) => {
    dispatch(requestInboxData());
    return axios.post('/bulkactions/assignSelf', payload)
      .then((response) => {
        dispatch(fetchInbox(options)).then(() => {
          const updatedInboxCount = (payload && payload.length) || 0;
          NotificationService('bulkAssignment', response, updatedInboxCount);
        });
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(clearInboxLoading());
        dispatch(setError(err.response || err));
        NotificationService('bulkAssignment', err.response);
      });
  };
}

export function createBulkAssignmentMember(payload, options) {
  return (dispatch) => {
    dispatch(requestInboxData());
    return axios.post('/bulkactions/assignMember', payload)
      .then((response) => {
        dispatch(fetchInbox(options)).then(() => {
          const updatedInboxCount = (payload && payload.length) || 0;
          NotificationService('bulkAssignment', response, updatedInboxCount);
        });
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(clearInboxLoading());
        dispatch(setError(err.response || err));
        NotificationService('bulkAssignment', err.response);
      });
  };
}

export function updateReadStatus(readOptions) {
  return async (dispatch) => {
    // call the api only when there is any channel selected in the thread
    // we dont need to call the API when the thread is new or empty
    if (readOptions.channelIds?.length > 0) {
      await dispatch(updateRead(readOptions));
      // Update badge count
      dispatch(fetchInboxSections());
    }
  };
}

function getPayLoadforMessageType({ eventType, messageTranslation = {}, messageText, hipaaRequestType }) {
  return async (dispatch, getState) => {
    let tempEventPayload;
    const messageContext = {};
    const optimisticId = DataHelpers.generateUUID(); // optimistic updates
    const currentState = getState();
    const mostRecentEvent = getMostRecentEvent(currentState);
    const activeUser = getActiveUser(currentState);
    const userOrganization = getLoggedInUserOrganization(currentState);
    const inboxContext = getInboxContext(currentState);
    const isLimitedProvider = userHasLimitedProviderRole(currentState);
    const {
      auth,
      inbox: {
        activeSecureNotificationToChannelId,
        activeSecureFromChannelId,
        activeSecureNotificationFromChannelId,
        activeFromChannelId,
        activeToChannelId,
        groupId,
        openAssignments,
      },
      thread: {
        messageForms,
        sharelinkFiles,
        attachments,
      },
      phone: {
        phones,
      },
      facebook: {
        facebooks,
      },
      instagram: {
        instagrams,
      },
    } = currentState;
    const channelType = eventType === 'messages' ? ChannelHelpers.getChannelClass(activeToChannelId) :
      ChannelHelpers.getChannelClass(activeSecureNotificationToChannelId);
    const communicationId = eventType === 'messages' ? ChannelHelpers.getCommunicationId(activeToChannelId) :
      ChannelHelpers.getCommunicationId(activeSecureNotificationToChannelId);
    const assigned = mostRecentEvent?.assigned || false;
    const following = mostRecentEvent?.following || false;
    const message = messageTranslation.isMessageTranslated ? messageTranslation.translatedText : messageText;
    function getDestination() {
      if (channelType === 'facebook') {
        return facebooks[communicationId]?.value;
      }
      if (channelType === 'instagram') {
        return instagrams[communicationId]?.value;
      }
      if (channelType === 'sms') {
        return phones[communicationId]?.value;
      }
      return activeToChannelId;
    }
    let payload = eventType === 'secureMessages' ? {
      optimisticId,
      userId: activeUser.id,
      text: messageText,
      secureChannelId: activeSecureFromChannelId,
      notificationChannelId: activeSecureNotificationFromChannelId,
      ...channelType === 'sms' && {
        notificationPhoneId: communicationId,
      },
      ...channelType === 'facebook' && {
        notificationFacebookId: communicationId,
      },
      ...channelType === 'instagram' && {
        notificationInstagramId: communicationId,
      },
      attachments,
      messageContext,
    } : {
      organizationId: currentState.auth.currentOrg,
      channelId: activeFromChannelId,
      fromUserId: currentState.auth.currentUser,
      toUserId: activeUser.id,
      destination: getDestination(),
      text: messageTranslation.isMessageTranslated ? messageTranslation.translatedText : messageText,
      translatedText: messageTranslation.isMessageTranslated ? messageText : undefined,
      languageId: messageTranslation?.languageId,
      type: hipaaRequestType ?? JobType.Conversation,
      attachments,
      optimisticId,
    };

    if (eventType === 'messages') {
      // If auto assignment is enabled, assign or re-assign the message to the member
      // Otherwise, if the sender is a limited provider create a new member or multi member assignment to the limited provider
      const autoAssign = userOrganization.allowAutoConversationAssignment && (inboxContext === 'group' || inboxContext === 'direct');
      const limitedProviderAssign = isLimitedProvider && inboxContext === 'assigned';
      const currentAssignmentExists = limitedProviderAssign ?
        openAssignments.some((assignment) => assignment.channelId === activeFromChannelId && assignment.assignedUserId === currentState.auth.currentUser)
        : false;

      if (!currentAssignmentExists && (autoAssign || limitedProviderAssign)) {
        let autoAssignmentType = inboxContext === 'group' ? AutoAssignmentType.Group : AutoAssignmentType.Member;
        if (limitedProviderAssign) {
          autoAssignmentType = AutoAssignmentType.MultiMember;
        }

        payload = {
          ...payload,
          autoAssignmentType,
          groupId: inboxContext === 'group' ? groupId : null,
          assigned,
          following,
        };
      }

      tempEventPayload = {
        optimisticId,
        userId: activeUser.id,
        channelId: activeFromChannelId,
        text: message,
        languageId: Number(messageTranslation.languageId) || null,
        translatedText: messageTranslation.isMessageTranslated ? messageText : null,
        ...channelType === 'sms' && {
          phoneId: communicationId,
        },
        ...channelType === 'facebook' && {
          facebookId: communicationId,
        },
        ...channelType === 'instagram' && {
          instagramId: communicationId,
        },
        attachments,
        assigned,
        following,
      };
    } else {
      tempEventPayload = {
        ...payload,
        assigned,
        following,
      };
    }
    if (sharelinkFiles.length > 0) {
      sharelinkFiles.forEach((file) => {
        payload.text += `${payload.text?.length === 0 ? '' : '\n'}${file.sharelink}`;
        tempEventPayload.text += `${tempEventPayload.text.length === 0 ? '' : '\n'}${file.sharelink}`;
      });
    }
    if (messageForms.length > 0) {
      const { rhinoForm, rhinoFormContent } = await dispatch(handleSendForms(messageForms, activeUser.id, auth.currentUser));
      if (eventType === 'secureMessages') {
        payload.rhinoForm = rhinoForm;
      } else {
        payload.formContent = rhinoForm;
      }
      tempEventPayload.rhinoFormContent = rhinoFormContent;
    }
    if (attachments.length > 0) {
      const vCardIds = [];
      for (let i = 0; i < attachments.length; i += 1) {
        if (attachments[i].type === AppConstants.VCARD_FILE_TYPE) {
          vCardIds.push(attachments[i].attachmentUrl);
        }
      }
      const filteredAttachments = attachments.filter((attachment) => attachment.type !== AppConstants.VCARD_FILE_TYPE);
      payload.attachments = filteredAttachments;
      payload.context = { vCardIds };
    }
    return { payload, tempEventPayload };
  };
}

export function handleSendMessage({ eventType = 'messages', messageTranslation, messageText, threadOptions, hipaaRequestType }) {
  return async (dispatch, getState) => {
    dispatch(ThreadReducer.setSendingMessageLoading());
    const { payload, tempEventPayload } = await dispatch(getPayLoadforMessageType({ eventType, messageTranslation, messageText, hipaaRequestType }));
    const inboxOptions = getInboxOptionsInReducer(getState());
    let messageContext = {};
    if (eventType === 'messages') messageContext.userId = inboxOptions.userId;
    else {
      messageContext = {
        ...inboxOptions,
        isAssigned: inboxOptions.assigned,
      };
    }

    dispatch(createTempInboxEvent(tempEventPayload, eventType, hipaaRequestType));
    await dispatch(createInboxEvent(payload, eventType, messageContext, threadOptions, tempEventPayload));
    if (inboxOptions.all) {
      dispatch(fetchOpenAssignments(inboxOptions.userId));
    }
  };
}

function handleSendForms(messageForms, activeUserId, currentUserId) {
  return async (dispatch) => {
    const payload = {
      rhinoForm: [],
      rhinoFormContent: [],
    };
    const sendForms = messageForms.map(async (form) => {
      const response = await sendFormById(`${form.formId}`, { patientId: activeUserId, senderId: currentUserId });
      if (response) {
        payload.rhinoForm.push({
          formUrl: response.data?.formUrl,
          title: form.title,
          formId: response.data?._id,
          contactId: activeUserId,
        });
        payload.rhinoFormContent.push({
          title: form.title,
          formId: response.data?._id,
        });
      }
      return response;
    });
    try {
      await Promise.all(sendForms);
      dispatch(FormReducer.refetchContactForms());
    } catch (err) {
      NotificationService('formSend', err.response);
    }
    return payload;
  };
}

export function fetchCompleteThreadView({ locationActivePanel, locationOptions, userId }) {
  return async (dispatch) => {
    dispatch(requestInitialThreadView(getInitialThreadPayload(locationOptions, locationActivePanel)));
    await dispatch(fetchInboxThreadView(locationOptions));
    const isOnAllRoute = (!locationOptions.groupId && !locationOptions.direct && !locationOptions.mentions && !locationOptions.following);
    if (isOnAllRoute || locationOptions.assigned) {
      dispatch(fetchOpenAssignments(userId));
    }
    await dispatch(fetchAssignees(locationOptions));
    dispatch(getRecommendedLanguageId(userId));
  };
}

function getInitialThreadPayload(locationOptions, locationActivePanel) {
  return {
    ...locationOptions,
    ...locationActivePanel && {
      locationActivePanel,
    },
  };
}
