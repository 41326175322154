import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import {
  Checkbox,
  CheckboxGroup,
} from 'rhinostyle';
import { setSelectedEventIds } from '../../reducers/savedContentReducer';
import { getActiveUser, getLoggedInUser } from '../../selectors/userSelectors';
import { useEventScroll, useThreadEventShaper } from '../../hooks';
import MessageEvent from './MessageEvent';
import RouteEvent from './RouteEvent';
import AssignmentEvent from './AssignmentEvent';
import CallEvent from './CallEvent';
import SavedContentEvent from './SavedContentEvent';
import ConsentEvent from './ConsentEvent';
import RhinoformSendEvent from './RhinoformSendEvent';
import AppointmentNotificationEvent from './AppointmentNotificationEvent';
import MergeEvent from './MergeEvent';
import VideoEvent from './VideoEvent';
import StoryMentionEvent from './StoryMentionEvent';
import { getUnreadInboxMention } from '../../selectors/uiSelectors';

const ThreadEvent = (props) => {
  const {
    event,
    isSelectionPreviewModeEnabled,
    isContentSelected,
    isSupportedContent,
    isSelectionModeEnabled,
    scrollToTop = false,
    scrollToBottom = false,
    searchText,
    unreadMention,
    currentUser,
  } = props;
  const eventRef = useRef(null);
  const location = useLocation();
  const threadEvent = useThreadEventShaper({ event });
  useEventScroll({
    scrollToTop,
    scrollToBottom,
    eventRef,
    eventId: event.id,
    unreadMention,
  });

  const isDefaultMessage = threadEvent.isNoteEvent || threadEvent.isSecureEvent || threadEvent.isMessageEvent;
  const isRenderRouteEvent = threadEvent.isPatientInitiatedMessage && threadEvent.suggestedTextClassificationType && !currentUser?.isCcr && !location.pathname.includes('all');
  const dispatch = useDispatch();
  const isOptimisticEvent = typeof threadEvent.id === 'string' && threadEvent.id === threadEvent.optimisticId;
  const isAvailableForSelection = isSelectionModeEnabled && !isSupportedContent && !isOptimisticEvent;
  const eventElementId = searchText ? `js-search__item-${event.id}` : `js-convo__item-${event.id}`;
  const convoEventWrapperClasses = cx('convo__event__wrapper', {
    'convo__event__wrapper--inverted': isAvailableForSelection && !isSelectionPreviewModeEnabled && threadEvent.isIncoming,
  });

  const eventClasses = cx('', {
    convo__event: !isDefaultMessage,
    convo__item: isDefaultMessage,
    'convo__item--active-search-result': searchText,
    'convo__item--selection-mode-enabled': isAvailableForSelection,
    'convo__item--inbound': isDefaultMessage && threadEvent.isIncoming,
    'convo__item--outbound': !threadEvent.isIncoming && isDefaultMessage,
    'convo__item--pim': threadEvent.isPatientInitiatedMessage,
  });
  const routeEventClasses = cx('convo__suggested__route', {
    'convo__item--selection-mode-enabled': isAvailableForSelection,
  });

  const eventCheckboxClasses = cx('convo__item__checkbox', {
    'convo__item__checkbox--left': isAvailableForSelection && !isSelectionPreviewModeEnabled && threadEvent.isIncoming,
    'convo__item__checkbox--right': isAvailableForSelection && !isSelectionPreviewModeEnabled && !threadEvent.isIncoming,
  });

  function handleSelectContent() {
    if (isAvailableForSelection && !isSelectionPreviewModeEnabled) {
      dispatch(setSelectedEventIds(event.id));
    }
  }

  function handleClick() {
    if (isAvailableForSelection && !isSelectionPreviewModeEnabled) {
      dispatch(setSelectedEventIds(event.id));
    }
  }

  const renderSelectContentCheckbox = () => isAvailableForSelection && !isSelectionPreviewModeEnabled && (
    <div className={eventCheckboxClasses}>
      <CheckboxGroup>
        <Checkbox
          onChange={handleSelectContent}
          isChecked={isContentSelected}
          name={`selectEvent${event.id}`}
          label="&#8203;"
        />
      </CheckboxGroup>
    </div>
  );

  const renderEvent = () => {
    if (threadEvent.isStoryMention) {
      return <StoryMentionEvent event={threadEvent} searchText={searchText} />;
    }
    if (isDefaultMessage) {
      return <MessageEvent event={threadEvent} searchText={searchText} />;
    }
    if (threadEvent.isSavedContentEvent || threadEvent.isSentSavedContentEvent || threadEvent.isCopyContentEvent) {
      return <SavedContentEvent event={threadEvent} />;
    }
    if (threadEvent.isHipaaEvent || threadEvent.isRhinopayEvent || threadEvent.isMarketingEvent) {
      return <ConsentEvent event={threadEvent} />;
    }
    if (threadEvent.isAppointmentNotificationEvent) {
      return <AppointmentNotificationEvent event={threadEvent} />;
    }
    if (threadEvent.isMergeEvent) {
      return <MergeEvent event={threadEvent} />;
    }
    if (threadEvent.isVideoChatEvent) {
      return <VideoEvent event={threadEvent} />;
    }
    if (threadEvent.isAssignmentEvent) {
      return <AssignmentEvent event={threadEvent} />;
    }
    if (threadEvent.isCallEvent) {
      return <CallEvent event={threadEvent} />;
    }
    if (threadEvent.isRhinoformSendEvent) {
      return <RhinoformSendEvent event={threadEvent} />;
    }
    return null;
  };

  const renderRouteEvent = () => (
    <div>
      <div className={convoEventWrapperClasses}>
        <div
          className={routeEventClasses}
          id={eventElementId}
          key={event.id}
          onClick={handleClick}
        >
          <RouteEvent event={threadEvent} />
        </div>
        {renderSelectContentCheckbox()}
      </div>
    </div>
  );

  return (
    <>
      <div>
        <div className={convoEventWrapperClasses}>
          <div
            ref={eventRef}
            className={eventClasses}
            id={eventElementId}
            key={event.id}
            onClick={handleClick}

          >
            {renderEvent()}
          </div>
          {renderSelectContentCheckbox()}
        </div>
      </div>
      {isRenderRouteEvent && (renderRouteEvent())}
    </>
  );
};

const mapStateToProps = (state, props) => {
  const {
    savedContent,
  } = state;
  return {
    activeUser: getActiveUser(state),
    currentUser: getLoggedInUser(state),
    isContentSelected: savedContent.selectedEventIds.includes(props.eventId),
    isSelectionModeEnabled: savedContent.isSelectionModeEnabled,
    isSelectionPreviewModeEnabled: savedContent.isSelectionPreviewModeEnabled,
    unreadMention: getUnreadInboxMention(state, { eventId: props.event?.id }),
  };
};

ThreadEvent.propTypes = {
  isContentSelected: PropTypes.bool,
  isSelectionModeEnabled: PropTypes.bool,
  isSelectionPreviewModeEnabled: PropTypes.bool,
  event: PropTypes.object,
  searchText: PropTypes.string,
  isSupportedContent: PropTypes.bool,
  scrollToTop: PropTypes.bool,
  scrollToBottom: PropTypes.bool,
  unreadMention: PropTypes.object,
  currentUser: PropTypes.object,
};
export default connect(mapStateToProps)(ThreadEvent);
