export function getPageTitle(location) {
  let title = null;

  if (location.startsWith('/chat')) return 'Team Messages'; // TODO: this section can probably be cleaned up
  if (location.startsWith('/contacts')) return 'Contacts';
  if (location.startsWith('/contact-list')) return 'Saved Lists';
  if (location.startsWith('/secure/profile')) return 'My Profile';
  if (location.startsWith('/secure')) return 'Secure Message';
  if (location.startsWith('/settings/organization/groups')) return 'Groups';
  if (location.startsWith('/settings/organization/members')) return 'Members';
  if (location.startsWith('/settings/organization/library/templates')) return 'Templates';
  if (location.startsWith('/settings/organization/out-of-office')) return 'Out of Office';
  if (location.startsWith('/settings/organization/channels')) return 'Organization Channels';
  if (location.startsWith('/settings/organization/offices')) return 'Organization Offices';
  if (location.startsWith('/settings/organization/library/forms')) return 'Forms';
  if (location.startsWith('/managers/routing-manager')) return 'Routing Manager';
  if (location.startsWith('/settings/organization/hipaa-campaigns')) return 'HIPAA Campaigns';
  if (location.startsWith('/settings/organization/appointment-reminders')) return 'Appointment Reminders';
  if (location.startsWith('/settings/organization/prescription-notifications')) return 'Prescription Notifications';

  switch (location) {
    case '/analytics':
      title = 'Analytics Dashboard';
      break;
    case '/login':
      title = 'Login';
      break;
    case '/auth':
      title = 'Login';
      break;
    case '/forgot-password':
      title = 'Forgot Password';
      break;
    case '/selectorg':
      title = 'Organizations';
      break;
    case '/accountsetup':
      title = 'Account Setup';
      break;
    case '/systemalert':
      title = 'System Alert';
      break;
    case '/settings/profile':
      title = 'My Profile';
      break;
    case '/settings/preferences':
      title = 'My Preferences';
      break;
    case '/settings/organization/profile':
      title = 'Organization Profile';
      break;
    case '/settings/organization/roles':
      title = 'Roles';
      break;
    case '/settings/organization/preferences':
      title = 'Organization Preferences';
      break;
    case '/managers/appointment-manager':
      title = 'Appointment Manager';
      break;
    case '/settings/organization/tags':
      title = 'Tags';
      break;
    case '/settings/organization/billing':
      title = 'Organization Billing';
      break;
    case '/analytics/auditLog':
      title = 'Audit Log';
      break;
    case '/analytics/conversation-activity':
      title = 'Conversation Activity';
      break;
    case '/analytics/messaging':
      title = 'Messaging';
      break;
    case '/analytics/hipaa-campaigns':
      title = 'HIPAA Campaigns';
      break;
    case '/managers/payment-requests':
      title = 'RhinoPay Manager';
      break;
    case '/managers/blast-manager':
      title = 'Blast Manager';
      break;
    case '/managers/prescriptions':
      title = 'Prescription Manager';
      break;
    case '/managers/rhinoforms-manager':
      title = 'RhinoForms Manager';
      break;
    case '/settings/organization/integration-analytics':
      title = 'Integration Analytics';
      break;
    case '/settings/organization/appointment-data-setup':
      title = 'Appointment Data';
      break;
    case '/admin':
      title = 'Organizations';
      break;
    default:
      title = 'Patient Messages';
      break;
  }

  return title;
}

export function getPageClassName(location) {
  let className = null;

  switch (location) {
    case 'inbox':
      className = 'nav-inbox';
      break;
    case 'chat':
      className = 'nav-chat';
      break;
    case 'contacts':
      className = 'nav-contacts';
      break;
    case 'contact-list':
      className = 'nav-contact-list';
      break;
    case 'selectorg':
      className = 'nav-select-org';
      break;
    case 'accountsetup':
      className = 'nav-account-setup';
      break;
    case 'systemalert':
      className = 'nav-system-alert';
      break;
    case 'settings':
      className = 'nav-settings';
      break;
    case 'secure':
      className = 'nav-secure';
      break;
    case 'analytics':
      className = 'nav-analytics';
      break;
    case 'analytics-conversation-activity':
      className = 'nav-conversation-activity';
      break;
    case 'analytics-messaging':
      className = 'nav-messaging-analytics';
      break;
    case 'settings-nav-active':
      className = 'app-navigation-sidebar--settings';
      break;
    default:
      className = '';
      break;
  }
  return className;
}

export function getQueryParams(query) {
  const queryString = query.slice(1); // strip off '?' from search string
  const obj = {};
  if (queryString) {
    queryString.split('&').forEach((item) => {
      const array = item.split('=');
      if (array && array.length > 1) obj[array[0]] = array[1].split('_').join(' ');
      // below is to prevent org names containing ampersands from having the second half of the name removed,
      // e.g. the org Johnson & Johnson (good), the org Johnson (bad)
      if (array && array.length === 1 && obj.officeName) obj.officeName += `& ${array[0].slice(1).split('_').join(' ')}`;
    });
  }

  return obj;
}

export function getInboxOptions(params, location) {
  const pathName = location.pathname.substring(1).split('/');
  const groupId = Number(params.groupId) || null;
  const userId = Number(params.userId) || null;
  const following = pathName.includes('following');
  const direct = pathName.includes('direct');
  const mentions = pathName.includes('mentions');
  const all = pathName.includes('all');
  const assigned = !groupId && !following && !direct && !all && !mentions;
  return { groupId, userId, following, direct, assigned, mentions };
}
/**
 * This is overly simplistic and makes assumptions that will not work
 * for all cases. For example, a domain like .co.uk
 */
export function getDomain() {
  const { hostname } = window.location;
  const splitHostname = hostname.split('.');
  const hasSubdomain = (splitHostname.length - 1) >= 2;
  let domain = hostname;

  if (hasSubdomain) {
    splitHostname.shift(); // Remove sub-domain
    domain = splitHostname.join('.');
  }

  return domain;
}

export function getURLParam(key) {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  return params.get(key);
}
