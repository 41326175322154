import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  DropdownMenuItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  DateRangeDropdown,
  Dropdown,
  UtilityInlineGrid,
  FormLabel,
} from 'rhinostyle';
import moment from 'moment-timezone';
import {
  PAST_REPORT_OPTIONS, REPORTS, REPORT_TYPES,
} from '../constants/PrescriptionReportConstants';
import { calculatePastDateRange } from '../helpers/AppointmentHelpers';
import { useGetRunReportMutation } from '../services/prescriptionReportService';
import NotificationService from '../services/NotificationService';

const ReportModal = (props) => {
  const {
    handleToggleModal,
    isOpen,
  } = props;

  const dateRange = calculatePastDateRange(1);
  const initialReportOptions = {
    selectedReportKey: 1,
    selectedReportName: 'OptumHUMANAReport',
    selectedReportTypeKey: 2,
    selectedReportTypeName: 'PDF',
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    activeKey: 2,
    errors: {},
  };

  const [runReportOptions, setRunReportOptions] = useState(initialReportOptions);
  const [runReport] = useGetRunReportMutation();

  const modalTitle = 'Run & Email Reports';
  const modalSubTitle = 'Select Report Type and specify Time Period';
  const modalSaveText = 'Run & Email Report';
  const minDate = moment().subtract(1, 'year').subtract(1, 'days').startOf('day');
  const maxDate = moment();

  useEffect(() => {
    if (isOpen) {
      setRunReportOptions(initialReportOptions);
    }
  }, [isOpen]);

  async function selectDate(date) {
    let { startDate, endDate } = date;
    if (date.activeReportKey === 5) {
      startDate = moment(startDate).startOf('day');
      endDate = moment(endDate).endOf('day');
    }
    setRunReportOptions((current) => ({ ...current, activeKey: date.activeKey, startDate, endDate }));
  }

  async function handleRunReport() {
    const response =
      await runReport({
        reportName: runReportOptions.selectedReportName,
        fromDate: runReportOptions.startDate.format(),
        toDate: runReportOptions.endDate.format(),
        reportType: runReportOptions.selectedReportTypeName,
      });

    if (response?.data) {
      if (response.data?.object?.status === 'success') {
        handleToggleModal(false);
        NotificationService('runPrescriptionReports', { status: 200 });
      } else {
        NotificationService('runPrescriptionReports', { status: 'default' });
      }
    } else {
      handleToggleModal(false);
      NotificationService('runPrescriptionReports', response?.error);
    }
  }

  function handleSelectReport(selectedReportId) {
    const reportObj = REPORTS.find((obj) => obj.id === selectedReportId);

    if (reportObj) {
      setRunReportOptions((current) => ({ ...current, selectedReportName: reportObj.key, selectedReportKey: selectedReportId }));
    }
  }

  function handleSelectReportType(selectedReportTpeId) {
    const reportObj = REPORT_TYPES.find((obj) => obj.id === selectedReportTpeId);

    if (reportObj) {
      setRunReportOptions((current) => ({ ...current, selectedReportTypeName: reportObj.key, selectedReportTypeKey: selectedReportTpeId }));
    }
  }

  const renderReportDropDown = () => (
    <div>
      <FormLabel
        className="variable-message__label u-m-t u-m-b-small"
        id="dropDown"
        required
      >
        Report Type:
      </FormLabel>
      <Dropdown
        position="bottom"
        activeKey={runReportOptions.selectedReportKey}
        name="Reports"
        onSelect={handleSelectReport}
        wide
      >
        {REPORTS.map((report) => (
          <DropdownMenuItem
            key={report.key}
            id={report.id}
            label={report.label}
          />
        ))}
      </Dropdown>
    </div>
  );

  const renderReportTypeDropDown = () => (
    <div>
      <FormLabel
        className="variable-message__label u-m-t u-m-b-small"
        id="dropDown"
        required
      >
        Report Type:
      </FormLabel>
      <Dropdown
        position="bottom"
        activeKey={runReportOptions.selectedReportTypeKey}
        label="Select Report Type"
        name="Report Types"
        onSelect={handleSelectReportType}
        wide
      >
        {REPORT_TYPES.map((reportType) => (
          <DropdownMenuItem
            key={reportType.key}
            id={reportType.id}
            label={reportType.label}
          />
        ))}
      </Dropdown>
    </div>
  );

  const renderDateView = () => (
    <div className="filter-page__dropdown" data-cypress="datePicker">
      <FormLabel
        className="variable-message__label u-m-t u-m-b-small"
        id="datePicker"
        required
      >
        Time Period:
      </FormLabel>
      <DateRangeDropdown
        name="Prescription REPORT Date Picker"
        startDate={moment(runReportOptions.startDate)}
        endDate={moment(runReportOptions.endDate)}
        selectDate={selectDate}
        dateLabelFormat="MMM D, YYYY"
        minDate={minDate}
        maxDate={maxDate}
        activeKey={runReportOptions.activeKey}
        position="left"
        dropdownMenuItems={PAST_REPORT_OPTIONS}
        selectDateRange={calculatePastDateRange}
        isCustomDate
      />
    </div>
  );

  const renderModalFooter = () => (
    <UtilityInlineGrid align="right">
      <Button
        type="link"
        className="u-p-l-0"
        onClick={() => handleToggleModal(false)}
      >
        Cancel
      </Button>
      <Button
        type="primary"
        onClick={handleRunReport}
        className="button button--primary"
      >
        {modalSaveText}
      </Button>
    </UtilityInlineGrid>
  );

  return (
    <Modal open={isOpen}>
      <ModalHeader
        onClose={() => handleToggleModal(false)}
        title={modalTitle}
        titleSub={modalSubTitle}
      />
      <ModalBody>
        <UtilityInlineGrid align="left">
          {renderReportDropDown()}
          {renderReportTypeDropDown()}
          {renderDateView()}
        </UtilityInlineGrid>
      </ModalBody>
      <ModalFooter>
        {renderModalFooter()}
      </ModalFooter>
    </Modal>
  );
};

ReportModal.propTypes = {
  handleToggleModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default ReportModal;
