// Types
export const TYPE_HOME = 1;
export const TYPE_WORK = 2;
export const TYPE_CELL = 3;
export const TYPE_EMAIL_HOME = 4;
export const TYPE_EMAIL_WORK = 42;
// image types
export const TYPE_IMAGE_JPG = 5;
export const TYPE_IMAGE_JPEG = 6;
export const TYPE_IMAGE_PNG = 7;
export const TYPE_IMAGE_BMP = 8;
export const TYPE_IMAGE_GIF = 9;
// video types
export const TYPE_VIDEO_3GP = 112;
export const TYPE_VIDEO_MP4 = 113;
export const TYPE_VIDEO_MPEG = 114;
export const TYPE_VIDEO_OGG = 115;
export const TYPE_VIDEO_QUICKTIME = 116;
export const TYPE_VIDEO_WEBM = 117;
export const TYPE_VIDEO_M4V = 118;
export const TYPE_VIDEO_ASF = 119;
export const TYPE_VIDEO_MSVIDEO = 120;
export const TYPE_VIDEO_WMV = 121;

// vCard type
export const TYPE_VCARD = 162;

export const TYPE_VISA = 14;
export const TYPE_MASTERCARD = 15;
export const TYPE_AMEX = 16;
export const TYPE_DISCOVER = 17;
export const TYPE_PATIENT = 18;
export const TYPE_MEMBER = 19;
export const TYPE_ORGANIZATION = 135;
export const TYPE_CONNECTED_PARTY = 20;
export const TYPE_EMAIL = 21;
export const TYPE_UNKNOWN = 22;
export const TYPE_DIRECT_MESSAGE = 23;
export const TYPE_PARENT_STEPPARENT = 30;
export const TYPE_USER_OTHER = 36;
export const TYPE_ALERT_TYPE_URGENT = 46;
export const TYPE_ALERT_TYPE_INFO = 47;
export const TYPE_ATTACHMENT_PDF = 45;
export const TYPE_ATTACHMENT_FORM = 128;

// Channel
export const TYPE_SMS = 10;
export const TYPE_SECURE = 54;
export const TYPE_FACEBOOK = 13;
export const TYPE_TWILIO = 12;
export const TYPE_RHINOGRAM = 134;
export const TYPE_INSTAGRAM = 163;

// Group
export const TYPE_GROUP_INBOX = 59;
export const TYPE_GROUP_CHAT = 60;
export const TYPE_GROUP_INBOX_AND_CHAT = 61;

// Events
export const TYPE_EVENT_MESSAGE = 26;
export const TYPE_EVENT_NOTE = 27;
export const TYPE_EVENT_SECURE = 28;
export const TYPE_EVENT_HIPAA_CONSENT = 51;
export const TYPE_EVENT_APPOINTMENT_REMINDER = 53;
export const TYPE_EVENT_ASSIGNMENT = 67;
export const TYPE_EVENT_RHINOPAY_CONSENT = 90;
export const TYPE_EVENT_SAVED_CONTENT = 103;
export const TYPE_EVENT_MARKETING_CONSENT = 104;
export const TYPE_VIDEO_CHAT_COMPLETED = 110;
export const TYPE_SAVED_CONTENT_SENT = 123;
export const TYPE_MERGE_CONTACT = 125;
export const TYPE_COPY_CONTENT = 127;
export const TYPE_EVENT_FORM = 129;
export const TYPE_CALL_COMPLETED = 137;
export const TYPE_EVENT_PRESCRIPTION_REMINDER = 161;
export const TYPE_EVENT_HIPAA_REQUEST = 169;

// HIPAA Consent Statuses
export const TYPE_HIPAA_CONSENT_PENDING = 48;
export const TYPE_HIPAA_CONSENT_GRANTED = 49;
export const TYPE_HIPAA_CONSENT_DECLINED = 50;

// Appointment Statuses
export const TYPE_APPOINTMENT_UNCONFIRMED = 81;
export const TYPE_APPOINTMENT_CONFIRMED = 82;
export const TYPE_APPOINTMENT_CANCELLED = 83;
export const TYPE_APPOINTMENT_STATUS_UPDATED_BY_MEMBER = 88;

// Event Statuses
export const TYPE_EVENT_STATUS_ASSIGNMENT = 37;
export const TYPE_EVENT_STATUS_DELIVERED = 38;
export const TYPE_EVENT_STATUS_CONFIRMED_DELIVERED = 39;
export const TYPE_EVENT_STATUS_ERROR = 40;
export const TYPE_EVENT_STATUS_CONFIRMED_ERROR = 41;

// Tags
export const TYPE_TAG_LOCATIONS = 55;
export const TYPE_TAG_ROLES = 56;
export const TYPE_TAG_DEPARTMENTS = 57;
export const TYPE_TAG_CUSTOM = 58;

// Type Classes
export const TYPE_CLASS_PHONE = 1;
export const TYPE_CLASS_EMAIL = 2;
export const TYPE_CLASS_FACEBOOK = 7;
export const TYPE_CLASS_RHINOGRAM = 31;
export const TYPE_CLASS_USER = 6;
export const TYPE_CLASS_CONNECTION = 10;
export const TYPE_CLASS_INSTAGRAM = 40;

// Integration Partners
export const TYPE_INTEGRATION_MI7 = 70;
export const TYPE_INTEGRATION_SIKKA = 71;
export const TYPE_INTEGRATION_TOPS = 72;
export const TYPE_INTEGRATION_CLOUD_9 = 76;
export const TYPE_INTEGRATION_EPIC = 101;
export const TYPE_INTEGRATION_FOCUS_ORTHO = 102;
export const TYPE_INTEGRATION_CSV_IMPORT = 111;
export const TYPE_INTEGRATION_NEXTECH = 109;
export const TYPE_INTEGRATION_OPEN_DENTAL = 124;
export const TYPE_INTEGRATION_DOLPHIN = 136;
export const TYPE_INTEGRATION_PIONEER_RX = 149;
export const TYPE_INTEGRATION_PROGNOCIS = 166;
export const TYPE_INTEGRATION_AXIUM = 173;

// Other
export const TYPE_NON_MEMBERS = `${TYPE_PATIENT},${TYPE_UNKNOWN},${TYPE_USER_OTHER}, ${TYPE_ORGANIZATION}`;

// Type Templates
export const TYPE_TEMPLATE_SYSTEM = 94;
export const TYPE_TEMPLATE_CONTACT = 95;
export const TYPE_TEMPLATE_TEAM = 96;

// RhinoPayStatusType
export const TYPE_RHINOPAY_CONSENT_PENDING = 91;
export const TYPE_RHINOPAY_CONSENT_GRANTED = 92;
export const TYPE_RHINOPAY_CONSENT_DECLINED = 93;

// MarketingStatusType
export const TYPE_MARKETING_CONSENT_PENDING = 105;
export const TYPE_MARKETING_CONSENT_GRANTED = 106;
export const TYPE_MARKETING_CONSENT_DECLINED = 107;

// connected party relationship updates
export const TYPE_UPDATED_BY_MEMBER = 98;

// mentions
export const TYPE_MENTION_USER = 99;
export const TYPE_MENTION_ALL = 100;
export const TYPE_MENTION_GROUP = 126;

// Library
export const TYPE_USER_LIBRARY_SAVED_CONTENT = 130;
export const TYPE_USER_LIBRARY_FORM = 131;

export const TYPE_APPOINTMENT_NOTIFICATION = 132;

// Rhinocall Connection Result Types
export const TYPE_CALL_CONNECTED = 140;
export const TYPE_CALL_NO_ANSWER = 141;
export const TYPE_CALL_VOICE_MESSAGE = 142;

// form
export const TYPE_FORM_SENT = 144;
export const TYPE_FORM_COMPLETED = 145;
export const TYPE_FORM_CREATED = 146;

// Appointment events
export const TYPE_APPOINTMENT_EVENT_REMINDER1 = 65;
export const TYPE_APPOINTMENT_EVENT_SCHEDULED = 66;
export const TYPE_APPOINTMENT_EVENT_REMINDER2 = 143;
export const TYPE_APPOINTMENT_EVENT_REMINDER3 = 171;
export const TYPE_APPOINTMENT_EVENT_REMINDER4 = 172;

// Appointment events
export const TYPE_PRESCRIPTION_STATUS_CONSENTED = 154;
export const TYPE_PRESCRIPTION_STATUS_CANCELLED = 155;
export const TYPE_PRESCRIPTION_STATUS_UNCONSENTED = 156;

// Prescription Campaign events TODO Deprecated!
export const TYPE_PRESCRIPTION_EVENT_REMINDER = 160;
export const TYPE_PRESCRIPTION_EVENT_REFILL_REMINDER = 167;

// ConnectedParty Type
export const TYPE_CONNECTED_PARTY_PRIMARY = 179;
export const TYPE_CONNECTED_PARTY_SECONDARY = 180;

// Prescription Campaign
export const TYPE_PRESCRIPTION_CAMPAIGN_INITAL_TYPE = 184;
export const TYPE_PRESCRIPTION_CAMPAIGN_REFILL_TYPE = 183;
export const TYPE_EVENT_DATE_CREATED_AT = 181;
export const TYPE_EVENT_DATE_PRESCRIPTION_REFILL = 182;
export const TYPE_PRESCRIPTION_CAMPAIGN_DELIVERY_DIRECTION_PRE = -1;
export const TYPE_PRESCRIPTION_CAMPAIGN_DELIVERY_DIRECTION_POST = 1;
